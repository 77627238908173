import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LogService } from 'src/app/components/logger/log.service';
import { environment } from 'src/environments/environment';
import { Customer } from '../../../../functions/src/models/Customer';
import { AuthService } from '../auth/auth.service';

interface HubspotUser {
  name: string;
  phone: string;
  email: string;
  companyName: string;
  cnpj: string;
  domainName: string;
  industry: string;
  revenue: number;
}

interface HubspotDeal {
  termSigned: boolean;
  hasAgent: boolean;
  source: string;
  properties: { companyName: string };
}

@Injectable({
  providedIn: 'root',
})
export class HubspotService {
  constructor(private authService: AuthService, private http: HttpClient, private logger: LogService,  private firestore: AngularFirestore) {}

  async deleteAllFb(data){
    const cnpj = data.replace(/[^\d]/g, '');
    try {
      await this.firestore.collection('customerjourneycontrol').doc(cnpj).update({
        hubspot: null,
      });
      console.log('Campo hubspot deletado com sucesso.');
    } catch (error) {
      console.error('Erro ao deletar campo hubspot:', error);
      throw error;
    }
  }

  async deleteAllHubspot(data): Promise<any> {

    const properties = {
      vid: data.hubspot.vid,
      dealId: data.hubspot.dealId,
      companyId: data.hubspot.companyId,
    }

      try {
        console.log('Upserting user on Hubspot');

        return await this.http
          .post(
            `${environment.functionsUrl}/hubspot/delete/all`,
            { properties },
            { headers: this.authService.getHeader() }
          )
          .toPromise();
      } catch (error) {
        this.logger.fatal(`Hubspot Service - Error delete`, error);
        throw error;
      }
    
  }


  async upsertCustomer(customer: Customer): Promise<object> {
    if (environment.envName === 'PROD') {
      try {
        console.log('Upserting user on Hubspot');

        return await this.http
          .post(
            `${environment.functionsUrl}/hubspot/user/upsert`,
            { customer },
            { headers: this.authService.getHeader() }
          )
          .toPromise();
      } catch (error) {
        this.logger.fatal(`Hubspot Service - Error while upserting user - ${customer.email}`, error);
        throw error;
      }
    } else {
      console.warn('Hubspot Service - User not created due the environment in use');
    }
  }
}
