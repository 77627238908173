<ng-container *ngIf="typeOfCustomer === 'no-touch' && isNoTouchVisible">
  <div class="opp-stream-dialog-container">
    <h2 mat-dialog-title>
      Configuração da oportunidade de crédito
    </h2>
    <span>Aqui você pode configurar o pop up de oferta de crédito que fica visível para o cliente!</span>
    <div mat-dialog-content *ngIf="currentView === 'first'" class="dialog-main-content">
      <mat-form-field appearance="fill" class="select">
        <mat-label>Instruções para o usuário</mat-label>
        <mat-select [formControl]="numberOfInstructionsControl">
          <mat-option *ngFor="let option of [1, 2, 3, 4, 5]" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button (click)="openSecondView()">Prosseguir</button>
    </div>
    <ng-container mat-dialog-content *ngIf="currentView === 'second'">
      <form [formGroup]="instructionsForm">
        <div class="section">
          <mat-form-field class="select" appearence="outline">
            <mat-label>Instruções para o usuário</mat-label>
            <mat-select formControlName="numberOfInstructions" required >
              <mat-option *ngFor="let option of [1, 2, 3, 4, 5]" [value]="option">{{ option }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="input-wrapper">
            <label>Instrução 1</label>
            <input class="input" type="text" placeholder="Instrução da oportunidade" formControlName="firstInstruction" name="firstInstruction" />
          </div>
          <div class="input-wrapper">
            <label>Instrução 2</label>
            <input class="input" type="text" placeholder="Instrução da oportunidade" formControlName="secondInstruction" name="secondInstruction" />
          </div>
          <div class="input-wrapper">
            <label>Instrução 3</label>
            <input class="input" type="text" placeholder="Instrução da oportunidade" formControlName="thirdInstruction" name="thirdInstruction" />
          </div>
          <div class="input-wrapper">
            <label>Instrução 4</label>
            <input class="input" type="text" placeholder="Instrução da oportunidade" formControlName="fourthInstruction" name="fourthInstruction" />
          </div>
          <div class="input-wrapper">
            <label>Instrução 5</label>
            <input class="input" type="text" placeholder="Instrução da oportunidade" formControlName="fifthInstruction" name="fifthInstruction" />
          </div>
        </div>
        <div class="section">
          <div class="btn-config-container">
            <label>Botão de redirecionamento final</label>
            <input class="input" type="text" placeholder="Frase visivel no botão" formControlName="buttonText" name="buttonText" />
            <input class="input" type="text" placeholder="URL do botão" formControlName="buttonUrl" name="buttonUrl" />
          </div>
        </div>

        <div class="btn-submit-wrapper">
          <button (click)="setDialogPreviewOpenNoTouch()">Pré-visualizar</button>
          <button (click)="closeInstructionsDialog()">Salvar</button>
        </div>
      </form>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="typeOfCustomer === 'touch' && isYesTouchVisible">
  <div class="opp-stream-dialog-container yes-touch">
    <h2 mat-dialog-title>
      Configuração da oportunidade de crédito
    </h2>
    <span>Aqui você pode configurar o pop up de oferta de crédito que fica visível para o cliente!</span>
    <form [formGroup]="touchCustomerForm">
      <div class="input-wrapper">
        <label>Observações adicionais (descrição fixa nas oportunidades)</label>
        <input class="input" type="text" placeholder="Observações adicionais" formControlName="comments" name="comments" />
      </div>
      <div class="input-wrapper">
        <label>Validade da proposta (em dias)</label>
        <input class="input" type="text" placeholder="Digite a validade da proposta" formControlName="proposedValidity" name="proposedValidity" required />
      </div>
      <div class="input-wrapper toggle">
        <label>Retorno da API</label>
        <mat-slide-toggle formControlName="useReturnApi">Visível para o cliente</mat-slide-toggle>
      </div>
      <div class="btn-container">
        <button (click)="setDialogPreviewOpen()" [disabled]="!touchCustomerForm.valid">Pré-visualizar</button>
        <button (click)="closeTouchDialog()" [disabled]="!touchCustomerForm.valid">Salvar</button>
      </div>
    </form>
  </div>
</ng-container>

<ng-container *ngIf="isPreviewDialogOpen">
  <div class="preview-dialog">
    <div class="header">
      <button (click)="setDialogPreviewOpen()"><mat-icon>chevron_left</mat-icon></button>
      <h2>Detalhes da oferta</h2>
    </div>
    <p>Instituição: <span>{{nameIF || ""}}</span></p>
    <p>Descrição da instituição: <span>Fintech para empréstimos para capital de giro sem garantia (aval)</span></p>
    <p>Limite de crédito pré-aprovado: <span>R$200.000</span></p>
    <p>Taxa: <span>3% ao mês</span></p>
    <p>Prazo: <span>42 meses</span></p>
    <p>Validade da proposta: <span>até dia xx/xx/xxxx</span></p>
    <p>Descrição da oportunidade: <span>{{oppDescription}} </span></p>
    <div class="btn-container">
      <button class="border-button">Recusar</button>
      <button>Tenho interesse</button>
    </div>
    <span class="obs-span">*Os dados mostrados acima são fictícios</span>
    <span class="obs-span"><b>Observação:</b> Ao clicar em “tenho interesse” a esta proposta, você irá passar para a próxima fase <br /> de análise de crédito, mais completa. <b>Este não é um aceite final.</b></span>
  </div>
</ng-container>


<ng-container *ngIf="isPreviewDialogOpenNotouch">
  <div class="preview-dialog">
    <div class="header">
      <button (click)="setDialogPreviewOpenNoTouch()"><mat-icon>chevron_left</mat-icon></button>
      <h2>Detalhes da oferta</h2>
    </div>
    <p>Instituição: <span>{{nameIF || ""}}</span></p>
    <p>Descrição da instituição: <span>Fintech para empréstimos para capital de giro sem garantia (aval)</span></p>
    <p>Temos uma oportunidade de crédito para você e sua empresa! </p>
    <p><span>Neste momento <span class ='bold'>não será necessário enviar documentos!</span> Basta seguir o 
      passo a passo simplificado mostrado após clicar no botão de “Prosseguir”.</span></p>
    <div class="btn-container">
      <button class="border-button" (click)="setDialogPreviewOpenNoTouchTwo()">Voltar</button>
      <button (click)="openThirdView()">Prosseguir</button>
    </div>
  </div>
</ng-container>
  
<ng-container *ngIf="isPreviewDialogOpenNotouchTwo">
  <div class="preview-dialog">
    <div class="header">
      <button (click)="setDialogPreviewOpenNoTouchTwo()"><mat-icon>chevron_left</mat-icon></button>
      <h2>Detalhes da oferta</h2>
    </div>

      <p *ngIf="instructionsForm.value.firstInstruction">Passo 1: <span>{{ instructionsForm.value.firstInstruction }}</span></p>
      <p *ngIf="instructionsForm.value.secondInstruction">Passo 2: <span>{{ instructionsForm.value.secondInstruction }}</span> </p>
      <p *ngIf="instructionsForm.value.thirdInstruction">Passo 3: <span>{{ instructionsForm.value.thirdInstruction }}</span> </p>
      <p *ngIf="instructionsForm.value.fourthInstruction">Passo 4: <span>{{ instructionsForm.value.fourthInstruction }}</span></p>
      <p *ngIf="instructionsForm.value.fifthInstruction">Passo 5: <span>{{ instructionsForm.value.fifthInstruction }}</span></p>

      <p>
        <span>
          <span style="font-weight: 400; color: #FF8641;">Observação importante:</span> Esta 
          instituição financeira prefere fazer contato direto com o cliente, 
        mas <span style="font-weight: 500;">nosso time da Capital Empreendedor seguirá acompanhando sua operação</span> e, 
        em caso de dificuldades ou dúvidas, encorajamos fortemente que <span style="font-weight: 500;">entre em contato conosco</span> para que possamos auxiliá-lo da melhor 
        forma! <span style="font-weight: 500;">Estamos sempre presentes para ajudá-lo a conseguir o melhor crédito para sua empresa!</span>
        </span>
        
      </p>
    <div class="btn-container">
      <button class="border-button" (click)="setDialogPreviewOpenNoTouchTwo()">Voltar</button>
      <button>{{instructionsForm.value.buttonText}}</button>
    </div>
  </div>
</ng-container>