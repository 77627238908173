import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerPendency } from '../../../../functions/src/models/opportunity/Opportunity';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss']
})
export class NotesDialogComponent {
  public alertTitle: string;
  pendenciesList: CustomerPendency[];
  institutionName: string;

  constructor(private dialogRef: MatDialogRef<NotesDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.alertTitle = data.alertTitle;
    this.pendenciesList = data.content;
    this.institutionName = data.institutionName;
   }

  onClose(){
    this.dialogRef.close();
  }

}
