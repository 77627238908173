import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-input',
  templateUrl: './alert-input.component.html',
  styleUrls: ['./alert-input.component.scss']
})
export class AlertInputComponent {

inputValue = '';
titleButton = "Salvar";


  constructor(public dialogRef: MatDialogRef<AlertInputComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    // Se o valor existir em message.text, preencha o textarea
    if (data.message && data.message.text) {
      this.inputValue = data.message.text;
    }

    if(data.titleButton){
      this.titleButton = data.titleButton;
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.dialogRef.close({ inputValue: this.inputValue });
  }
}
