import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CarouselCardsEntity } from '../../../../functions/src/models/general/carousel-cards.entity';

@Injectable({
  providedIn: 'root',
})
export class CarrosselService {
  constructor(
    private angularFirestore: AngularFirestore,
   
  ) {
   
  }

  async getItems(type: string): Promise<CarouselCardsEntity[]> {
    return new Promise((resolve, reject) => {
    this.angularFirestore
    .collection<CarouselCardsEntity>('carousel-cards', ref => ref.where('type', '==', type))
    .valueChanges()
    .subscribe(
      (res: CarouselCardsEntity[]) => {
        console.log('res', res);
        resolve(res);  
      },
      (error) => {
        console.error('Erro ao buscar dados:', error);
        reject(error);  
      }
    );
  });
}

  async getItemsAll(): Promise<CarouselCardsEntity[]> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection('carousel-cards')
        .snapshotChanges() 
        .subscribe(
          (snapshots) => {
            
            const entities: CarouselCardsEntity[] = snapshots.map((snapshot) => {
              const data = snapshot.payload.doc.data() as CarouselCardsEntity;
              const id = snapshot.payload.doc.id; 
              return { id, ...data }; 
            });
  
           
            resolve(entities);
          },
          (error) => {
            console.error('Erro ao buscar dados:', error);
            reject(error);
          }
        );
    });
  }
 
  getItemById(id: string): Observable<CarouselCardsEntity[]> {
    const collection = this.angularFirestore.collection('carousel-cards', (ref) =>
      ref.where('id', '==',  id )
    );

    return collection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as CarouselCardsEntity;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }
  saveFormData(formData: any): Promise<void> {
    formData.id = formData.name.toLowerCase().replace(/ /g, '_');

    const collectionRef = this.angularFirestore.collection('carousel-cards');
    const docRef = collectionRef.doc(formData.id); 
  
    return docRef
      .set(formData) 
      .then(() => {
        console.log('Dados salvos com sucesso no Firestore com ID no documento!');
      })
      .catch((error) => {
        console.error('Erro ao salvar os dados no Firestore:', error);
        throw error;
      });
  }

  seveStatus(id: string, status: boolean): Promise<any> {
    return this.angularFirestore.collection('carousel-cards').doc(id).set(
      { ativo: status }, 
      { merge: true }  
    );
  }

  
  updateFormData(formData: any): Promise<void> {
    return this.angularFirestore.collection('carousel-cards').doc(formData.id).update(formData);
  }
  
}
