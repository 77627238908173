<div class="user-notes-container">
  <div class="">
    <div class="">
      <h1 style="flex: 1" class="mb-4">Chat</h1>
      <!-- <p>Olá, {{ createdBy?.name || '' }}.</p> -->
      <p>
        Fale com o time da <b>Capital Empreendedor</b>! Aqui você pode sanar suas dúvidas e dificuldades, trabalhando para te atender da melhor forma possível o quanto antes!
      </p>
    </div>

    <div class="" *ngIf="checkCriteria()">

      <p>Gostaria de tirar suas dúvidas ou enviar documentos por <span style="color:#00C46C;">WhatsApp</span> para <b> maior agilidade?</b></p>
        <p> Nos chame pelo botão abaixo! </p>
     
       <p> 
        <button class="botao-contato" (click)="showModal()">
        <i class='fab fa-whatsapp'></i> 
        Entrar em contato
      </button>
    </p>
    
    </div>

    
    <div>
      <app-carrossel styleType="column-reverse" [type]="'faq'" [customer]="this.customer"></app-carrossel>
    </div>

    <div *ngIf="isModalVisible" class="modal">
      <div class="modal-content">
        <span class="close" (click)="closeModal()">&times;</span>
        <h1 style="color: #242424;">Entre em contato pelo Whatsapp</h1>
        <p style="font-size: 15px;">Descreva sua dúvida aqui para agilizar mais ainda sua resposta (opcional)</p>
        <textarea class="input" placeholder="Escreva a dúvida aqui" [(ngModel)]="textoDuvida" style="max-height: 69px; overflow-y: auto;"></textarea>
        <p >
          <a class="botao" [href]="construirLink()" target="_blank">
            <i class='fab fa-whatsapp'></i> 
            Entrar em contato
          </a>
        </p>
      </div>
    </div>

    <div class="text-center my-3" *ngIf="isLoading">
      <mat-spinner [diameter]="80" color="accent" class="mx-auto"></mat-spinner>
    </div>
    <app-user-notes [type]="'USER'" [customer]="customer" [createdBy]="createdBy" *ngIf="!isLoading">
    </app-user-notes>
    
  </div>
</div>