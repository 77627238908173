import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UserManagementService } from '../../../admin/services/user-management.service';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {
  customer: any;
  deleteAccountForm: FormGroup;
  isExtraCardOpen: boolean;
  buttonDisabled: boolean;
  isLoading = false;
  cancellationRequested: boolean = localStorage.getItem('cancellationRequested') === 'true';

  customerSubscription: Subscription;

  cancelationReasons = [
    { id: 'sem-notivo', label: 'Sem motivo' },
    { id: 'taxa', label: 'Não concordo com a taxa' },
    { id: 'simulacao-instantanea', label: 'Achava que a plataforma fazia uma simulação instantânea' },
  ]

  constructor(private dialog: MatDialog, private authService: AuthService, private http: HttpClient, private ums: UserManagementService) {
    this.isExtraCardOpen = false;
  }

  ngOnInit(): void {
    this.customerSubscription = this.authService.user.subscribe((customer) => {
      this.customer = customer;
    })
    this.deleteAccountForm = new FormGroup({
      reason: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    })
  }

  onSubmit(formValue: any): void {
    const { reason: motivation } = formValue;
    const { uid, mainRole } = this.customer;
    const deletedBy = 'user';
    this.isLoading = true;
    // Acessando o valor da senha corretamente usando `get`
    const password = this.deleteAccountForm.get('password')?.value;
  
    this.dialog.open(AlertDialogComponent, {
      maxWidth: '560px',
      data: {
        alertTitle: 'Cancelar conta',
        alertDescription: 'Tem certeza que deseja enviar o pedido de cancelamento da sua conta?',
      }
    })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.buttonDisabled = true;
  
          // Autenticando com o email do cliente e a senha digitada
          this.authService
            .loginWithEmailAndPassword((this.customer.email || '').trim().toLowerCase(), password)
            .then(() => {
              this.ums
                .deleteUser(uid, mainRole, motivation, deletedBy)
                .then(() => {
                  this.dialog.open(AlertDialogComponent, {
                    maxWidth: '560px',
                    data: {
                      alertTitle: 'Sua solicitação está sendo processada',
                      alertDescription: 'Seu pedido de cancelamento já foi enviado à nossa equipe e em até 24 horas será concluído.',
                      isOnlyConfirm: true
                    }
                  });
                  this.cancellationRequested = true;
                  localStorage.setItem('cancellationRequested', this.cancellationRequested.toString());
                  this.isExtraCardOpen = true;
                })
                .catch((err) => {
                  console.error('Erro ao solicitar cancelamento', err);
                  this.dialog.open(AlertDialogComponent, {
                    maxWidth: '560px',
                    data: {
                      alertTitle: 'Erro ao solicitar cancelamento',
                      alertDescription: 'Houve um erro ao solicitar o cancelamento da sua conta, tente novamente mais tarde ou entre em contato com a nossa equipe.',
                      isOnlyConfirm: true
                    }
                  });
                })
                .finally(() => {
                  // Reabilita o botão após a tentativa de solicitação de cancelamento, independentemente do sucesso ou falha
                  this.buttonDisabled = false;
                  this.isLoading = false;
                });
  
            })
            .catch((err) => {
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '560px',
                data: {
                  alertTitle: 'Sua senha está incorreta',
                  alertDescription: 'Por favor digite sua senha correta.',
                  isOnlyConfirm: true
                }
              });
              // Reabilita o botão quando a autenticação falha
              this.buttonDisabled = false;
              this.isLoading = false;
            });
        }
      });
  }
  


}
