<mat-dialog-content>
  <p>{{ data.alertTitle }}</p>
  <mat-form-field *ngIf="data.showInput">
    <textarea class="textArea" matInput placeholder="{{ data.inputLabel }}" [(ngModel)]="inputValue"></textarea>
  </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions style="padding-top: 20px" class="d-flex justify-content-center">
  
    <button mat-raised-button color="primary" class="action-button text-white autosized" (click)="onNoClick()">Cancelar</button>
    <button mat-raised-button color="primary" class="action-button text-white autosized" (click)="onYesClick()">{{ titleButton }}</button>

  
</div>