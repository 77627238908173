import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { BankerNote } from 'functions/src/models/Notes';
import * as Operators from 'functions/src/models/common/Operators';
import _ from 'lodash';
import { MASKS } from 'ng-brazil';
import { Observable, Subscription } from 'rxjs';
import { finalize, map, startWith, take } from 'rxjs/operators';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { UserDocumentService } from 'src/app/customer/services/user-document.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { AuthService } from '../../../core/auth/auth.service';
import { BankersAdminService } from '../../services/bankers-admin.service';
import { NotesService } from '../../services/notes.service';
import { StatsService } from '../../services/stats.service';
import { UserManagementService } from '../../services/user-management.service';
import { momentDuration } from './../../../../../functions/src/utils/dates';
interface Recipient {
  email: string;
  valid: boolean;
  color: string;
}

@Component({
  selector: 'app-customer-reports',
  templateUrl: './customer-reports.component.html',
  styleUrls: ['./customer-reports.component.scss'],
})
export class CustomerReportsComponent implements OnInit, OnDestroy {
  readonly MASKS = MASKS;

  formatCnae = UtilHandler.formatCnae;

  readonly reportList = [
    { label: 'Clientes', name: 'customers' },
    { label: 'Oportunidades', name: 'opportunities' },
    { label: 'Documentos Pendentes', name: 'pendingDocuments' },
    { label: 'Documentos Enviados', name: 'sentDocuments' },
  ];

  readonly steps = [0, 10, 15, 20, 30, 40, 60, 80, 90, 100];

  readonly operators = [
    { label: '=', value: Operators.Compar.EQUAL },
    { label: '>=', value: Operators.Compar.GREATER_OR_EQUAL },
    { label: '>', value: Operators.Compar.GREATER },
    { label: '<=', value: Operators.Compar.LESS_OR_EQUAL },
    { label: '<', value: Operators.Compar.LESS },
  ];

  readonly numberMask = createNumberMask({
    decimalLimit: 2,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: false,
    integerLimit: 15,
    prefix: 'R$ ',
    suffix: '',
  });

  readonly banks = [
    { value: 'banco_do_brasil', label: 'Banco do Brasil' },
    { value: 'bradesco', label: 'Bradesco' },
    { value: 'caixa_economica_federal', label: 'Caixa' },
    { value: 'itau', label: 'Itaú' },
    { value: 'santander', label: 'Santander' },
    { value: 'outro', label: 'Outro' },
  ];

  readonly monetaryFields = ['revenue', 'requestedCredit', 'debt', 'creditValue'];

  readonly baseOpportunityFieldList = [
    { name: 'institutionName', label: 'Instituição' },
    { name: 'step', label: 'Fase da oportunidade' },
    { name: 'lastStageChangeDate', label: 'Duração da fase atual (dias)' },
    { name: 'companyName', label: 'Razão Social' },
    { name: 'whatsapp', label: 'WhatsApp' },
    { name: 'email', label: 'E-mail' },
    { name: 'cnpj', label: 'CNPJ' },
    { name: 'createdAt', label: 'Data de Criação' },
    { name: 'agreedTerms', label: 'Concorda com os termos' },
    { name: 'agreedTermsDate', label: 'Data de Assinatura dos Termos'},
    { name: 'closingDate', label: 'Tempo para tomada do crédito (dias)' },
    { name: 'operationTime', label: 'Tempo no sistema (dias)' },
    { name: 'source', label: 'Origem' },
    { name: 'revenue', label: 'Faturamento Anual' },
    { name: 'guarantees', label: 'Garantias' },
    { name: 'creditValue', label: 'Valor Solicitado' },
    { name: 'limitOperation', label: 'Limite de Operação' },
    { name: 'rate', label: 'Taxa' },
    { name: 'deadlineForMonth', label: 'Prazo' },
    { name: 'city', label: 'Cidade' },
    { name: 'name', label: 'Nome' },
    { name: 'cpf', label: 'CPF' },
    { name: 'uid', label: 'UID' },
    { name: 'motivationOfCancellation', label: 'Motivos de Recusa' },
    { name: 'contactingName', label: 'Responsável' },
    { name: 'lastContactDate', label: 'Ultimo contato' },

  ];

  readonly baseCustomerFieldList = [
    { name: 'companyName', label: 'Razão Social' },
    { name: 'cnpj', label: 'CNPJ' },
    { name: 'website', label: 'WebSite' },
    { name: 'email', label: 'E-mail' },
    { name: 'cupomCode', label: 'Cupom' },
    { name: 'agreedTerms', label: 'Concorda com os Termos' },
    { name: 'agreedTermsDate', label: 'Data de Assinatura dos Termos' },
    { name: 'termsCancellationDate', label: 'Data de Cancelamento dos Termos'},
    { name: 'operationTime', label: 'Tempo no sistema' },
    { name: 'revenue', label: 'Faturamento' },
    { name: 'creditValue', label: 'Valor Solicitado' },
    { name: 'debt', label: 'Dívida informada' },
    { name: 'score', label: 'Score' },
    { name: 'guarantees', label: 'Garantias' },
    { name: 'typeOfAnticipation', label: 'Tipo de antecipação' },
    { name: 'banks', label: 'Bancos' },
    { name: 'purpose', label: 'Finalidade' },
    { name: 'createdAt', label: 'Data de Criação' },
    { name: 'taxRegime', label: 'Regime Tributário' },
    { name: 'zipCode', label: 'CEP' },
    { name: 'city', label: 'Cidade' },
    { name: 'agent', label: 'Assessor' },
    { name: 'phone', label: 'Telefone' },
    { name: 'whatsapp', label: 'WhatsApp' },
    { name: 'hasBDC', label: 'Possui consulta ao BDC?' },
    { name: 'highestOpps', label: 'Oportunidade Mais Alta' },
    { name: 'name', label: 'Nome do contato' },
    { name: 'cpf', label: 'CPF do contato' },
    { name: 'uid', label: 'UID' },
    { name: 'accountantEmail', label: 'Email do Contador' },
    { name: 'accountantPhone', label: 'Telefone do Contador' },
    { name: 'cnaes', label: 'CNAEs' },
    { name: 'source', label: 'Origem' },
    { name: 'stepsOppsAndIFs', label: 'Etapas das Oportunidades e suas Instituições' },
    { name: 'foundationDate', label: 'Data de fundação' },
    { name: 'foundationAge', label: 'Tempo de fundação' },
    { name: 'lastInteraction', label: 'Data da última interação' },
    { name: 'taxRegimeBDC', label: 'Tipo da empresa' },
    { name: 'utmMedium', label: 'UTM Medium' },
    { name: 'utmCampaign', label: 'UTM Campaign' },
    { name: 'utmId', label: 'UTM Id' },
    { name: 'utmTerm', label: 'UTM Term' },
    { name: 'utmContent', label: 'UTM Content' },
    { name: 'typeOfInvoices', label: 'Tipo de Nota Fiscal' },
    { name: 'hasRecurrence', label: 'Cliente recorrente' },
    { name: 'lastRecurrenceDate', label: 'Data recorrência' }
  ];

  readonly typeFile = [
    { name: 'CSV', type: 'text/csv' },
    { name: 'XLSX', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' },
    { name: 'XLS', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' },
  ];

  readonly agreedTermsOptions = [
    {id: 'sim', label: 'Sim'},
    {id: 'sim_com_termo', label: 'Sim (com termo)'},
    {id: 'nao', label: 'Não'}
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  loggedUserSubscription: Subscription;
  revenueOperatorSubs: Subscription;
  userDocumentsSubscription: Subscription;
  guaranteesSubscription: Subscription;

  // fields for export
  selectedFields;
  fieldsList = [];
  filteredFieldsList: any[] = [];
  previouslySelectedFields: string[] = [];

  // fields for type file
  selectedType: FormControl;

  // options reports
  selectedReport: FormControl;

  filterFormGroup: FormGroup;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [];

  // Pagination
  pageSize = 100;
  pageSizeOptions: number[] = [25, 50, 100];

  // Sorting
  sortField = 'lastInteraction';
  sortDirection: MatSortable['start'] = 'desc';

  // User
  loggedUser: { uid: string; name: string | 'Unknown'; email: string };

  // Institutions
  InstitutionsFilter: Observable<any[]>;
  institutions: { name: string; id: string }[] = [];
  selectedInstitutions: any = new Array<any>();
  lastFilter = '';
  isMenuOpen = false;

  guarantees: { id: string; name: string }[];
  typeOfAnticipationList: { id: string; value: string }[];
  isTypeOfAnticipationEnabled = false;
  task: AngularFireUploadTask;


  taxRegimesList: { id: string; name: string }[];

  // Progress monitoring
  percentage: Observable<number>;
  snapshot: Observable<any>;
  fileURL: Observable<string>;
  fileURLStr = '';
  footer = '';

  customers = undefined;
  usersPendingDocuments = undefined;
  isLoadingCustomers = false;

  enableSendEmail = false;
  recipients: Recipient[] = [];
  removable = true;
  limit = 10;
  subject = 'Posicionamento sobre oportunidade abertas';
  message = '';
  isToSendReportToIF = false;
  isProcessing = false;
  hasError = false;
  disabled = true;
  hasFilterByHaveTerm = false;

  constructor(
    private fb: FormBuilder,
    private ums: UserManagementService,
    private userDocumentService: UserDocumentService,
    private statsService: StatsService,
    private bankersAdminService: BankersAdminService,
    private dialog: MatDialog,
    private storage: AngularFireStorage,
    private customerService: CustomerService,
    private authService: AuthService,
    private notesService: NotesService
  ) { }

  ngOnInit(): void {
    this.selectedReport = new FormControl();
    this.selectedFields = new FormControl();
    this.selectedType = new FormControl({ value: this.typeFile[0], disabled: false });

    this.filterFormGroup = this.fb.group({
      guaranteesOperator: new FormControl(),
      banksOperator: new FormControl(),
      zipCode: new FormControl(),
      cnae: new FormControl(),
      revenue: new FormControl({ value: '', disabled: true }),
      revenueOperator: new FormControl(),
      selectedAgentOption: new FormControl(),
      selectedTaxRegimeOption: new FormControl(),
      selectedBanks: new FormControl({ value: '', disabled: true }),
      selectedGuarantees: new FormControl({ value: '', disabled: true }),
      selectedAnticipations: new FormControl({ value: '', disabled: false }),
      selectedSteps: new FormControl(),
      selectedHighestSteps: new FormControl(),
      selectedTermsOption: new FormControl(),
      customerUID: new FormControl(),
      cnpj: new FormControl(),
      email: new FormControl(),
      cupomCode: new FormControl(),
      utmMedium: new FormControl(),
      utmCampaign: new FormControl(),
      utmId: new FormControl(),
      utmTerm: new FormControl(),
      utmContent: new FormControl(),
      companyName: new FormControl(),
      origem: new FormControl(),
      scoreMax: new FormControl(),
      scoreMin: new FormControl(),
      timeCreatedStartCtrl: new FormControl(),
      timeCreatedEndCtrl: new FormControl(),
      termsSignatureDateStartCtrl: new FormControl(),
      termsSignatureDateEndCtrl: new FormControl(),
      dataSource: new MatTableDataSource([]),
      userControl: new FormControl(),
      lastInteractionStartCtrl: new FormControl(),
      lastInteractionEndCtrl: new FormControl(),
      typeOfInvoices: new FormControl({ value: ''}, []),
      contactingNameCtrl: new FormControl(),
      lastRecurrenceDateStartCtrl: new FormControl(),
      lastRecurrenceDateEndCtrl: new FormControl(),
      hasRecurrence: new FormControl()

    });

    this.bankersAdminService.getInstitutions().then((institutions) => {
      this.institutions = institutions.map((i) => ({ name: i.nome, id: i.nomeNoSistema, selected: false }));
    });

    // user

    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser) => {
      this.loggedUser = {
        uid: loggedUser.uid,
        name: loggedUser.name || 'Unknown',
        email: loggedUser.email,
      };
    });

    this.guaranteesSubscription = this.customerService.getSignUpConfiguration().subscribe((config) => {
      this.guarantees = config.guarantees;
      this.typeOfAnticipationList = config.typeOfAnticipation;
      this.taxRegimesList = config.taxRegimes;
      console.log(this.taxRegimesList);
    });

    this.InstitutionsFilter = this.filterFormGroup.controls['userControl'].valueChanges.pipe(
      startWith<string | any[]>(''),
      map((value) => (typeof value === 'string' ? value : this.lastFilter)),
      map((filter) => this.filter(filter))
    );
  }

  selectedGuaranteesChange(event): void {
    this.isTypeOfAnticipationEnabled = !!event.value.find((selected) => selected.id === 'boleto');
  }

  selectedTermsOption(event): void {
    this.hasFilterByHaveTerm = event.value === 'sim_com_termo';
  }


  ngOnDestroy(): void {
    if (this.loggedUserSubscription) {
      this.loggedUserSubscription.unsubscribe();
    }
    if (this.guaranteesSubscription) {
      this.guaranteesSubscription.unsubscribe();
    }
    if (this.revenueOperatorSubs) {
      this.revenueOperatorSubs.unsubscribe();
    }
  }

  filter(filter: string): any[] {
    if (typeof filter === 'string') {
      const institutionsArray = filter.split(',');
      filter = institutionsArray[institutionsArray?.length - 1];
      if (filter.indexOf(' ') === 0) {
        filter = filter.slice(1, filter.length);
      }
    }
    this.lastFilter = filter;
    if (filter) {
      return this.institutions.filter((i) => {
        return i.name.toLowerCase().includes(filter.toLowerCase());
      });
    } else {
      return this.institutions.slice();
    }
  }

  displayFn(value: any[] | string): string | undefined {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((i, index) => {
        if (index === 0) {
          displayValue = i.name;
        } else {
          displayValue += ', ' + i.name;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  optionClicked(event: Event, institution: any) {
    event.stopPropagation();
    this.toggleSelection(institution);
  }

  toggleSelection(institution: any) {
    institution.selected = !institution.selected;
    if (institution.selected) {
      this.selectedInstitutions.push(institution);
    } else {
      const i = this.selectedInstitutions.findIndex((value) => value.name === institution.name);
      this.selectedInstitutions.splice(i, 1);
    }
    this.filterFormGroup.controls['userControl'].setValue(this.selectedInstitutions);
  }

  cancelMessage() {
    this.isToSendReportToIF = false;
  }

  getOpportunities(): void {
    this.isLoadingCustomers = true;
    const selectedTermsOption: string = this.filterFormGroup.get('selectedTermsOption').value || null;
    const agreedTerms = selectedTermsOption ? selectedTermsOption !== 'nao' : null;
    this.statsService
      .getOpportunities({
        selectedFields: this.selectedFields?.value,
        hasFilterByHaveTerm: this.hasFilterByHaveTerm,
        institutions: this.selectedInstitutions?.map((i) => i.id) ?? [],
        steps: this.filterFormGroup.get('selectedSteps').value,
        hasTerms: agreedTerms,
        opportunitiesSteps: this.filterFormGroup.get('selectedSteps').value,
        timeCreated: {
          start: this.filterFormGroup.get('timeCreatedStartCtrl')?.value
            ? new Date(this.filterFormGroup.get('timeCreatedStartCtrl').value).toISOString()
            : null,
          end: this.filterFormGroup.get('timeCreatedEndCtrl')?.value
            ? new Date(this.filterFormGroup.get('timeCreatedEndCtrl').value).toISOString()
            : null,
        },
        revenue:
          this.filterFormGroup.get('revenueOperator')?.value && this.filterFormGroup.get('revenue')?.value
            ? parseFloat(
              this.filterFormGroup
                .get('revenue')
                .value.replace(/[A-Za-z.$\s]/g, '')
                .replace(',', '.')
            )
            : null,

        revenueOperator: this.filterFormGroup.get('revenueOperator').value || null,
        termsSignatureDate: {
          start: this.filterFormGroup.get('termsSignatureDateStartCtrl')?.value
            ? new Date(this.filterFormGroup.get('termsSignatureDateStartCtrl').value).toISOString()
            : null,
          end: this.filterFormGroup.get('termsSignatureDateEndCtrl')?.value
            ? new Date(this.filterFormGroup.get('termsSignatureDateEndCtrl').value).toISOString()
            : null,
        },
        source: this.filterFormGroup.get('origem').value || null,
        contactingName: this.filterFormGroup.get('contactingNameCtrl').value || null,
      })
      .subscribe(
        (opps) => {
          const opportunities = opps.map((op) => ({
            ...op,
            // score: op.score ? Number(op?.score).toFixed(2) : null,
            closingOppTime:
              typeof op.closingDate === 'number'
                ? Math.ceil(momentDuration(op.closingDate, op.createdAt).asDays())
                : '-',
            operationTime:
              op.createdAt && op.termsDate ? Math.ceil(momentDuration(op.createdAt).asDays())  : '-',
            revenue: op.revenue,

            requestedCredit: op.requestedCredit ?? '-',
            message: '',
          }));

          this.customers = _.sortBy(opportunities, [this.sortField]);

          if (this.sortDirection === 'desc') {
            _.reverse(this.customers);
          }

          // this.fieldsList = [...this.baseOpportunityFieldList];
          // this.displayedColumns = this.fieldsList.map((f) => f.name);

          this.dataSource = new MatTableDataSource(this.customers);

          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          // this.selectedFields.setValue(this.displayedColumns);

          this.isLoadingCustomers = false;
        },
        (err) => {
          console.error('Error getting opportunities', err);
          this.customers = null;

          this.dataSource = new MatTableDataSource([]);

          this.isLoadingCustomers = false;

          this.dialog.open(AlertDialogComponent, {
            maxWidth: '700px',
            data: {
              alertTitle: 'Erro ao obter oportunidades',
              alertDescription: `Houve um problema ao obter os dados das oportunidades.`,
              isOnlyConfirm: true,
            },
          });
        }
      );
  }

  selectedGuaranteesOperatorChange($event): void {
    $event.value
      ? this.filterFormGroup.controls['selectedGuarantees'].enable()
      : this.filterFormGroup.controls['selectedGuarantees'].disable();
  }

  getCustomers(): void {
    this.customers = [];
    this.isLoadingCustomers = true;
    const selectedTermsOption = this.filterFormGroup.get('selectedTermsOption').value;
    const agreedTerms = selectedTermsOption ? this.filterFormGroup.get('selectedTermsOption').value !== 'nao' : null;
    const params = {
      selectedFields: this.selectedFields?.value,
      hasFilterByHaveTerm: this.hasFilterByHaveTerm ? this.hasFilterByHaveTerm : null ,
      uid: this.filterFormGroup.get('customerUID').value || null,
      cupomCode: this.filterFormGroup.get('cupomCode').value || null,
      utmMedium: this.filterFormGroup.get('utmMedium').value,
      utmCampaign: this.filterFormGroup.get('utmCampaign').value,
      utmId: this.filterFormGroup.get('utmId').value,
      utmTerm: this.filterFormGroup.get('utmTerm').value,
      utmContent: this.filterFormGroup.get('utmContent').value,
      typeOfInvoices: this.filterFormGroup.get('typeOfInvoices').value || null,
      banks: this.filterFormGroup.get('banksOperator').value
        ? this.filterFormGroup.get('selectedBanks').value || null
        : null,
      banksOperator: this.filterFormGroup.get('banksOperator').value || null,
      guarantees: this.filterFormGroup.get('guaranteesOperator').value
        ? this.filterFormGroup.get('selectedGuarantees').value || null
        : null,
      guaranteesOperator: this.filterFormGroup.get('guaranteesOperator').value || null,
      typeOfAnticipation: this.filterFormGroup.get('selectedAnticipations').value
        ? this.filterFormGroup.get('selectedAnticipations').value || null
        : null,
      taxRegime: this.filterFormGroup.get('selectedTaxRegimeOption').value || null,
      hasAgent: this.filterFormGroup.get('selectedAgentOption').value,
      hasTerms: agreedTerms ,
      zipCode: this.filterFormGroup.get('zipCode').value || null,
      cnae: this.filterFormGroup.get('cnae').value ? `${ this.filterFormGroup.get('cnae').value }` : null,
      revenue:
        this.filterFormGroup.get('revenueOperator').value && this.filterFormGroup.get('revenue').value
          ? parseFloat(
            this.filterFormGroup
              .get('revenue')
              .value.replace(/[A-Za-z.$\s]/g, '')
              .replace(',', '.')
          )
          : null,
      revenueOperator: this.filterFormGroup.get('revenueOperator').value || null,
      highestOpps: this.filterFormGroup.get('selectedHighestSteps').value || null,
      opportunitiesSteps: this.filterFormGroup.get('selectedSteps').value || null,
      source: this.filterFormGroup.get('origem').value || null,
      scoreMax: this.filterFormGroup.get('scoreMax').value || null,
      scoreMin: this.filterFormGroup.get('scoreMin').value || null,
      timeCreated: {
        start: this.filterFormGroup.get('timeCreatedStartCtrl')?.value
          ? new Date(this.filterFormGroup.get('timeCreatedStartCtrl').value).toISOString()
          : null,
        end: this.filterFormGroup.get('timeCreatedEndCtrl')?.value
          ? new Date(this.filterFormGroup.get('timeCreatedEndCtrl').value).toISOString()
          : null,
      },
      termsSignatureDate: {
        start: this.filterFormGroup.get('termsSignatureDateStartCtrl')?.value
          ? new Date(this.filterFormGroup.get('termsSignatureDateStartCtrl').value).toISOString()
          : null,
        end: this.filterFormGroup.get('termsSignatureDateEndCtrl')?.value
          ? new Date(this.filterFormGroup.get('termsSignatureDateEndCtrl').value).toISOString()
          : null,
      },
      lastInteraction: {
        start: this.filterFormGroup.get('lastInteractionStartCtrl')?.value
          ? new Date(this.filterFormGroup.get('lastInteractionStartCtrl').value).toISOString()
          : null,
        end: this.filterFormGroup.get('lastInteractionEndCtrl')?.value
          ? new Date(this.filterFormGroup.get('lastInteractionEndCtrl').value).toISOString()
          : null,
      },

      lastRecurrenceDate: {
        start: this.filterFormGroup.get('lastRecurrenceDateStartCtrl')?.value
          ? new Date(this.filterFormGroup.get('lastRecurrenceDateStartCtrl').value).toISOString()
          : null,
        end: this.filterFormGroup.get('lastRecurrenceDateEndCtrl')?.value
          ? new Date(this.filterFormGroup.get('lastRecurrenceDateEndCtrl').value).toISOString()
          : null,
      },
      hasRecurrence:  this.filterFormGroup.get('hasRecurrence').value || null,

    };

    this.statsService.getCustomers(params).subscribe(
      (customers) => {
        // const today = new Date();
        this.customers = customers.map((customer) => ({
          ...customer,
          score: customer.score ? Number(customer?.score).toFixed(2) : null,
          foundationAge: !customer?.foundationDate
            ? '-'
            : Math.ceil(momentDuration(customer.foundationDate).asDays()) + ' dias',
          operationTime: customer?.termsDate && customer?.createdAt ? momentDuration(customer.createdAt) : '-',
          lastInteraction: customer?.lastInteraction,
          lastRecurrenceDate: customer?.lastRecurrenceDate,
        }));
        this.customers = _.sortBy(this.customers, [this.sortField]);
        if (this.sortDirection === 'desc') {
          _.reverse(this.customers);
        }

        // this.fieldsList = [...this.baseCustomerFieldList];
        // this.displayedColumns = this.fieldsList.map((f) => f.name);
        this.dataSource = new MatTableDataSource(this.customers);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // this.selectedFields.setValue(this.displayedColumns);
        this.isLoadingCustomers = false;
      },
      (err) => {
        console.error('Error getting customers', err);
        this.customers = null;

        this.dataSource = new MatTableDataSource([]);

        this.isLoadingCustomers = false;

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '700px',
          data: {
            alertTitle: 'Erro ao obter usuários',
            alertDescription: `Houve um problema ao obter os dados dos usuários.`,
            isOnlyConfirm: true,
          },
        });
      }
    );
  }

  getData(): void {
    switch (this.selectedReport.value) {
      case 'customers':
        this.getCustomers();
        break;
      case 'opportunities':
        this.getOpportunities();
        break;
      case 'pendingDocuments':
        this.getPendingDocuments();
        break;
      case 'sentDocuments':
        this.getSentDocuments();
    }
  }

  exportList(): void {
    if (this.selectedFields?.value?.length) {
      const data = this.customers.map((c) => {
        const onlySelectedData = _.pick(c, this.selectedFields.value);
        if (this.selectedFields.value?.indexOf('cnaes') >= 0) {
          onlySelectedData.cnaes =
            onlySelectedData.cnaes
              ?.map((a) => `${ a.IsMain ? '*' : '' }${ a.Code }-${ a.Activity }${ a.IsMain ? '*' : '' }`)
              .join(' ') || '';
        }

        return onlySelectedData;
      });

      const type = this.selectedType?.value.type;
      const extension = this.selectedType?.value.name.toLowerCase();
      const blob = new Blob(
        [
          this.selectedType?.value.name === 'XLSX'
            ? this.statsService.generateXLSX(data)
            : this.selectedType?.value.name === 'XLS'
              ? this.statsService.generateXLS(data)
              : this.statsService.generateCSV(data),
        ],
        { type }
      );

      const date = new Date().toLocaleDateString();
      saveAs(blob, `${ this.selectedReport?.value }-${ date }.${ extension }`.toLowerCase()?.replace(/\s/g, '-'));
    }
  }

  compareSelectedType(o1: any, o2: any): boolean {
    return o1.name === o2.name;
  }

  numberMax(): number {
    return this.filterFormGroup.get('scoreMax')?.value ? Number(this.filterFormGroup.get('scoreMax')?.value) : null;
  }

  numberMin(): number {
    return this.filterFormGroup.get('scoreMin')?.value ? Number(this.filterFormGroup.get('scoreMin')?.value) : null;
  }

  saveReport() {
    if (this.selectedFields?.value?.length) {
      const data = this.customers.map((a) => _.pick(a, this.selectedFields.value));
      let blob;
      const extension = this.selectedType?.value.name.toLowerCase();
      const type = this.selectedType?.value.type;
      if (this.selectedType?.value.name === 'XLSX') {
        blob = new Blob([this.statsService.generateXLSX(data)], { type: type });
      } else if (this.selectedType?.value.name === 'XLS') {
        blob = new Blob([this.statsService.generateXLS(data)], { type: type });
      } else {
        blob = new Blob([this.statsService.generateCSV(data)], { type: type });
      }
      if (blob) {
        this.enableSendEmail = true;
      }

      this.startUpload(blob, extension);
    }
  }

  startUpload(blob: Blob, extension: string): void {
    // The storage path
    const path = `opps-reports/${ new Date().getTime() }.${ extension }`;

    // Totally optional metadata
    const customMetadata = { app: 'Capital Empreendedor' };

    // The main task
    this.task = this.storage.ref(path).put(blob, { customMetadata });

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.task
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          console.log('call finalize');
          this.fileURL = this.storage.ref(path).getDownloadURL();
          this.fileURLStr = await this.fileURL.pipe(take(1)).toPromise();
          this.footer = this.fileURLStr;
        })
      )
      .subscribe();
  }

  getPendingDocuments(): void {
    this.usersPendingDocuments = [];
    this.isLoadingCustomers = true;

    const params = {
      selectedFields: ['companyName', 'cnpj', 'email', 'uid'],
      cnpj: this.filterFormGroup.get('cnpj')?.value || null,
      companyName: this.filterFormGroup.get('companyName')?.value || null,
      email: this.filterFormGroup.get('email')?.value || null,
      agreedTerms: true,
    };

    this.statsService.getAllDocumentsPending(params).subscribe((leadsWithPendingDocuments) => {
      if (leadsWithPendingDocuments?.length > 0) {
        this.usersPendingDocuments.push(...leadsWithPendingDocuments);
      }
      this.isLoadingCustomers = false;
    });
  }


  getSentDocuments(): void {
    this.usersPendingDocuments = [];
    this.isLoadingCustomers = true;

    const params = {
      selectedFields: ['companyName', 'cnpj', 'email', 'uid'],
      cnpj: this.filterFormGroup.get('cnpj')?.value || null,
      companyName: this.filterFormGroup.get('companyName')?.value || null,
      email: this.filterFormGroup.get('email')?.value || null,
      agreedTerms: true,
    };

    this.statsService.getAllDocumentsSent(params).subscribe((leadsWithPendingDocuments) => {
      if (leadsWithPendingDocuments?.length > 0) {
        this.usersPendingDocuments.push(...leadsWithPendingDocuments);

        console.log( this.usersPendingDocuments)
      }
      this.isLoadingCustomers = false;
    });
  }

  createNote(opp: any): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Salvar registro',
          alertDescription: 'Deseja realmente salvar esse registro de acompanhamento?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const { message } = opp;
          const createdBy = this.loggedUser;
          const note: BankerNote = {
            message,
            type: 'ADMIN',
            banker: null,
            createdBy,
          };
          this.notesService
            .createBankerNote(opp.uid, opp.nomeNoSistema, note, {
              companyName: opp.company,
              address: opp.address,
              institution: opp.institution,
            })
            .then(() => {
              opp.message = '';
              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Registro salvo',
                  alertDescription: 'O registro de acompanhamento foi salvo com sucesso.',
                  isOnlyConfirm: true,
                },
              });
            })
            .catch((err) => {
              console.error('Error creating note', err);

              this.dialog.open(AlertDialogComponent, {
                maxWidth: '600px',
                data: {
                  alertTitle: 'Erro ao salvar',
                  alertDescription: 'Não foi possível salvar o registro. Tente novamente mais tarde.',
                  isOnlyConfirm: true,
                },
              });
            });
        }

        if (confirmSubscription) {
          confirmSubscription.unsubscribe();
        }
      });
  }

  onChangeFieldList(value): void {
    switch (value) {
      case 'customers':
        this.fieldsList = [...this.baseCustomerFieldList];
        break;
      case 'opportunities':
        this.fieldsList = [...this.baseOpportunityFieldList];
        break;
      case 'pendingDocuments':
        break;
    }
    this.displayedColumns = this.fieldsList.map((f) => f.name);
    this.selectedFields.setValue(this.displayedColumns);
    this.filteredFieldsList = this.fieldsList;
  }

  selectAllFields(): void {
    this.selectedFields.setValue(this.filteredFieldsList.map((field) => field.name));
    this.previouslySelectedFields = this.selectedFields.value.slice();
  }

  clearAllFields(): void {
    this.selectedFields.setValue([]);
    this.filterFields('');
  }

  filterFields(searchValue: string): void {
    const selectedFieldsBeforeFilter = this.selectedFields.value; // Armazena os campos selecionados antes da pesquisa
    this.filteredFieldsList = this.fieldsList.filter((field) =>
      field.label.toLowerCase().includes(searchValue.toLowerCase())
    );
    this.filteredFieldsList = this.filteredFieldsList.concat(
      selectedFieldsBeforeFilter.filter((selectedFieldName) =>
        !this.filteredFieldsList.some((field) => field.name === selectedFieldName)
      ).map((selectedFieldName) => this.fieldsList.find((field) => field.name === selectedFieldName))
    );
    this.selectedFields.setValue(
      selectedFieldsBeforeFilter.filter((selectedFieldName) =>
        this.filteredFieldsList.some((field) => field.name === selectedFieldName)
      )
    );
  }

  onSelectMenuOpened(opened: boolean): void {
    this.isMenuOpen = opened;
  }
}
