import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { firestore } from 'firebase';
import { Agent } from 'functions/src/models/Agent';
import { Customer } from 'functions/src/models/Customer';
import { AgentNote, UserNote, UserNotification } from 'functions/src/models/Notes';
import { User } from 'functions/src/models/User';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { environment } from 'src/environments/environment';
import { timestampForDate } from '../../../../functions/src/utils/dates';
import { CadastrosTemplatesMessage } from '../../admin/cadastros-gerais/cadastros-gerais';
import { CadastrosGeraisService } from '../../admin/cadastros-gerais/cadastros-gerais.service';
import { NotesService } from '../../admin/services/notes.service';
import { ChatService } from '../../chat/services/chat.service';
import { AuthService } from '../../core/auth/auth.service';
import { CustomerService } from '../../customer/services/customer.service';
import { ForwardNotesComponent } from '../forward-notes/forward-notes.component';
import { UserNotesDialogComponent } from '../user-notes-dialog/user-notes-dialog.component';

@Component({
  selector: 'app-user-notes',
  templateUrl: './user-notes.component.html',
  styleUrls: ['./user-notes.component.scss'],
})
export class UserNotesComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  type: UserNote['type'];

  @Input()
  customer: string;

  @Input()
  createdBy: UserNote['createdBy'];

  @Input()
  showHr = false;
  admin: User;
  notificationData: UserNotification;
  customerData: Customer;
  agent: string;
  // onlyUsed if type !== agent
  agentEmail?: string;
  agentName?: string;
  customerEmail?: string;
  accountantEmail?: string;
  templates: CadastrosTemplatesMessage[];
  getTemplateMessageSubscription: Subscription;

  public valuePlace: string;
  public newPlaceHolder: string;

  showTemplateOptions = false;
  hasAccountant = false;
  isCreateMode = false;
  isLoadingNotes = true;
  newNote: UserNote = {
    message: '',
  };
  searchValue = '';
  sendToAccountant = false;
  whatsAppDate: string;
  isWhatsApp: boolean;
  notes: UserNote[] = [];
  filteredNotes: AgentNote[] = [];
  notesSubscription: Subscription;
  messages = [];
  userSubscription: Subscription;
  agentSubscription: Subscription;
  messagesSubscription: Subscription;


  // cdc
  textMessageChatSce: string;
  phoneValue: string;
  message: string;
  loggedUser: { uid: string; name: string | 'Unknown'; email: string };
  loggedUserSubscription: Subscription;

  selectedFile: File | null = null;
  fileData: string | null = null;
  fileType: string | null = null;
  docExtension: string;
  @ViewChild('fileInput') fileInputRef!: ElementRef;



  readonly typeLabels = {
    ADMIN: 'Capital Empreendedor',
    AGENT: 'Assessor',
    USER: 'Cliente',
  };

  currentPage = 1;
  itemsPerPage = 20;
  isCustomerNoTouch: boolean;

  constructor(
    private notesService: NotesService,
    private cadastrosGeraisService: CadastrosGeraisService,
    private angularFirestore: AngularFirestore,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private chatService: ChatService,
    private authService: AuthService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async ngOnInit(): Promise<void> {
    // this.whatsAppDate = this.customerData.whatsAppContact.date.toDate().toLocaleDateString();

    this.route.queryParams.subscribe(queryParam => {
      this.valuePlace = queryParam.filter;

      if (this.valuePlace === 'credito') {
        this.newPlaceHolder = 'Quero pedir crédito novamente...';
      } else {
        this.newPlaceHolder = 'Escreva sua mensagem...';
      }
    });

    this.getTemplateMessageSubscription = this.cadastrosGeraisService.getTemplateMessageAtivas().subscribe((templates) => {
      this.templates = templates;
      templates.map((template) => {
        template['id'] = template.mnemonico;
        template['name'] = template.nome;
        template['description'] = template.description;

      });
    });

    this.chatService.getPlugConfig().subscribe((data: any) => {
      this.phoneValue = data?.phone;
      this.itemsPerPage = data?.maxMessage || 20;

    });



    this.loggedUserSubscription = this.authService.user.subscribe((loggedUser) => {
      this.loggedUser = {
        uid: loggedUser.uid,
        name: loggedUser.name || 'Unknown',
        email: loggedUser.email,
      };
    });

    // this.getPendencyMessage();
    // this.getMessageFromAddGuarantee();
    this.getMessage();
  }

  checkForSlash() {
    if (this.newNote.message.includes('/')) {
      this.showTemplateOptions = true;
    } else {
      this.showTemplateOptions = false;
    }
  }

  selectTemplate(template: any) {
    this.newNote.message = template.description;
    this.showTemplateOptions = false;
  }

  refreshNotes(): void {
    console.log('refreshing note');
    this.notesService
      .getCustomerComments({
        customerUid: this.customerData.uid,
        isSenderAdmin: this.type === 'ADMIN',
        senderEmail: this.customerData.email,
      })
      .toPromise()
      .then((res) => {
        this.notes = res;
        this.isLoadingNotes = false;
      });
  }


  ngOnChanges(): void {
    if (this.customer) {
      // this.notesService.getUserNotesPreview(this.customer, 5).subscribe((userNotes) => {
      // this.notesService.getAllUserNotes(this.customer).subscribe((userNotes) => {
      //   this.notes = userNotes;
      //   this.isLoadingNotes = false;
      // });

      // TODO: usar service
      this.angularFirestore
        .collection('customers')
        .doc(this.customer)
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.exists) {
            const data: Customer = doc.data();
            this.agent = data.agent || null;
            this.customerData = data;
            this.customerEmail = data.email;
            this.accountantEmail = data.accountantData?.email;
            // console.log(this.customerData);
            if (this.customerData.whatsAppContact) {
              this.whatsAppDate = this.formatDate(this.customerData.whatsAppContact.date);
              this.isWhatsApp = this.customerData.whatsAppContact.value;
            }
            this.getContactMessages(this.formatWhatsapp(this.customerData.whatsapp), this.currentPage, this.itemsPerPage);

            this.notesService
              .getCustomerComments({
                customerUid: data.uid,
                isSenderAdmin: this.type === 'ADMIN',
                senderEmail: data.email,
              })
              .subscribe((res) => {
                this.notes = res;
                this.isLoadingNotes = false;
              });

            this.hasAccountant = !!this.accountantEmail;
            if (this.type !== 'AGENT' && this.agent) {
              this.agentSubscription = this.angularFirestore
                .collection('agents')
                .doc(this.agent)
                .get()
                .pipe(take(1))
                .subscribe((docAgent) => {
                  const dataAgent: Agent = docAgent.data();

                  if (dataAgent?.isActive) {
                    this.agentEmail = dataAgent.email;
                    this.agentName = dataAgent.name;
                  }
                });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  handleOptionClick(option: any, typeMessage: string) {
    const data = {
      customerId: this.loggedUser.uid,
      contactId: this.formatWhatsapp(this.customerData.whatsapp),
      sender: {
        uid: this.loggedUser.uid,
        name: this.loggedUser.name,
        phone: this.formatWhatsapp(this.customerData.whatsapp),
        type: 'customer'
      },
      recipient: {
        uid: this.customerData.attributedTo || '',
        name: 'Capital Empreendedor',
        phone: this.phoneValue,
        type: 'admin'
      },
      sendingType: 'API Client by client',
      messageType: typeMessage,
      text: option.title,
    };
    this.chatService.sendTextMessage(data);
  }

  ngOnDestroy(): void {
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
    if (this.notesSubscription) {
      this.notesSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.getTemplateMessageSubscription) {
      this.getTemplateMessageSubscription.unsubscribe();
    }
    if (this.loggedUserSubscription) {
      this.loggedUserSubscription.unsubscribe();
    }
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }

  }

  updateCustomer(event) {
    const dateNow = firestore.Timestamp.now();
    this.isWhatsApp = event.checked;

    const whatsAppContact: Customer['whatsAppContact'] = {
      date: dateNow,
      value: this.isWhatsApp,
    };
    this.customerService.updateCustomerOnFirestore(this.customerData.uid, { whatsAppContact });
  }

  clearNote(): void {
    this.newNote.message = '';
  }

  cancelNote(): void {
    this.isCreateMode = false;
    this.clearNote();
  }

  createNote(): void {
    const confirmSubscription = this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Enviar Mensagem',
          alertDescription: 'Deseja realmente enviar essa mensagem?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.notificationData = {
          type: this.type,
          name: this.createdBy.name,
          companyName: this.customerData?.companyName || null,
          content: this.newNote.message,
          recipients: [],
          link: {
            toAdmin: `${ environment.baseURL }/admin/clientes/detalhes/${ this.customer }`,
            toAgent: `${ environment.baseURL }/agents/lead/${ this.customer }`,
            toUser: `${ environment.baseURL }/mensagens`,
          },
        };

        if (this.type !== 'AGENT' && this.agentEmail) {
          this.notificationData.recipients.push({
            Name: this.agentName,
            Email: this.agentEmail,
            type: 'AGENT',
          });
        }

        if (this.type !== 'USER') {
          this.notificationData.recipients.push({
            Name: this.customerData.name,
            Email: this.customerEmail,
            type: 'USER',
          });

          if (this.sendToAccountant) {
            this.notificationData.recipients.push({
              Name: this.accountantEmail,
              Email: this.accountantEmail,
              type: 'USER',
            });
          }
        }

        this.newNote = {
          ...this.newNote,
          type: this.type,
          agent: this.agent,
          createdBy: this.createdBy,
        };

        if (this.sendToAccountant) {
          this.newNote.sendToAccountant = true;
        }

        this.notesService
          .createUserNote(this.customer, this.newNote, this.notificationData)
          .then(() => {
            // this.notes.push(this.newNote);
            this.refreshNotes();
            // this.notes = [{ ...this.newNote, createdAt: firestore.Timestamp.now() }, ...this.notes];
            this.isCreateMode = false;
            this.clearNote();
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Mensagem Salva',
                alertDescription: 'A mensagem foi enviada com sucesso.',
                isOnlyConfirm: true,
              },
            });
          })
          .catch((err) => {
            console.error('Error creating note', err);

            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao salvar',
                alertDescription: 'Não foi possível enviar a mensagem. Tente novamente mais tarde.',
                isOnlyConfirm: true,
              },
            });
          })
          .finally(() => {
            if (confirmSubscription) {
              confirmSubscription.unsubscribe();
            }
          });
      });
  }

  forwardNote(noteText): void {
    this.dialog.open(ForwardNotesComponent, {
      width: '70%',
      data: {
        customer: this.customer,
        customerData: this.customerData,
        noteText: noteText,
        noteFrom: 'USER',
      },
    });
  }

  viewMoreNotes(): void {
    this.dialog.open(UserNotesDialogComponent, {
      height: '80%',
      width: '70%',
      data: {
        customer: this.customer,
        agent: this.agent,
        notificationData: '',
        type: this.type,
        createdBy: this.createdBy,
        agentName: this.agentEmail,
        agentEmail: this.agentEmail,
        customerData: this.customerData,
      },
    });
  }

  async sendTextMessage(messageType: string) {
    if (this.isNotEmpty(this.textMessageChatSce)) {
      this.message = this.textMessageChatSce;

      const textMessage = this.formatMessage(this.message.replace(/\n/g, '<br>'));

      const data = {
        customerId: this.loggedUser.uid,
        contactId: this.formatWhatsapp(this.customerData.whatsapp),
        sender: {
          uid: this.loggedUser.uid,
          name: this.loggedUser.name,
          phone: this.formatWhatsapp(this.customerData.whatsapp),
          type: 'customer'
        },
        recipient: {
          uid: this.customerData.attributedTo || '',
          name: 'Capital Empreendedor',
          phone: this.phoneValue,
          type: 'admin'
        },
        sendingType: 'API Client by client',
        messageType: messageType,
        text: textMessage,
      };

      await this.chatService.sendTextMessage(data);
      this.chatService.updateCdcMessageReadDate(this.customerData.whatsapp, "customer");
      this.textMessageChatSce = '';
    }
  }

  isNotEmpty(str: string): boolean {
    return str?.trim() !== '';
  }

  formatMessage(text: string): string {
    const parts = text.split(/(<br>)/);

    const formattedParts = parts.map((part) => {
      if (part === '<br>') {
        return '<br>';
      }

      const words = part.split(/\s+/);

      const formattedWords = words.map((word) => {
        const isUrl = this.checkIfTextIsUrl(word);

        if (isUrl) {
          const prefixedUrl = word.startsWith('http') ? word : 'http://' + word;
          return `<b><a href="${ prefixedUrl }" target="_blank">${ word }</a></b>`;
        }

        return word;
      });

      return formattedWords.join(' ');
    });

    return formattedParts.join('');
  }

  checkIfTextIsUrl(str: string): boolean {
    if (str.includes('://')) {
      try {
        const url = new URL(str);
        return ['http:', 'https:'].includes(url.protocol);
      } catch (error) {
        return false;
      }
    }
    else {
      const domainRegex = /^(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
      return domainRegex.test(str);
    }
  }

  openFileInput(): void {
    this.fileInputRef.nativeElement.click();
  }

  onFileSelected(event: Event, messageType: string): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
      const docName = fileInput.files[0].name || 'document';

      const maxFileSizeMB = 8;

      const fileSizeMB = (this.selectedFile?.size ?? 0) / (1024 * 1024);

      if (fileSizeMB > maxFileSizeMB) {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao anexar arquivo',
            alertDescription: `Não é possível anexar um arquivo superior a ${ maxFileSizeMB } MB. Verifique.`,
            isOnlyConfirm: true,
          },
        });
      } else {
        const reader = new FileReader();
        reader.onload = async (e: any) => {
          this.fileData = e.target.result;
          if (this.fileType === 'image/jpeg' || this.fileType === 'image/jpng' || this.fileType === 'image/png') {
            await this.sendImageMessage(messageType, this.fileData);
          }
          if (this.fileType === 'application/pdf' || this.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || this.fileType === 'application/msword' || this.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            if (this.fileType === 'application/pdf') {
              this.docExtension = 'pdf';
            }
            if (this.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
              this.docExtension = 'docx';
            }
            if (this.fileType === 'application/msword') {
              this.docExtension = 'doc';
            }
            if (this.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
              this.docExtension = 'xlsx';
            }
            await this.sendDocumentMessage(messageType, this.fileData, this.docExtension, docName.replace(`.${ this.docExtension }`, ''));
          }
        };
        reader.readAsDataURL(this.selectedFile);

        this.fileType = this.selectedFile.type;
      }
    }
  }


  formatWhatsapp(phoneStr: string) {
    return phoneStr.replace(/[^0-9]/g, '');
  }

  onKeyDown(event: any): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendTextMessage('Chat-SCE');
      const textarea = event.target;
      textarea.setSelectionRange(0, 0);
      textarea.style.height = 'auto';
    }
  }

  sendImageMessage(messageType: string, base64: string) {
    this.message = this.textMessageChatSce;

    const data = {
      customerId: this.loggedUser.uid,
      contactId: this.formatWhatsapp(this.customerData.whatsapp),
      sender: {
        uid: this.loggedUser.uid,
        name: this.loggedUser.name,
        phone: this.formatWhatsapp(this.customerData.whatsapp),
        type: 'customer'
      },
      recipient: {
        uid: this.customerData.attributedTo || '',
        name: 'Capital Empreendedor',
        phone: this.phoneValue,
        type: 'admin'
      },
      sendingType: 'API Client by client',
      messageType: messageType,
      imageCaption: ' ',
      image: base64,
    };
    this.chatService.sendImageMessage(data, this.customerData);
  }

  sendDocumentMessage(messageType: string, base64: string, docExtension: string, docName: string) {
    this.message = this.textMessageChatSce;

    const data = {
      customerId: this.loggedUser.uid,
      contactId: this.formatWhatsapp(this.customerData.whatsapp),
      sender: {
        uid: this.loggedUser.uid,
        name: this.loggedUser.name,
        phone: this.formatWhatsapp(this.customerData.whatsapp),
        type: 'customer'
      },
      recipient: {
        uid: this.customerData.attributedTo || '',
        name: 'Capital Empreendedor',
        phone: this.phoneValue,
        type: 'admin'
      },
      sendingType: 'API Client by client',
      messageType: messageType,
      documentFileName: docName,
      document: base64,
      docExtension: docExtension
    };
    this.chatService.sendDocumentMessage(data, this.customerData);
  }

  getContactMessages(contact: string, page: number, itemsPerPage: number): void {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
    console.log(`Getting messages for contact ${ contact }, page ${ page }, itemsPerPage ${ itemsPerPage }`);
    //this.isLoadingMessages = true;
    this.messagesSubscription = this.chatService.getCdcMessagesCustomer(contact).subscribe({
      next: (result) => {
        console.log('Received messages:', result.length);

        if (result && Array.isArray(result)) {
          // Adicione as novas mensagens à lista existente
          this.messages = result.sort((a, b) => timestampForDate(a.sendDate).toISOString() > timestampForDate(b.sendDate).toISOString() ? 1 : -1);
        } else {
          // Se não houver mensagens, não altere a lista existente
        }

        //this.isLoadingMessages = false;
      },
      error: (error) => {
        console.error('Error fetching messages:', error);
        //this.isLoadingMessages = false;
      },
      complete: () => {
        // Executado quando a observação é concluída (opcional)
      },
    });
  }

  loadMoreMessages(): void {
    this.currentPage++;
    this.getContactMessages(this.customerData.whatsapp, this.currentPage, this.itemsPerPage);
  }

  getMessageCardStyle(message: any) {
    if (message.sender.type === 'customer' && message.messageType === 'WHATSAPP') {
      return 'message-card-whatsapp-client';
    }
    if (message.sender.type !== 'customer' && message.messageType === 'WHATSAPP') {
      return 'message-card-whatsapp';
    }
    if (message.sender.type === 'customer' && message.messageType === 'CHAT-SCE') {
      return 'message-card-internal-client';
    }
    if (message.sender.type !== 'customer' && message.messageType === 'CHAT-SCE') {
      return 'message-card-internal';
    }
    if (message.sender.type === 'admin' && message.type === 'note') {
      return 'message-card-note';
    }
  }

  downloadDocuments(message: any): void {
    const documentUrl = message.document;
    const documentName = message.documentFileName;

    const link = document.createElement('a');
    link.href = documentUrl;
    link.download = documentName;
    link.target = '_blank';
    link.click();
  }

  downloadImage(message: any): void {
    const imageUrl = message.image;
    const imageName = message.imageCaption;

    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = imageName;
    link.target = '_blank';
    link.click();
  }

  formatDate(date: any): string {
    if (date) {
      return new Date(timestampForDate(date)).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    } else {
      return '';
    }
  }

  // getPendencyMessage(){
  //   this.route.queryParams.subscribe(params => {
  //     this.textMessageChatSce = params['message'] || '';
  //   });
  // }

  // getMessageFromAddGuarantee(){
  //   this.route.queryParams.subscribe(params => {
  //     this.textMessageChatSce = params['message'] || '';
  //   });
  // }

  getMessage(){
    this.route.queryParams.subscribe(params => {
      this.textMessageChatSce = params['message'] || '';
    });
  }

}
