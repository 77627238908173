import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Agent } from 'functions/src/models/Agent';
import { Banker } from 'functions/src/models/Banker';
import Papa from 'papaparse';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root',
})
export class StatsService {
  constructor(private authService: AuthService, private http: HttpClient) {}

  generateCSV(data, headers?: string[]): string {
    return 'sep=,\n' + Papa.unparse(data, { columns: headers || null });
  }

  generateXLSX(data: any[]): ArrayBuffer {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    return XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  }

  generateXLSScreening(data: any[]): ArrayBuffer {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    return XLSX.write(workbook, { bookType: 'xls', type: 'array' });
  }


  generateXLS(data): string {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
    return excelBuffer;
  }

  getAccountants(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.functionsUrl}/stats/accountants`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((accountants) => {
          console.log('Finished accountants request');
          resolve(accountants);
        })
        .catch((err) => {
          console.error('Error getting accountants', err);
          reject();
        });
    });
  }

  getActiveAgents(params: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.functionsUrl}/stats/active-agents`,
          { params },
          {
            responseType: 'json',
            headers: this.authService.getHeader(),
          }
        )
        .toPromise()
        .then((res: any) => {
          console.log('Finished active agents request');
          resolve(res.agents);
        })
        .catch((err) => {
          console.error('Error getting active agents', err);
          reject(err);
        });
    });
  }

  getAgentsAndLeads(params: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.functionsUrl}/stats/agents-and-leads`,
          { params },
          {
            responseType: 'json',
            headers: this.authService.getHeader(),
          }
        )
        .toPromise()
        .then((res: any) => {
          console.log('Finished leads request');
          resolve(res.agents);
        })
        .catch((err) => {
          console.error('Error getting leads', err);
          reject(err);
        });
    });
  }

  getAllAgents(params: any): Promise<Agent[]> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.functionsUrl}/stats/all-agents`,
          { params },
          {
            responseType: 'json',
            headers: this.authService.getHeader(),
          }
        )
        .toPromise()
        .then((res: any) => {
          console.log('Finished all agents request');
          resolve(res.agents);
        })
        .catch((err) => {
          console.error('Error getting all agents', err);
          reject(err);
        });
    });
  }

  getAllBankers(): Promise<Banker[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.functionsUrl}/stats/all-bankers`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res: any) => {
          console.log('Finished bankers request');
          resolve(res.bankers);
        })
        .catch((err) => {
          console.error('Error getting bankers', err);
          reject(err);
        });
    });
  }

  getSearchBankers(institutions): Promise<Banker[]> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.functionsUrl}/stats/search-bankers`,
          { institutions },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then((res: any) => {
          console.log('Finished search bankers request');
          resolve(res.bankers);
        })
        .catch((err) => {
          console.error('Error getting search bankers', err);
          reject(err);
        });
    });
  }

  getCustomers(filter: any): Observable<any[]> {
    const params: HttpParams = new HttpParams().set('filters', JSON.stringify(filter));
    return this.http.get<any[]>(`${environment.functionsUrl}/stats/customers`, {
      params,
      responseType: 'json',
      headers: this.authService.getHeader(),
    });
  }

  getBankerActiveOps({
    bank,
    banker,
    filterField,
    filterValue,
    /*orderField,
    orderDirection,
    pageSize,
    page,*/
    isRegionalized,
    regionalization,
    minRevenue,
    maxRevenue,
  }): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let params = new HttpParams();
      params = params.append('bank', bank);
      params = params.append('banker', banker);
      params = params.append('filterField', filterField);
      params = params.append('filterValue', filterValue);
      params = params.append('isRegionalized', JSON.stringify(isRegionalized));
      params = params.append('minRevenue', minRevenue);
      params = params.append('maxRevenue', maxRevenue);
      params = params.append('regionalization', JSON.stringify(regionalization));

      this.http
        .get(`${environment.functionsUrl}/banker/opportunities?${params}`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res: any[]) => {
          console.log('Finished bankers ops request');
          resolve(res);
        })
        .catch((err) => {
          console.error('Error getting bankers ops', err);
          reject(err);
        });
    });
  }

  getOpportunities(filter): Observable<any[]> {
    const params: HttpParams = new HttpParams().set('filters', JSON.stringify(filter));

    return this.http.get<any[]>(`${environment.functionsUrl}/stats/opportunities`, {
      params,
      responseType: 'json',
      headers: this.authService.getHeader(),
      withCredentials: false,
    });
  }

  async getOpportunitiesScreening(filter): Promise<any> {
    const params: HttpParams = new HttpParams().set('filters', JSON.stringify(filter));
  
    console.log("passou aqui");
  
    try {
      const response = await this.http.get<any[]>(`${environment.functionsUrl}/stats/opportunities-screening`, {
        params,
        responseType: 'json',
        headers: this.authService.getHeader(),
        withCredentials: false,
      }).toPromise();
  
      console.log('Resposta da API:', response);
      return response; // Retorna a resposta da API
    } catch (error) {
      console.error('Erro na requisição:', error);
      throw error; // Lança o erro para que possa ser tratado em `teste`
    }
  }
  

  getOpportunitiesAverageClosingTime(lastNdays: number | 'all'): Promise<{ result: number; opportunities: any[] }> {
    return this.http
      .post(
        `${environment.functionsUrl}/stats/opportunities-average-closing-time`,
        { lastNdays },
        {
          responseType: 'json',
          headers: this.authService.getHeader(),
        }
      )
      .toPromise() as Promise<{ result: number; opportunities: any[] }>;
  }

  getAllDocumentsPending(filter?): Observable<any[]> {
    const params: HttpParams = new HttpParams().set('filters', JSON.stringify(filter));
    return this.http.get<any[]>(`${environment.functionsUrl}/stats/documents-pending`, {
      params,
      responseType: 'json',
      headers: this.authService.getHeader(),
    });
  }

  getAllDocumentsSent(filter?): Observable<any[]> {
    const params: HttpParams = new HttpParams().set('filters', JSON.stringify(filter));
    return this.http.get<any[]>(`http://127.0.0.1:5001/ce-dev-8dcce/us-central1/stats/documents-sent`, {
      params,
      responseType: 'json',
      headers: this.authService.getHeader(),
    });
  }
}
