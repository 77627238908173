<div class="user-document-container">
  <div class="inner-container">
    <div>
      <div class="content row">
        <div class="col-12 col-md-7">
          <h1 class="mb-4">Meus documentos</h1>
          <p><b>Carregue seus documentos</b> e fique atento ao seu email para novidades!</p>

          <div class="faq-wrapper">
            <p>Precisa de ajuda?</p>
            <div class="faq-buttons-container">
              <button><a routerLink="/mensagens">Fale conosco</a></button>
              <button><a href="https://www.capitalempreendedor.com.br/faq" target="_blank">Perguntas frequentes</a></button>
            </div>
          </div>

          <div *ngIf="documentsLength > 0">
            <div class="mb-3 mt-2 accepted-documents">
              <div class="documents-quantity">
                <label>Documentos aceitos:</label>
                <div>
                  <span class="accepted">{{ acceptedDocuments }}</span>
                  <span class="total">/{{ documentsLength }}</span>
                </div>
              </div>
              <div class="mt-1 mb-2 progress-bar">
                <mat-progress-bar class="bar" mode="determinate"
                  [value]="(acceptedDocuments/documentsLength)*100"></mat-progress-bar>
              </div>
              <div class="progress">
                <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>

          <div class="text-center" *ngIf="enableLoading">
            <mat-spinner [diameter]="60" color="primary" class="mx-auto"></mat-spinner>
          </div>
        </div>

        <div class="detail col-12 col-md-5">
          <div class="accountant-card-container" *ngIf="!hasAccountant && !isEditingAccountantData && !isFillingOutAccountData">
            <h4>Adicionar contador</h4>
            <p>Quer ajuda para enviar seus documentos?</p>
            <span>Informe os dados do seu contador e entraremos em contato diretamente com ele para auxiliá-lo!</span>
            <button (click)="fillAccountData()">Adicionar contador</button>
          </div>
          <div class="accountant-card-container" *ngIf="isFillingOutAccountData">
            <h4>Adicionar contador</h4>
            <form [formGroup]="accountantDataFormGroup">
              <div class="inputs-container">
                <input formControlName="accountantEmail" placeholder="Email do contador*" required />
                <mat-error
                  *ngIf="accountantDataFormGroup.get('accountantEmail')?.touched && accountantDataFormGroup.get('accountantEmail')?.hasError('required')">
                  O e-mail do contador é obrigatório.
                </mat-error>
                <mat-error
                  *ngIf="accountantDataFormGroup.get('accountantEmail')?.touched && accountantDataFormGroup.get('accountantEmail')?.hasError('email')">
                  Informe um e-mail válido.
                </mat-error>
                <input formControlName="accountantPhone" placeholder="Telefone do contador*" required />
                <mat-error
                  *ngIf="accountantDataFormGroup.get('accountantPhone')?.touched && accountantDataFormGroup.get('accountantPhone')?.hasError('required')">
                  O telefone do contador é obrigatório.
                </mat-error>
              </div>
              <mat-checkbox formControlName="allowAccountantContact" [checked]="checked"
                (click)="onChangeAccountantContactAuthorization(); isEditingAccountantData = true" color="primary">
                Autorizo a Capital Empreendedor a entrar em contato com meu contador
              </mat-checkbox>
              <div class="action-container">
                <button class="cancel-button" (click)="cancelEditing()">Cancelar</button>
                <button class="save-button" type="submit" (click)="saveAccountantData()"
                  [disabled]="!accountantDataFormGroup.get('allowAccountantContact').value">Salvar</button>
              </div>
            </form>
          </div>
          <div class="accountant-card-container" *ngIf="hasAccountant && !isEditingAccountantData && !isFillingOutAccountData">
            <h4>Dados do contador</h4>
            <div class="filled-content">
              <p>Email: {{ customer.accountantData.email }}</p>
              <p>Telefone: {{ customer.accountantData.phone || '-' }}</p>
            </div>
            <button class="save-button"
              (click)="onChangeAccountantContactAuthorization(true); isFillingOutAccountData = true">Alterar
              dados</button>
          </div>
          <button class="clicksign-button" (click)="openClicksignDialog()">Assinatura pendente <i class="fas fa-arrow-right fa-lg"></i></button>
        </div>
      </div>

      <div class="mt-4" class="documents-list-container">

        <mat-tab-group class="documents-list-group">
          <mat-tab label="Documentos Obrigatórios" *ngIf="requiredDocuments" class="required-documents-tab">
            <h4 class="h4-required-documents">Envie seus documentos obrigatórios!</h4>
            <p class="description-required-documents">O envio dos documentos a seguir são <b>obrigatórios</b> para dar andamento no seu pedido de crédito.</p>

            <mat-accordion *ngIf="groupByStatus" class="example-headers-align">
              <mat-expansion-panel *ngFor="let docStatGroup of requiredDocsStatusGroups" [class.d-none]="!docStatGroup.count">
                <mat-expansion-panel-header style="border-left: solid; border-left-width: 8px"
                  [style.border-left-color]="docStatGroup.color">
                  <mat-panel-title>
                    {{ docStatGroup.text }} <strong class="ml-1">({{ docStatGroup.count }})</strong>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="descricao">
                  <ng-container
                    *ngTemplateOutlet="docItems; context: { total: docStatGroup.count, documents: docStatGroup.docs }"></ng-container>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <div *ngIf="!groupByStatus">
              <ng-container *ngTemplateOutlet="
                  docItems;
                  context: { total: documents.length, documents: documentsToShowOnList }
                "></ng-container>
            </div>

            <ng-template #docItems let-documents="documents" let-total="total">
              <mat-accordion class="example-headers-align" *ngIf="total > 0">
                <mat-expansion-panel class="inside-cards" *ngFor="let item of documents">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ item?.name }}
                    </mat-panel-title>
                    <mat-panel-description>
                      {{ getDocumentStatus(item) }}
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div class="descricao">
                    <h4>Descrição</h4>
                    <div class="d-flex justify-content-between flex-column flex-md-row">
                      <mat-label>{{ item?.description }}</mat-label>
                    </div>
                  </div>
                  <div class="instrucoes">
                    <h4>Instruções</h4>
                    <div class="content" innerHtml="{{ item?.instructions }}" style="padding-top: 5px"></div>
                  </div>
                  <div class="detalhes">
                    <h4>Detalhes</h4>
                    <div class="d-flex flex-column">
                      <div *ngIf="item?.situation === 'Aprovado' && item?.approvalOrDisapprovalDate"
                        style="padding-bottom: 10px">
                        <mat-label>
                          Aprovado em {{ item?.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                        </mat-label>
                      </div>
                      <div *ngIf="item?.situation === 'Reprovado' && item?.approvalOrDisapprovalDate"
                        style="padding-bottom: 10px">
                        <mat-label>
                          Reprovado em {{ item?.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                        </mat-label>
                        <br />
                        <mat-label *ngIf="item?.disapprovalReason"> Motivo: {{ item?.disapprovalReason }} </mat-label>
                      </div>
                    </div>
                    <div>
                      <mat-label *ngIf="item?.qtyExpectedFiles - item?.qtySubmittedFiles > 0">Aguardando
                        {{
                        item?.qtyExpectedFiles - item?.qtySubmittedFiles < 0 ? 0 : item?.qtyExpectedFiles -
                          item?.qtySubmittedFiles }} de {{ item?.qtyExpectedFiles }} {{ item?.qtyExpectedFiles> 1 ?
                          'arquivos' : 'arquivo' }}</mat-label>
                    </div>
                    <div>
                      <mat-label>
                        Limite de arquivos{{ item.qtyFinancialYear > 0 ? ' (por ano): ' : ': ' }}
                        {{ item?.maximumDocuments || item?.minimumDocuments || 1 }}
                      </mat-label>
                    </div>
                    <div class="d-flex flex-column flex-md-row" *ngIf="item?.expirationDate?.id === 'sim'"
                      style="padding-top: 10px">
                      <mat-label> Validade: {{ item?.qtyDays }} dias</mat-label>
                    </div>
                    <div *ngIf="item?.qtyFinancialYear > 0" style="padding: 15px 0">
                      <mat-label> Anos de exercício:</mat-label>
                      <mat-accordion style="color: white" class="financial-years">
                        <mat-expansion-panel class="mt-2" *ngFor="let year of item.financialYears">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{ year }}
                            </mat-panel-title>
                            <mat-panel-description>
                              {{ getDocumentStatus(item, year) }}
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                          <div class="mt-2" class="financial-years-content">
                            <p class="mb-2">
                              Anexe o {{ item?.name }} de {{ year }} abaixo e informe a data de emissão do documento.
                            </p>
                            <p class="mt-4"
                              *ngIf="item?.fileInfo?.length === 0 && getDocumentStatus(item, year) === 'Pendente'">
                              <strong>Nenhum arquivo foi anexado.</strong>
                            </p>
                            <div *ngIf="item?.fileInfo.length > 0">
                              <div class="attached-file m-2 mt-4 mb-3" *ngFor="let file of item?.fileInfo; let i = index">
                                <p class="file" *ngIf="file?.financialYear === year">
                                  <a class="attached-file-link cursor-style" href="{{ file.path }}" target="_blank"
                                    matTooltip="Abrir arquivo">
                                    {{ file.fileName ? file.fileName : item?.name }}
                                  </a>
                                  <ng-container *ngIf="item?.incomplete !== true">
                                    <span *ngIf="file.dateUpload">
                                      &nbsp; {{ (file.dateUpload | date: 'dd/MM/yyyy HH:mm:ss') }}
                                    </span>
                                    <mat-icon *ngIf="item?.situation !== 'Aprovado' && item?.approvalOrDisapprovalDate"
                                      inline="true" class="ml-2 icon pointer"
                                      (click)="onRemoveFileOfDocument(null, item, file)" matTooltip="Remover arquivo">
                                      clear
                                    </mat-icon>
                                  </ng-container>
                                </p>
                              </div>
                            </div>

                            <ng-container *ngIf="customer?.hasRecurrence === true ">
                              <div class="botao-alteracao-1"
                                *ngIf="!item?.customerConfirmation && item?.fileInfOld.length > 0">
                                <button mat-raised-button color="primary" (click)="onButtonNoChange(item.uid, item.typeId)"
                                  [disabled]="item?.hasNoAlterationRec">
                                  <mat-label>Não houve alterações</mat-label>
                                </button>
                                <mat-spinner [diameter]="20" color="primary" class="d-inline-block mx-3"
                                  *ngIf="isProcessing">
                                </mat-spinner>
                              </div>
                            </ng-container>

                            <div class="attach-file financial-years">
                              <button mat-raised-button color="primary" (click)="onSendNewFile(item, year)"
                                [disabled]="!canUploadFiles(item,year)">
                                Enviar Arquivo
                              </button>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>

                    <div *ngIf="item?.qtyFinancialYear == 0 || item?.qtyFinancialYear == null">
                      <div class="mt-2" class="financial-years-content">
                        <p class="mb-2">Anexe o {{ item?.name }} abaixo e informe a data de emissão do documento.</p>
                        <p class="mb-2" *ngIf="!item?.fileInfo">
                          <strong>Nenhum arquivo foi anexado.</strong>
                        </p>
                        <div class="attached-file mt-4 mb-3" *ngIf="item?.fileInfo.length > 0 ">
                          <p class="file" *ngFor="let file of item?.fileInfo; let i = index">
                            <a class="attached-file-link cursor-style" [href]="file.path" target="_blank"
                              matTooltip="Abrir arquivo">
                              {{ file.fileName ? file.fileName : item?.name }}
                            </a>

                            <ng-container *ngIf="item?.incomplete !== true">
                              <mat-icon *ngIf="item?.situation !== 'Aprovado' && item?.approvalOrDisapprovalDate"
                                class="ml-3 icon pointer" inline="true" (click)="onRemoveFileOfDocument(i, item, file)"
                                matTooltip="Remover arquivo">
                                clear
                              </mat-icon>
                            </ng-container>
                          </p>
                        </div>
                        <div class="attach-file">
                          <button mat-raised-button color="primary" (click)="onSendNewFile(item)"
                            [disabled]="!canUploadFiles(item)">
                            Enviar arquivo
                          </button>
                        </div>
                        <ng-container *ngIf="customer?.hasRecurrence === true">
                          <div class="botao-alteracao" *ngIf="!item?.customerConfirmation && item?.fileInfOld.length > 0">
                            <button mat-raised-button color="primary" (click)="onButtonNoChange(item.uid, item.typeId)"
                              [disabled]="item?.hasNoAlterationRec">
                              <mat-label>Não houve alterações</mat-label>
                            </button>
                            <mat-spinner [diameter]="18" color="primary" class="d-inline-block mx-3" *ngIf="isProcessing">
                            </mat-spinner>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-template>

          </mat-tab>
          <mat-tab label="Documentos Extras" *ngIf="extraDocuments">

            <mat-accordion *ngIf="groupByStatus" class="example-headers-align">
              <mat-expansion-panel *ngFor="let docStatGroup of extraDocsStatusGroups" [class.d-none]="!docStatGroup.count">
                <mat-expansion-panel-header style="border-left: solid; border-left-width: 8px"
                  [style.border-left-color]="docStatGroup.color">
                  <mat-panel-title>
                    {{ docStatGroup.text }} <strong class="ml-1">({{ docStatGroup.count }})</strong>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="descricao">
                  <ng-container
                    *ngTemplateOutlet="docItems; context: { total: docStatGroup.count, documents: docStatGroup.docs }"></ng-container>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <div *ngIf="!groupByStatus">
              <ng-container *ngTemplateOutlet="
                  docItems;
                  context: { total: documents.length, documents: documentsToShowOnList }
                "></ng-container>
            </div>

            <ng-template #docItems let-documents="documents" let-total="total">
              <mat-accordion class="example-headers-align" *ngIf="total > 0">
                <mat-expansion-panel class="inside-cards" *ngFor="let item of documents">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ item?.name }}
                    </mat-panel-title>
                    <mat-panel-description>
                      {{ getDocumentStatus(item) }}
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div class="descricao">
                    <h4>Descrição</h4>
                    <div class="d-flex justify-content-between flex-column flex-md-row">
                      <mat-label>{{ item?.description }}</mat-label>
                    </div>
                  </div>
                  <div class="instrucoes">
                    <h4>Instruções</h4>
                    <div class="content" innerHtml="{{ item?.instructions }}" style="padding-top: 5px"></div>
                  </div>
                  <div class="detalhes">
                    <h4>Detalhes</h4>
                    <div class="d-flex flex-column">
                      <div *ngIf="item?.situation === 'Aprovado' && item?.approvalOrDisapprovalDate"
                        style="padding-bottom: 10px">
                        <mat-label>
                          Aprovado em {{ item?.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                        </mat-label>
                      </div>
                      <div *ngIf="item?.situation === 'Reprovado' && item?.approvalOrDisapprovalDate"
                        style="padding-bottom: 10px">
                        <mat-label>
                          Reprovado em {{ item?.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                        </mat-label>
                        <br />
                        <mat-label *ngIf="item?.disapprovalReason"> Motivo: {{ item?.disapprovalReason }} </mat-label>
                      </div>
                    </div>
                    <div>
                      <mat-label *ngIf="item?.qtyExpectedFiles - item?.qtySubmittedFiles > 0">Aguardando
                        {{
                        item?.qtyExpectedFiles - item?.qtySubmittedFiles < 0 ? 0 : item?.qtyExpectedFiles -
                          item?.qtySubmittedFiles }} de {{ item?.qtyExpectedFiles }} {{ item?.qtyExpectedFiles> 1 ?
                          'arquivos' : 'arquivo' }}</mat-label>
                    </div>
                    <div>
                      <mat-label>
                        Limite de arquivos{{ item.qtyFinancialYear > 0 ? ' (por ano): ' : ': ' }}
                        {{ item?.maximumDocuments || item?.minimumDocuments || 1 }}
                      </mat-label>
                    </div>
                    <div class="d-flex flex-column flex-md-row" *ngIf="item?.expirationDate?.id === 'sim'"
                      style="padding-top: 10px">
                      <mat-label> Validade: {{ item?.qtyDays }} dias</mat-label>
                    </div>
                    <div *ngIf="item?.qtyFinancialYear > 0" style="padding: 15px 0">
                      <mat-label> Anos de exercício:</mat-label>
                      <mat-accordion style="color: white" class="financial-years">
                        <mat-expansion-panel class="mt-2" *ngFor="let year of item.financialYears">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{ year }}
                            </mat-panel-title>
                            <mat-panel-description>
                              {{ getDocumentStatus(item, year) }}
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                          <div class="mt-2" class="financial-years-content">
                            <p class="mb-2">
                              Anexe o {{ item?.name }} de {{ year }} abaixo e informe a data de emissão do documento.
                            </p>
                            <p class="mt-4"
                              *ngIf="item?.fileInfo?.length === 0 && getDocumentStatus(item, year) === 'Pendente'">
                              <strong>Nenhum arquivo foi anexado.</strong>
                            </p>
                            <div *ngIf="item?.fileInfo.length > 0">
                              <div class="attached-file m-2 mt-4 mb-3" *ngFor="let file of item?.fileInfo; let i = index">
                                <p class="file" *ngIf="file?.financialYear === year">
                                  <a class="attached-file-link cursor-style" href="{{ file.path }}" target="_blank"
                                    matTooltip="Abrir arquivo">
                                    {{ file.fileName ? file.fileName : item?.name }}
                                  </a>
                                  <ng-container *ngIf="item?.incomplete !== true">
                                    <span *ngIf="file.dateUpload">
                                      &nbsp; {{ (file.dateUpload | date: 'dd/MM/yyyy HH:mm:ss') }}
                                    </span>
                                    <mat-icon *ngIf="item?.situation !== 'Aprovado' && item?.approvalOrDisapprovalDate"
                                      inline="true" class="ml-2 icon pointer"
                                      (click)="onRemoveFileOfDocument(null, item, file)" matTooltip="Remover arquivo">
                                      clear
                                    </mat-icon>
                                  </ng-container>
                                </p>
                              </div>
                            </div>

                            <ng-container *ngIf="customer?.hasRecurrence === true ">
                              <div class="botao-alteracao-1"
                                *ngIf="!item?.customerConfirmation && item?.fileInfOld.length > 0">
                                <button mat-raised-button color="primary" (click)="onButtonNoChange(item.uid, item.typeId)"
                                  [disabled]="item?.hasNoAlterationRec">
                                  <mat-label>Não houve alterações</mat-label>
                                </button>
                                <mat-spinner [diameter]="20" color="primary" class="d-inline-block mx-3"
                                  *ngIf="isProcessing">
                                </mat-spinner>
                              </div>
                            </ng-container>

                            <div class="attach-file financial-years">
                              <button mat-raised-button color="primary" (click)="onSendNewFile(item, year)"
                                [disabled]="!canUploadFiles(item,year)">
                                Enviar Arquivo
                              </button>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>

                    <div *ngIf="item?.qtyFinancialYear == 0 || item?.qtyFinancialYear == null">
                      <div class="mt-2" class="financial-years-content">
                        <p class="mb-2">Anexe o {{ item?.name }} abaixo e informe a data de emissão do documento.</p>
                        <p class="mb-2" *ngIf="!item?.fileInfo">
                          <strong>Nenhum arquivo foi anexado.</strong>
                        </p>
                        <div class="attached-file mt-4 mb-3" *ngIf="item?.fileInfo.length > 0 ">
                          <p class="file" *ngFor="let file of item?.fileInfo; let i = index">
                            <a class="attached-file-link cursor-style" [href]="file.path" target="_blank"
                              matTooltip="Abrir arquivo">
                              {{ file.fileName ? file.fileName : item?.name }}
                            </a>
                            <ng-container *ngIf="item?.incomplete !== true">
                              <mat-icon *ngIf="item?.situation !== 'Aprovado' && item?.approvalOrDisapprovalDate"
                                class="ml-3 icon pointer" inline="true" (click)="onRemoveFileOfDocument(i, item, file)"
                                matTooltip="Remover arquivo">
                                clear
                              </mat-icon>
                            </ng-container>
                          </p>
                        </div>
                        <div class="attach-file">
                          <button mat-raised-button color="primary" (click)="onSendNewFile(item)"
                            [disabled]="!canUploadFiles(item)">
                            Enviar arquivo
                          </button>
                        </div>
                        <ng-container *ngIf="customer?.hasRecurrence === true">
                          <div class="botao-alteracao" *ngIf="!item?.customerConfirmation && item?.fileInfOld.length > 0">
                            <button mat-raised-button color="primary" (click)="onButtonNoChange(item.uid, item.typeId)"
                              [disabled]="item?.hasNoAlterationRec">
                              <mat-label>Não houve alterações</mat-label>
                            </button>
                            <mat-spinner [diameter]="18" color="primary" class="d-inline-block mx-3" *ngIf="isProcessing">
                            </mat-spinner>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
