import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { BankersResult } from 'functions/src/models/Banker';
import { Contract } from 'functions/src/models/Contract';
import { Customer } from 'functions/src/models/Customer';
import { BankerNote } from 'functions/src/models/Notes';
import { Document as DocumentMongoDB } from 'functions/src/models/documents/UserDocument';
import { DocumentParameter } from 'functions/src/models/model-interface';
import { Observable, Observer } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserDocumentService } from 'src/app/customer/services/user-document.service';
import { environment } from 'src/environments/environment';
import { BankerContract } from '../../../../functions/src/models/contract/BankerContract';
import { CustomerAndOpportunities } from '../../../../functions/src/models/customers/Customer';
import { Opportunity } from '../../../../functions/src/models/opportunity/Opportunity';
import { OpportunityStageSituationBanker } from '../../../../functions/src/models/opportunity/OpportunityStages';

import { BankerFilter } from '../../../../functions/src/models/common/BankerFilter';
import { OpportunityManagementService } from './../../admin/services/opportunity-management.service';
import { UserManagementService } from './../../admin/services/user-management.service';
@Injectable({
  providedIn: 'root',
})
export class OpportunityService {
  constructor(
    private ums: UserManagementService,
    private angularFirestore: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient,
    private userDocumentService: UserDocumentService,
    private oppMService: OpportunityManagementService
  ) { }

  createNote(uid: string, institution: string, note: BankerNote, notificationData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const now = firestore.Timestamp.now();
      Promise.all([
        this.angularFirestore
          .collection('customers')
          .doc(uid)
          .collection('banker-notes')
          .doc(institution)
          .set({ institution }),
        this.angularFirestore
          .collection('customers')
          .doc(uid)
          .collection('banker-notes')
          .doc(institution)
          .collection('notes')
          .doc(now.toDate().toISOString())
          .set({
            ...note,
            createdAt: now,
            id: now.toDate().toISOString(),
          }),
      ])
        .then(() => {
          this.http
            .post(
              `${ environment.functionsUrl }/email/notify-new-note`,
              {
                banker: note.createdBy.name,
                institution: notificationData.institution,
                companyName: notificationData.companyName,
                content: note.message,
                customer: notificationData.companyName,
                redirectTo: `${ environment.baseURL }/admin/clientes/detalhes/${ uid }`,
                type: 'admin-banker',
              },
              { responseType: 'text', headers: this.authService.getHeader() }
            )
            .toPromise()
            .then(() => {
              console.log('Note notification was sent to admin');
            })
            .catch((error) => {
              console.error('Error sending note notification to admin.', error);
            });
          resolve();
        })
        .catch((error) => reject(error));
    });
  }

  updateNote(uid: string, institution: string, note: BankerNote): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const now = firestore.Timestamp.now();
      const newNote = note;
      newNote.lastUpdate = now;

      Promise.all([
        this.angularFirestore
          .collection('customers')
          .doc(uid)
          .collection('banker-notes')
          .doc(institution)
          .set({ institution }),
        this.angularFirestore
          .collection('customers')
          .doc(uid)
          .collection('banker-notes')
          .doc(institution)
          .collection('notes')
          .doc(newNote.id)
          .set(newNote),
      ])
        .then(() => {
          resolve();
        })
        .catch((error) => reject(error));
    });
  }

  getAllNotes(customer: string, institution: string): Observable<BankerNote[]> {
    return this.angularFirestore
      .collection('customers')
      .doc(customer)
      .collection('banker-notes')
      .doc(institution)
      .collection<BankerNote>('notes', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges();
  }

  getBigDataCorpSavedData(uid: string): Observable<any> {
    return this.angularFirestore.collection(`customers`).doc(uid).collection('bigdatacorp').valueChanges();
  }

  getContractData(uid: string): Observable<Contract> {
    return this.angularFirestore.collection<Contract>('contract').doc(uid).valueChanges();
  }

  getNotesPreview(customer: string, institution: string, quantity: number): Observable<BankerNote[]> {
    return this.angularFirestore
      .collection('customers')
      .doc(customer)
      .collection('banker-notes')
      .doc(institution)
      .collection('notes', (ref) => ref.orderBy('createdAt', 'desc').limit(quantity))
      .valueChanges();
  }

  getOpportunityStatus(opportunity: Opportunity): string {
    if (opportunity) {
      const step = opportunity?.stageOpportunity?.defaultprobability;
      const status = OpportunityStageSituationBanker[step];

      return status;
    } else {
      return '-';
    }
  }

  getCustomerData(uid: string): Observable<Customer> {
    return this.angularFirestore.collection('customers').doc(uid).valueChanges();
  }

  getCustomerDocuments(uid: string, institutionName: string): Observable<DocumentMongoDB[]> {
    return new Observable((obs: Observer<DocumentMongoDB[]>) => {
      const subs = this.userDocumentService.getDocuments({ uid, situations: ['Aprovado'] }).then(async (documents) => {
        if (!institutionName) {
          // Only basic (automatic) or visible to banker documentation with id "sim"
          obs.next(documents.filter((doc) => doc.automatic || (doc.visibleToBanker && doc.visibleToBanker.id === 'sim')));
        } else {
          try {
            const institutionPromise = this.angularFirestore.firestore
              .collection('instituicoes-financeiras')
              .where('nomeNoSistema', '==', institutionName)
              .get();

            const institutionSnap = (await institutionPromise).docs[0];
            const institutionId = institutionSnap.id as string;
            const institutionDocsPromise = this.angularFirestore.firestore
              .collection('inst-financ-documentos')
              .doc(institutionId)
              .get();

            const institutionDocs = (await institutionDocsPromise).data() as DocumentParameter;

            this.ums.getTiposDocumentosAtivos().subscribe(tiposDocumentos => {
              const docsToBanker =
                documents?.filter((userDoc) =>
                  (userDoc.automatic && ((userDoc.documentAction !== 'sign') ||
                    (userDoc.documentAction === 'sign' && institutionDocs?.documents?.some((instDoc) => instDoc.id === userDoc.typeId)))) ||
                  (userDoc.visibleToBanker && userDoc.visibleToBanker.id === 'sim') ||
                  institutionDocs?.documents?.some((instDoc) => instDoc.id === userDoc.typeId) ||
                  (userDoc.visibleToBanker === undefined && tiposDocumentos?.length && tiposDocumentos.some((tipoDoc) => tipoDoc.visibleToBanker && tipoDoc.visibleToBanker.id === 'sim' && tipoDoc.mnemonico === userDoc.mnemonic))
                ) || [];

              obs.next(docsToBanker);
            });

          } catch (err) {
            obs.error(err);
          }
        }
      });
    });
  }

  rejectOpportunity(customer: string, opportunity: Opportunity, banker: {
    uid: string;
    name: string;
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/banker/opportunities/reject`,
          { customer, opportunity, banker },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Opportunity rejected.');
          resolve();
        })
        .catch((err) => {
          console.error('Error rejecting opportunity.', err);
          reject(err);
        });
    });
  }


  createPersonRyze(uid: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/integrationsapi/ryze/person`,
          { data: {
            customerId: uid
          }},
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Opportunity rejected.');
          resolve();
        })
        .catch((err) => {
          console.error('Error rejecting opportunity.', err);
          reject(err);
        });
    });
  }

  reportOpportunityOutOfRegion(customer: string, banker: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/banker/opportunities/report-out-of-region`,
          { customer, banker },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Opportunity reported.');
          resolve();
        })
        .catch((err) => {
          console.error('Error reporting opportunity.', err);
          reject(err);
        });
    });
  }

  searchOpportunities(filter: Partial<BankerFilter>): Promise<BankersResult['results']> {
    return new Promise((resolve, reject) => {
      let params = new HttpParams();
      const arrayOfParams = Object.keys(filter);
      arrayOfParams.map(param => {
        params = params.append(param, filter[param]);
      });
      this.http
        .get(`${ environment.functionsUrl }/banker/opportunities?${ params }`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => {
          resolve(res as BankersResult['results']);
        })
        .catch((err) => {
          console.error('Error getting opportunities of banker.', err);
          reject(err);
        });
    });
  }

  searchRejectedOpportunities({
    bank,
    banker,
    filterField,
    filterValue,
    orderField,
    orderDirection,
    pageSize,
    page,
    isRegionalized,
    regionalization,
    minRevenue,
    maxRevenue,
  }): Promise<BankersResult> {
    return new Promise((resolve, reject) => {
      if (orderField === 'createdAt') {
        orderField = 'termsSignatureDate';
      }

      const params =
        `bank=${ bank }&banker=${ banker }` +
        `&filterField=${ filterField }&filterValue=${ filterValue }` +
        `&orderField=${ orderField }&orderDirection=${ orderDirection }` +
        `&page=${ page }&pageSize=${ pageSize }` +
        `&isRegionalized=${ JSON.stringify(isRegionalized) }` +
        `&minRevenue=${ minRevenue }&maxRevenue=${ maxRevenue }&regionalization=${ JSON.stringify(regionalization) }`;

      this.http
        .get(`${ environment.functionsUrl }/banker/opportunities/search-rejected?${ params }`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => {
          resolve(res as BankersResult);
        })
        .catch((err) => {
          console.error('Error getting  concluded opportunities of banker.', err);
          reject(err);
        });
    });
  }

  submitContract(
    customer: string,
    companyName: string,
    currentOpportunity: Opportunity,
    contract: BankerContract,
    banker: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const opportunity: Opportunity = {
        ...currentOpportunity,
        contract: {
          ...contract,
          sender: banker,
        },
      };

      this.http
        .post(
          `${ environment.functionsUrl }/banker/opportunities/update-contract`,
          { customer, companyName, opportunity, banker },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Contract saved.');
          resolve();
        })
        .catch((err) => {
          console.error('Error saving contract.', err);
          reject(err);
        });
    });
  }

  updateProposal(customer: string, opportunity: Opportunity, banker: string, companyName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/banker/opportunities/update-proposal`,
          { customer, opportunity, banker, companyName },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Proposal saved.');
          resolve();
        })
        .catch((err) => {
          console.error('Error saving proposal.', err);
          reject(err);
        });
    });
  }

  getCustomerAndOpportunity(uid: string): Promise<CustomerAndOpportunities> {
    const params = `filterField=uid&filterValue=${ uid }&agregate_opportunities=true`;
    return new Promise((resolve, reject) => {
      this.http
        .get<CustomerAndOpportunities[]>(`${ environment.functionsUrl }/customer?` + params, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => resolve(res[0]))
        .catch((error) => {
          console.error('Error Ocurred - Get Opportunities: ', error);
          reject(error);
        });
    });
  }

  getOpportunityOfBanker(uid: string, institutionKey: string): Promise<Opportunity> {
    return this.oppMService.getOpportunity(uid, institutionKey);
  }
}
