<div class="recommend-container">
    <div class="recommend-nav">
        <div class="recommend-wrapper">
            <div class="recommend">
                <div class="recommend-item" routerLink="/mensagens">
                    <img src="../../../assets/images/icons/comments-solid.png" alt="" class="img-left" />
                    <div class="text">
                        <p class="txt-black">Tem dúvidas?</p>
                        <p class="txt-green"><a class="txt-green" routerLink="/mensagens">Fale conosco</a></p>
                    </div>
                </div>
              
                    <div  class="mt-2">
                        <app-user-cupom></app-user-cupom>
                    </div>
               
            </div>
        </div>
    </div>
</div>
