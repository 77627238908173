<div class="user-document-container">
  <div class="inner-container">
    <div>
      <div class="content row">
        <div class="col-12 col-md-7">
          <h1 class="mb-4">Meus documentos</h1>

          <p>
            Estamos processando suas informações.
            <span *ngIf="customer?.revenue >= 360000">Enquanto isso, <span class="highlight">carregue seus documentos
                pendentes</span>. Quanto antes você enviar, maiores suas chances de obter o empréstimo mais barato e
              mais rápido!
            </span>
          </p>

          <div class="contact-section-wrapper">
            <p>Tem dúvidas ou gostaria de enviar seus documentos por mensagem?</p>
            <a mat-flat-button color="primary" class="action-button" type="button" routerLink="/mensagens">
              Fale conosco
            </a>
          </div>
          <div class="text-center" *ngIf="enableLoading">
            <mat-spinner [diameter]="60" color="primary" class="mx-auto"></mat-spinner>
          </div>

        </div>
        <div class="detail col-12 col-md-5">
          <div class="accountant-card-container" *ngIf="!hasAccountant || isEditingAccountantData">
            <h4>Dados do contador</h4>
            <p>Quer ajuda para enviar seus documentos? Informe os dados do seu contador abaixo e entraremos em contato
              diretamente com ele para auxiliá-lo!</p>
            <form [formGroup]="accountantDataFormGroup">
              <mat-checkbox formControlName="allowAccountantContact" [checked]="checked"
                (click)="onChangeAccountantContactAuthorization(); isEditingAccountantData = true" color="primary">
                Autorizo a Capital Empreendedor solicitar ao meu contador os documentos necessários para captação de
                recursos para minha empresa e/ou para mim.
              </mat-checkbox>
              <div class="inputs-container">
                <input formControlName="accountantEmail" placeholder="Email do contador" required />
                <mat-error
                  *ngIf="accountantDataFormGroup.get('accountantEmail')?.touched && accountantDataFormGroup.get('accountantEmail')?.hasError('required')">
                  O e-mail do contador é obrigatório.
                </mat-error>
                <mat-error
                  *ngIf="accountantDataFormGroup.get('accountantEmail')?.touched && accountantDataFormGroup.get('accountantEmail')?.hasError('email')">
                  Informe um e-mail válido.
                </mat-error>
                <input formControlName="accountantPhone" placeholder="Telefone do contador" required />
                <mat-error
                  *ngIf="accountantDataFormGroup.get('accountantPhone')?.touched && accountantDataFormGroup.get('accountantPhone')?.hasError('required')">
                  O telefone do contador é obrigatório.
                </mat-error>
              </div>
              <div class="action-container">
                <button class="cancel-button" (click)="cancelEditing()">Cancelar</button>
                <button class="save-button" type="submit" (click)="saveAccountantData()"
                  [disabled]="!accountantDataFormGroup.get('allowAccountantContact').value">Salvar</button>
              </div>
            </form>
          </div>
          <div class="accountant-card-container" *ngIf="hasAccountant && !isEditingAccountantData">
            <h4>Dados do contador</h4>
            <div class="filled-content">
              <p>Email: {{ customer.accountantData.email }}</p>
              <p>Telefone: {{ customer.accountantData.phone || '-' }}</p>
            </div>
            <button class="action-button"
              (click)="onChangeAccountantContactAuthorization(true); isEditingAccountantData = true">Alterar
              dados</button>
          </div>
        </div>
      </div>

      <div class="mt-4" class="documents-list-container">
        <div *ngIf="documentsLength > 0">
          <div class="mb-3 mt-2 accepted-documents">
            <div class="documents-quantity">
              <label>Documentos aceitos:</label>
              <div>
                <span class="accepted">{{ acceptedDocuments }}</span>
                <span class="total">/{{ documentsLength }}</span>
              </div>
            </div>
            <div class="mt-1 mb-2 progress-bar">
              <mat-progress-bar class="bar" mode="determinate"
                [value]="(acceptedDocuments/documentsLength)*100"></mat-progress-bar>
            </div>
            <div class="progress">
              <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <p class="my-3" class="expand-text">Clique para expandir, visualizar as instruções e carregar cada um deles:
          </p>

          <!-- <div class="my-3">
            <mat-slide-toggle [(ngModel)]="groupByStatus" color="primary">
              Agrupar documentos por status
            </mat-slide-toggle>
          </div> -->
        </div>

        <mat-accordion class="example-headers-align" *ngIf="documentsToSign.length > 0">
          <mat-expansion-panel class="carta-scr" *ngFor="let docSign of documentsToSign">
            <mat-expansion-panel-header>
              <mat-panel-title> Assinar {{docSign.name}} </mat-panel-title>
              <mat-panel-description> Pendente </mat-panel-description>
            </mat-expansion-panel-header>
            <div style="display: flex; flex-direction: column; margin-top: 12px">
              <p *ngIf="docSign.instructions !== ''" [innerHTML]="docSign.instructions"></p>
              <button mat-raised-button style="width: fit-content; align-self: start" [disabled]="isClicksignWidgetOpen"
                (click)="openClicksignWidget(docSign.typeId, docSign.description)">
                Assinar
                <mat-spinner [diameter]="18" color="accent" class="d-inline-block mx-3" *ngIf="isClicksignWidgetOpen">
                </mat-spinner>
              </button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="p-3 mb-3 color-gray-800" style="background-color: rgba(5, 196, 107, 0.2); border-radius: 10px"
          *ngIf="showTextAfterSign">
          <p *ngIf="textAfterSign !== ''" [innerHTML]="textAfterSign"></p>
          <p style="margin-bottom: 0px">
            <strong>ATENÇÃO: </strong>Caso o documento não fique disponível, entre em contato conosco ou com seu
            assessor.
          </p>
        </div>

        <mat-accordion *ngIf="groupByStatus" class="example-headers-align">
          <mat-expansion-panel *ngFor="let docStatGroup of documentsStatusGroups" [class.d-none]="!docStatGroup.count">
            <mat-expansion-panel-header style="border-left: solid; border-left-width: 8px"
              [style.border-left-color]="docStatGroup.color">
              <mat-panel-title>
                {{ docStatGroup.text }} <strong class="ml-1">({{ docStatGroup.count }})</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="descricao">
              <ng-container
                *ngTemplateOutlet="docItems; context: { total: docStatGroup.count, documents: docStatGroup.docs }"></ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <div *ngIf="!groupByStatus">
          <ng-container *ngTemplateOutlet="
              docItems;
              context: { total: documents.length, documents: documentsToShowOnList }
            "></ng-container>
        </div>

        <ng-template #docItems let-documents="documents" let-total="total">
          <mat-accordion class="example-headers-align" *ngIf="total > 0">
            <mat-expansion-panel class="inside-cards" *ngFor="let item of documents">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ item?.name }}
                </mat-panel-title>
                <mat-panel-description>
                  {{ getDocumentStatus(item) }}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="descricao">
                <h4>Descrição</h4>
                <div class="d-flex justify-content-between flex-column flex-md-row">
                  <mat-label>{{ item?.description }}</mat-label>
                </div>
              </div>
              <div class="instrucoes">
                <h4>Instruções</h4>
                <div class="content" innerHtml="{{ item?.instructions }}" style="padding-top: 5px"></div>
              </div>
              <div class="detalhes">
                <h4>Detalhes</h4>
                <div class="d-flex flex-column">
                  <div *ngIf="item?.situation === 'Aprovado' && item?.approvalOrDisapprovalDate"
                    style="padding-bottom: 10px">
                    <mat-label>
                      Aprovado em {{ item?.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                    </mat-label>
                  </div>
                  <div *ngIf="item?.situation === 'Reprovado' && item?.approvalOrDisapprovalDate"
                    style="padding-bottom: 10px">
                    <mat-label>
                      Reprovado em {{ item?.approvalOrDisapprovalDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                    </mat-label>
                    <br />
                    <mat-label *ngIf="item?.disapprovalReason"> Motivo: {{ item?.disapprovalReason }} </mat-label>
                  </div>
                </div>
                <div>
                  <mat-label *ngIf="item?.qtyExpectedFiles - item?.qtySubmittedFiles > 0">Aguardando
                    {{
                    item?.qtyExpectedFiles - item?.qtySubmittedFiles < 0 ? 0 : item?.qtyExpectedFiles -
                      item?.qtySubmittedFiles }} de {{ item?.qtyExpectedFiles }} {{ item?.qtyExpectedFiles> 1 ?
                      'arquivos' : 'arquivo' }}</mat-label>
                </div>
                <div>
                  <mat-label>
                    Limite de arquivos{{ item.qtyFinancialYear > 0 ? ' (por ano): ' : ': ' }}
                    {{ item?.maximumDocuments || item?.minimumDocuments || 1 }}
                  </mat-label>
                </div>
                <div class="d-flex flex-column flex-md-row" *ngIf="item?.expirationDate?.id === 'sim'"
                  style="padding-top: 10px">
                  <mat-label> Validade: {{ item?.qtyDays }} dias</mat-label>
                </div>
                <div *ngIf="item?.qtyFinancialYear > 0" style="padding: 15px 0">
                  <mat-label> Anos de exercício:</mat-label>
                  <mat-accordion style="color: white" class="financial-years">
                    <mat-expansion-panel class="mt-2" *ngFor="let year of item.financialYears">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{ year }}
                        </mat-panel-title>
                        <mat-panel-description>
                          {{ getDocumentStatus(item, year) }}
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div class="mt-2" class="financial-years-content">
                        <p class="mb-2">
                          Anexe o {{ item?.name }} de {{ year }} abaixo e informe a data de emissão do documento.
                        </p>
                        <p class="mt-4"
                          *ngIf="item?.fileInfo?.length === 0 && getDocumentStatus(item, year) === 'Pendente'">
                          <strong>Nenhum arquivo foi anexado.</strong>
                        </p>
                        <div *ngIf="item?.fileInfo.length > 0">
                          <div class="attached-file m-2 mt-4 mb-3" *ngFor="let file of item?.fileInfo; let i = index">
                            <p class="file" *ngIf="file?.financialYear === year">
                              <a class="attached-file-link cursor-style" href="{{ file.path }}" target="_blank"
                                matTooltip="Abrir arquivo">
                                {{ file.fileName ? file.fileName : item?.name }}
                              </a>
                              <ng-container *ngIf="item?.incomplete !== true">
                                <span *ngIf="file.dateUpload">
                                  &nbsp; {{ (file.dateUpload | date: 'dd/MM/yyyy HH:mm:ss') }}
                                </span>
                                <mat-icon *ngIf="item?.situation !== 'Aprovado' && item?.approvalOrDisapprovalDate"
                                  inline="true" class="ml-2 icon pointer"
                                  (click)="onRemoveFileOfDocument(null, item, file)" matTooltip="Remover arquivo">
                                  clear
                                </mat-icon>
                              </ng-container>
                            </p>
                          </div>
                        </div>

                        <ng-container *ngIf="customer?.hasRecurrence === true ">
                          <div class="botao-alteracao-1"
                            *ngIf="!item?.customerConfirmation && item?.fileInfOld.length > 0">
                            <button mat-raised-button color="primary" (click)="onButtonNoChange(item.uid, item.typeId)"
                              [disabled]="item?.hasNoAlterationRec">
                              <mat-label>Não houve alterações</mat-label>
                            </button>
                            <mat-spinner [diameter]="20" color="primary" class="d-inline-block mx-3"
                              *ngIf="isProcessing">
                            </mat-spinner>
                          </div>
                        </ng-container>

                        <div class="attach-file financial-years">
                          <button mat-raised-button color="primary" (click)="onSendNewFile(item, year)"
                            [disabled]="!canUploadFiles(item,year)">
                            <!-- <a mat-raised-button color="primary" (click)="onSendNewFile(item, year)"
                          [disabled]="
                            item.situation === 'Aprovado' || getDocumentStatus(item, year) === 'Enviado' && item?.incomplete !== true
                          "> -->
                            Enviar Arquivo
                          </button>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>

                <div *ngIf="item?.qtyFinancialYear == 0 || item?.qtyFinancialYear == null">
                  <div class="mt-2" class="financial-years-content">
                    <p class="mb-2">Anexe o {{ item?.name }} abaixo e informe a data de emissão do documento.</p>
                    <p class="mb-2" *ngIf="!item?.fileInfo">
                      <strong>Nenhum arquivo foi anexado.</strong>
                    </p>
                    <div class="attached-file mt-4 mb-3" *ngIf="item?.fileInfo.length > 0 ">
                      <p class="file" *ngFor="let file of item?.fileInfo; let i = index">
                        <a class="attached-file-link cursor-style" [href]="file.path" target="_blank"
                          matTooltip="Abrir arquivo">
                          {{ file.fileName ? file.fileName : item?.name }}
                        </a>

                        <ng-container *ngIf="item?.incomplete !== true">
                          <!-- <span *ngIf="file.dateUpload">
                            &nbsp; {{ (file.dateUpload | date: 'dd/MM/yyyy HH:mm:ss') }}
                          </span> -->

                          <mat-icon *ngIf="item?.situation !== 'Aprovado' && item?.approvalOrDisapprovalDate"
                            class="ml-3 icon pointer" inline="true" (click)="onRemoveFileOfDocument(i, item, file)"
                            matTooltip="Remover arquivo">
                            clear
                          </mat-icon>
                        </ng-container>
                      </p>
                    </div>
                    <div class="attach-file">
                      <button mat-raised-button color="primary" (click)="onSendNewFile(item)"
                        [disabled]="!canUploadFiles(item)">
                        <!-- old
                      <a mat-raised-button color="primary" (click)="onSendNewFile(item)"
                        [disabled]="item.situation === 'Aprovado' || getDocumentStatus(item) === 'Enviado' && item?.incomplete !== true || item.situation === 'Enviado Parcialmente'"> -->
                        <!-- <span >Enviar arquivo</span> -->
                        Enviar arquivo
                      </button>
                    </div>
                    <ng-container *ngIf="customer?.hasRecurrence === true">
                      <div class="botao-alteracao" *ngIf="!item?.customerConfirmation && item?.fileInfOld.length > 0">
                        <button mat-raised-button color="primary" (click)="onButtonNoChange(item.uid, item.typeId)"
                          [disabled]="item?.hasNoAlterationRec">
                          <mat-label>Não houve alterações</mat-label>

                        </button>
                        <mat-spinner [diameter]="18" color="primary" class="d-inline-block mx-3" *ngIf="isProcessing">
                        </mat-spinner>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-template>
      </div>
    </div>
  </div>
</div>