import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'functions/src/models/Customer';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { LogService } from 'src/app/components/logger/log.service';
import { UploadDialogComponent } from 'src/app/components/upload-dialog/upload-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserDocumentService } from 'src/app/customer/services/user-document.service';
import { Document as DocumentMongoDB } from '../../../../../functions/src/models/documents/UserDocument';
import { InstituicaoFinanceira } from '../../../admin/instituicao-financeira/instituicao-financeira';
import { UserManagementService } from '../../../admin/services/user-management.service';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { ClicksignDialogComponent } from '../../../components/clicksign-widget/clicksign-dialog.component';
import { ClicksignService } from '../../services/clicksign.service';
import { ContractService } from '../../services/contract.service';
import { CustomerService } from '../../services/customer.service';
import { FinancialInstitutionsService } from '../../services/financial-institutions.service';
import { SidebarStateService } from '../../services/sidebar-state.service';
import { DocumentAction } from './../../../../../functions/src/models/documents/UserDocument';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-user-document',
  templateUrl: './user-document.component.html',
  styleUrls: ['./user-document.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserDocumentComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['nome', 'situacao', 'ativo', 'acao'];
  customer: Customer;
  customerUid: string;
  documentSelected: DocumentMongoDB;
  documents: DocumentMongoDB[];
  doc: DocumentMongoDB;
  userSubscription: Subscription;
  userDocumentsSubscription: Subscription;
  getDetalhesBancoSubscription: Subscription;
  financialInstitutionsSubscription: Subscription;
  financialInstitutions: InstituicaoFinanceira[];
  documentsLength: number;
  acceptedDocuments: number;
  selectedDocument: any;
  canUpload: boolean;
  yearDocuments: any;
  documentsToShowOnList: DocumentMongoDB[];
  isClicksignWidgetOpen: boolean;
  isDisabled: boolean;
  documentsToSign: DocumentMongoDB[];
  showTextAfterSign: boolean;
  textAfterSign: string;
  requestSignatureKey: string;
  hasAccountant = false;
  enableLoading: boolean;
  isSaving = false;
  isEditingAccountantData = false;
  isMobile: boolean;

  accountantDataFormGroup: FormGroup;

  groupByStatus = true;

  isProcessing = false;

  // to do: colocar isso em models ou serviço
  documentsStatusGroups: {
    key: string;
    text: string;
    docs: DocumentMongoDB[];
    color: string;
    count?: number;
  }[] = [
      { key: 'Pendente', text: 'Documentos pendentes', color: '#B9B9B9', docs: [] },
      { key: 'Em Progresso', text: 'Documentos em progresso', color: '#B9B9B9', docs: [] },
      // { key: 'Enviado Parcialmente' , text : 'Documentos Pendentes' },
      { key: 'Envio Completo', text: 'Documentos sob análise', color: '#6B98F0', docs: [] },
      { key: 'Aprovado', text: 'Documentos aprovados', color: '#05C46B', docs: [] },
      { key: 'Reprovado', text: 'Documentos rejeitados', color: '#F06B6B', docs: [] },
    ];

  @ViewChild(FileUploadComponent, { static: false }) fileUpload: FileUploadComponent;
  documentosAtivosSubscription: Subscription;
  tipeDocuments: import("./../../../../../functions/src/models/model-interface").TipoDocumento[];

  constructor(
    private ums: UserManagementService,
    public authService: AuthService,
    private clicksignService: ClicksignService,
    private dialog: MatDialog,
    private financialInstitutionsService: FinancialInstitutionsService,
    private userDocumentService: UserDocumentService,
    private logger: LogService,
    private customerService: CustomerService,
    private contractService: ContractService,
    private activatedRoute: ActivatedRoute,
    private sidebarStateService: SidebarStateService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.acceptedDocuments = 0;
    this.documents = [];
    this.documentsToShowOnList = [];
    this.logger.controllerName = this.constructor.name;
    this.isClicksignWidgetOpen = false;
    this.isDisabled = false;
    this.documentsToSign = [];
    this.showTextAfterSign = false;

    const routeSnapshot = this.activatedRoute.snapshot;
    const routeData = routeSnapshot.data;
    this.sidebarStateService.updateCurrentSection(routeData);
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.enableLoading = true;
    this.accountantDataFormGroup = new FormGroup({
      allowAccountantContact: new FormControl('', []),
      accountantEmail: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
      accountantPhone: new FormControl({ value: '', disabled: true }, [Validators.minLength(14)]),
    });



    this.userSubscription = this.customerService.customer.subscribe((customer) => {
      this.customer = customer;
      this.customerUid = this.customer?.uid;

      if (this.customer?.uid) {
        this.getDocuments();
        this.hasAccountant = !!this.customer.accountantData?.email;

        if (this.hasAccountant) {
          this.accountantDataFormGroup.get('allowAccountantContact').setValue(true);
          this.accountantDataFormGroup.get('accountantEmail').setValue(this.customer.accountantData.email);
          this.accountantDataFormGroup.get('accountantPhone').setValue(this.customer.accountantData.phone);
        }

        this.enableLoading = false;
      }

    });

    this.financialInstitutionsSubscription = this.financialInstitutionsService
      .getFinancialInstitutions()
      .subscribe((financialInstitutions) => {
        this.financialInstitutions = financialInstitutions;
      });


  }

  ngOnDestroy(): void {
    this.documentosAtivosSubscription?.unsubscribe();

    if (this.userDocumentsSubscription) {
      this.userDocumentsSubscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.getDetalhesBancoSubscription) {
      this.getDetalhesBancoSubscription.unsubscribe();
    }

    if (this.financialInstitutionsSubscription) {
      this.financialInstitutionsSubscription.unsubscribe();
    }
  }

  getDocuments(): void {
    const { uid } = this.customer;
    this.userDocumentService.getDocuments({ uid }).then(async (documents) => {

      console.log(`User documents subscribe ${ new Date().toISOString() }`);
      this.documents = await Promise.all(documents
        .map((d) => {
          if (d.financialYear?.id === 'sim') {
            const currentYear = new Date().getFullYear();
            let financialYears: DocumentMongoDB['financialYears'] = null;
            const anoExercicioCorrente = d.anoExercicioCorrente ?? false;
            let countYear = 0;

            while (countYear < d.qtyFinancialYear) {

              let financialYear;

              if (anoExercicioCorrente || (anoExercicioCorrente && d.financialYears?.includes(currentYear.toString()))) {
                financialYear = (currentYear - countYear).toString();
                countYear++;
              } else {
                countYear++;
                financialYear = (currentYear - countYear).toString();
              }

              if (countYear === 1) {
                financialYears = [financialYear];
              } else {
                if (financialYears?.length > 0 && !financialYears.includes(financialYear)) {
                  console.log('year', financialYear);
                  financialYears.push(financialYear);
                }
              }
            }

            d.financialYears = financialYears;

          }
          return d;
        })
        ?.sort((o1, o2) => {
          if (o1.ordenation > o2.ordenation) {
            return 1;
          } else if (o1.ordenation < o2.ordenation) {
            return -1;
          } else {
            return 0;
          }
        }).map(async (d) => {
          const typeDocument = await this.ums.getTipoDocumentoByMnemonic(d.mnemonic).toPromise();
          if (typeDocument && typeDocument.maximumDocuments !== d.maximumDocuments) d.maximumDocuments = typeDocument.maximumDocuments;
          if (typeDocument && typeDocument.minimumDocuments !== d.minimumDocuments) d.minimumDocuments = typeDocument.minimumDocuments;
          return d;
        }));

      this.documentsLength = this.documents?.length || 0;

      this.acceptedDocuments = _.filter(this.documents, (d) => d?.situation === 'Aprovado').length;

      this.documentsToShowOnList = this.removesFilesToSignFromList(this.documents);
      this.updateDocumentsGroupStatus(this.documentsToShowOnList);

      this.documentsToSign = this.documents?.filter(
        (doc) =>
          doc?.documentAction === DocumentAction.SIGN &&
          !doc?.fileInfo?.find((info) => !!info?.path) &&
          (doc?.qtySubmittedFiles || 0) < (doc?.qtyExpectedFiles || 1) &&
          doc?.situation === 'Pendente'
      );


    });
  }

  onSendNewFile(item: DocumentMongoDB, year = '') {
    const confirmUpload = this.dialog
      .open(UploadDialogComponent, {
        data: {
          item: item,
          exerciseYear: year,
          customer: this.customer,
          dataUserDocuments: this.documents,
          hasEmissionDate: false,
        },
      })
      .afterClosed()
      .subscribe((doc) => {
        let document = this.documents.find((d) => d.typeId === doc.typeId);
        document = doc;
        if (confirmUpload) {
          confirmUpload.unsubscribe();
        }
      });

    this.documentSelected = this.documents.find((t) => t.mnemonic === item.mnemonic);
  }

  getColor(docStatus: string): string {
    switch (docStatus) {
      case 'Pendente':
      case 'Enviado Parcialmente':
        return '#747474';
      case 'Envio Completo':
        return '#FFB82E';
      case 'Aprovado':
        return '#00C46C';
      case 'Reprovado':
        return '#F93232';
      default:
        return '#747474';
    }
  }

  updateDocumentsGroupStatus(documents: DocumentMongoDB[]): void {
    const docs = documents.map((d) => {
      if (d.situation === 'Enviado Parcialmente') {
        d.situation = 'Pendente';
      }
      if (d.situation === 'Assinado - Aguardando Atualização Webhook') {
        d.situation = 'Em Progresso';
      }
      return d;
    });
    const docRecord = _.groupBy(docs, (doc) => doc.situation);

    this.documentsStatusGroups.forEach((docGroup) => {
      docGroup.docs = docRecord[docGroup.key] ?? [];
      docGroup.count = docGroup.docs.length;
    });
  }

  onRemoveFileOfDocument(fileIndex = null, item: DocumentMongoDB, file: DocumentMongoDB['fileInfo'][0]): void {
    const doc = this.documents.find((d) => d.typeId === item.typeId);

    doc.fileInfo = doc.fileInfo.filter((f, i) => f.path !== file.path && fileIndex !== i);
    doc.qtySubmittedFiles = item.fileInfo.length ?? 0;
    if (doc.fileInfo.length === 0) {
      doc.situation = 'Pendente';
      doc.clicksignDocumentKey = null;
      doc.clicksignSignatureRequestKey = null;
    }
    doc.uid = this.customer.uid;
    this.userDocumentService.patchDocument(doc);
  }

  removesFilesToSignFromList(documentsList: DocumentMongoDB[]): DocumentMongoDB[] {
    const documents = documentsList?.filter((document) => {
      if (document?.documentAction === 'sign') {
        return (
          !!document.fileInfo?.find((info) => !!info?.path) &&
          (document?.qtySubmittedFiles || 0) === (document?.qtyExpectedFiles || 1) &&
          document.situation !== 'Pendente'
        );
      } else {
        return true;
      }
    });
    return documents;
  }

  openClicksignWidget(typeId: string, description: string): void {
    this.isClicksignWidgetOpen = true;
    this.processDocumentsToSign(typeId)
      .then((requestSignatureKey) => {
        this.dialog
          .open(ClicksignDialogComponent, {
            closeOnNavigation: false,
            disableClose: false,
            hasBackdrop: true,
            width: '100%',
            height: '90%',
            data: {
              requestSignatureKey,
            },
          })
          .afterClosed()
          .subscribe(async (data) => {
            if (!!data) {
              console.log(data);
              if (data === 'signed') {
                this.showTextAfterSign = true;
                this.textAfterSign = `Obrigado! Estamos processando a assinatura e em breve sua ${ description } estará disponível na Lista de Documentos abaixo.`;
                this.documentsToSign = this.documentsToSign.filter(d => d.typeId !== typeId);
              }
            }
          });
      })
      .catch((error) => console.error(error))
      .finally(() => (this.isClicksignWidgetOpen = false));
  }

  async processDocumentsToSign(typeId: string): Promise<string> {
    const documentsWithTypeId = this.documentsToSign?.filter(d => d.typeId === typeId);
    this.requestSignatureKey = await this.clicksignService.requestSignatureKey({
      uid: this.customer.uid,
      documentsId: documentsWithTypeId.map(d => d.typeId),
    });
    return this.requestSignatureKey;
  }

  getDocumentStatus(item: DocumentMongoDB, year = ''): string {
    const files = year !== '' ? item?.fileInfo?.filter((f) => f.financialYear === year) : item.fileInfo;
    const minimunDocuments = (item?.minimumDocuments || 1) * (year === '' ? item.qtyFinancialYear : 1);
    return files.length > 0 ? (minimunDocuments > files.length ? 'Enviado Parcialmente' : 'Enviado') : 'Pendente';
  }

  canUploadFiles(item: DocumentMongoDB, year = ''): boolean {
    const files = year !== '' ? item?.fileInfo?.filter((f) => f.financialYear === year) : item.fileInfo;
    const maximumDocuments = item?.maximumDocuments || item?.minimumDocuments || 1;
    if (item.situation === 'Aprovado') return false;
    return maximumDocuments > files.length;
  }


  onButtonNoChange(uid: string, typeId: string): void {
    this.isProcessing = true;
    this.dialog
      .open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Confirmar Ação',
          alertDescription: `Deseja realmente informar que não houve alteração no documento?`,
        },
      })
      .afterClosed()
      .toPromise()
      .then(async (confirmed) => {
        if (confirmed) {
          await this.userDocumentService.documentsNoNeedsChange(uid, typeId);
        }
      }).catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.isProcessing = false;
        this.getDocuments();
      });


  }

  validateSendEmailContract(): boolean {
    const guaranteesIds = this.customer.guarantees.map((guarantee) => guarantee.id);
    const revenueGreaterThan240000 = this.customer.revenue > 240000;
    const companyAgeGreaterThan2 = this.customer.companyInformation?.age > 2;

    const hasVehicleOrProperty = guaranteesIds.some((id) => id.includes('veiculos') || id.includes('imovel'));
    const hasDesiredGuarantee = guaranteesIds.some((id) => id.includes('avalista') || id.includes('boleto'));

    return (revenueGreaterThan240000 && (hasVehicleOrProperty || (hasDesiredGuarantee && companyAgeGreaterThan2)));
  }

  saveAccountantData(): void {

    if (this.accountantDataFormGroup.get('accountantEmail').value === "") {
      this.dialog.open(AlertDialogComponent, {
        maxWidth: '600px',
        data: {
          alertTitle: 'Erro',
          alertDescription: 'E-mail do contador deve ser informado. Tente novamente.',
          isOnlyConfirm: true,
        },
      });
    } else {
      if (this.accountantDataFormGroup.get('accountantPhone').value === "") {
        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro',
            alertDescription: 'Telefone do contador deve ser informado. Tente novamente.',
            isOnlyConfirm: true,
          },
        });
      } else {
        this.isSaving = true;
        const accountantData = this.accountantDataFormGroup.get('allowAccountantContact').value
          ? {
            email: this.accountantDataFormGroup.get('accountantEmail').value,
            phone: this.accountantDataFormGroup.get('accountantPhone').value,
          }
          : null;

        this.customerService
          .updateCustomerOnFirestore(this.customer.uid, { accountantData })
          .then(() => {
            if (this.validateSendEmailContract() && accountantData.email) {
              this.contractService.sendEmailToAccountant(
                accountantData.email.toLowerCase(),
                this.customer.companyName?.toUpperCase(),
                this.customer.email.toLowerCase(),
              );
            }

            this.isSaving = false;
            this.isEditingAccountantData = false;

            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Dados do contador salvos',
                alertDescription:
                  'Recebemos os dados do seu contador. Agora podemos entrar em contato assim que for necessário acrescentar ou atualizar algum documento.',
                isOnlyConfirm: true,
              },
            });
          })
          .catch((err) => {
            this.isSaving = false;

            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro',
                alertDescription: 'Ocorreu um erro ao salvar os dados do contador. Tente novamente.',
                isOnlyConfirm: true,
              },
            });

            console.error('Error saving accountant', err);

            this.logger.fatal(
              'Problemas ao atualizar os dados de contador do usuario',
              this.customer.email,
              this.accountantDataFormGroup.get('accountantEmail').value,
              err
            );
          });
      }
    }
  }

  onChangeAccountantContactAuthorization(isChecked = false): void {
    let valueCheckbox = isChecked;
    if (!valueCheckbox) {
      valueCheckbox = !this.accountantDataFormGroup.get('allowAccountantContact').value;
    }

    this.accountantDataFormGroup.get('allowAccountantContact').setValue(this.isMobile || isChecked ? valueCheckbox : !valueCheckbox);

    const accountantEmailControl = this.accountantDataFormGroup.get('accountantEmail');
    const accountantPhoneControl = this.accountantDataFormGroup.get('accountantPhone');

    if (valueCheckbox) {
      accountantEmailControl.enable();
      accountantEmailControl.setErrors({ required: true });
      accountantEmailControl.markAsUntouched();

      accountantPhoneControl.enable();
      accountantPhoneControl.setErrors({ required: true });
      accountantPhoneControl.markAsUntouched();
    } else {
      accountantEmailControl.setValue('');
      accountantEmailControl.disable();
      accountantEmailControl.setErrors([]);

      accountantPhoneControl.setValue('');
      accountantPhoneControl.disable();
      accountantPhoneControl.setErrors([]);
    }
  }

  cancelEditing(): void {
    this.isEditingAccountantData = false;

    const allowAccountantContact = this.accountantDataFormGroup.get('allowAccountantContact');
    const accountantEmailControl = this.accountantDataFormGroup.get('accountantEmail');
    const accountantPhoneControl = this.accountantDataFormGroup.get('accountantPhone');

    allowAccountantContact.setValue(false);
    accountantEmailControl.setValue(this.customer.accountantData.email);
    accountantEmailControl.disable();
    accountantEmailControl.setErrors([]);
    accountantPhoneControl.setValue(this.customer.accountantData.phone || '');
    accountantPhoneControl.disable();
    accountantPhoneControl.setErrors([]);
  }

}
