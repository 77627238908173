import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-opportunity-stream-dialog',
  templateUrl: './opportunity-stream-dialog.component.html',
  styleUrls: ['./opportunity-stream-dialog.component.scss']
})
export class OpportunityStreamDialogComponent implements OnInit {
  currentView: 'first' | 'second' = 'first';
  typeOfCustomer: string;
  numberOfInstructionsControl: FormControl;
  instructionsForm: FormGroup;
  touchCustomerForm: FormGroup;
  isPreviewDialogOpen: boolean;
  isPreviewDialogOpenNotouch: boolean;
  isPreviewDialogOpenNotouchTwo: boolean;
  isYesTouchVisible: boolean;
  isNoTouchVisible: boolean;
  oppDescription: string;
  nameIF: string;

  constructor(
    public dialogRef: MatDialogRef<OpportunityStreamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.isYesTouchVisible = true;
    this.isNoTouchVisible = true;
    this.isPreviewDialogOpen = false;
    this.isPreviewDialogOpenNotouch = false;
    this.isPreviewDialogOpenNotouchTwo = false
    this.typeOfCustomer = this.data.value.id;
    this.nameIF = this.data.institution.nome;
    this.onLoadForm();
    this.numberOfInstructionsControl.valueChanges.subscribe(value => {
      this.instructionsForm.patchValue({ numberOfInstructions: value });
    });
  }
  
  onLoadForm(): void {
    this.numberOfInstructionsControl = new FormControl(this.data.institution.instructions?.numberOfInstructions || ''),
    this.instructionsForm = this.formBuilder.group({
      numberOfInstructions: new FormControl(this.data.institution.instructions?.numberOfInstructions || ''),
      firstInstruction: new FormControl(this.data.institution.instructions?.firstInstruction || ''),
      secondInstruction: new FormControl(this.data.institution.instructions?.secondInstruction || ''),
      thirdInstruction: new FormControl(this.data.institution.instructions?.thirdInstruction || ''),
      fourthInstruction: new FormControl(this.data.institution.instructions?.fourthInstruction || ''),
      fifthInstruction: new FormControl(this.data.institution.instructions?.fifthInstruction || ''),
      buttonText: new FormControl(this.data.institution?.buttonText || ''),
      buttonUrl: new FormControl(this.data.institution?.buttonUrl || '')
    });
  
    this.touchCustomerForm = this.formBuilder.group({
      comments: new FormControl(this.data.institution?.comments || ''),
      proposedValidity: new FormControl(this.data.institution?.proposedValidity || '', Validators.required),
      useReturnApi: new FormControl(this.data.institution?.useReturnApi || '')
    });
  }

  openSecondView() {
    this.currentView = 'second';
    const selectedValue = this.numberOfInstructionsControl.value;
    this.instructionsForm.patchValue({ numberOfInstructions: selectedValue });
  }

  openThirdView() {
    this.isPreviewDialogOpenNotouchTwo = !this.isPreviewDialogOpenNotouchTwo;
    this.isPreviewDialogOpenNotouch =  !this.isPreviewDialogOpenNotouch;
  }


  closeInstructionsDialog() {
    this.dialogRef.close(this.instructionsForm.value);
  }

  closeTouchDialog() {
    this.dialogRef.close(this.touchCustomerForm.value);
  }

  setDialogPreviewOpen() {
    const touchFormValue = this.touchCustomerForm.value;
    this.oppDescription = touchFormValue.comments;
    this.isPreviewDialogOpen = !this.isPreviewDialogOpen;
    this.isYesTouchVisible = !this.isYesTouchVisible;
  }

  setDialogPreviewOpenNoTouch() {
    const touchFormValue = this.touchCustomerForm.value;
    this.oppDescription = touchFormValue.comments;
    this.isPreviewDialogOpenNotouchTwo = false;
    this.isPreviewDialogOpenNotouch = !this.isPreviewDialogOpenNotouch;
    this.isNoTouchVisible = !this.isNoTouchVisible;
  }

  setDialogPreviewOpenNoTouchTwo() {
    const touchFormValue = this.touchCustomerForm.value;
    this.oppDescription = touchFormValue.comments;
    this.isPreviewDialogOpenNotouchTwo = false;
    this.isPreviewDialogOpenNotouch = false;
    this.isNoTouchVisible = !this.isNoTouchVisible;
  }

  
}
