import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root',
})
export class OptinService {
  constructor(private angularFirestore: AngularFirestore) {}

  getOptinConfiguration(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc('configuracao/optin')
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            console.error('Optin document not found');
            reject(new Error('Optin document not found'));
          }
        })
        .catch((err) => {
          console.error('Error getting optin configuration', err);
          reject(err);
        });
    });
  }
}
