<!-- <button [matMenuTriggerFor]="notification" mat-icon-button class="m-r-10" *ngIf="user">
  <mat-icon>notifications</mat-icon>
  <div class="notify">
    <span class="heartbit"></span>
    <span class="point"></span>
  </div>
</button>
<mat-menu #notification="matMenu" class="mymegamenu">
  <div class="mailbox">
    <ul>
      <li>
        <div class="drop-title">Notificações</div>
      </li>
      <li>
        <div style="text-align: center;">
          <mat-label>Sem notificações</mat-label>
        </div>
      </li>
    </ul>
  </div>
</mat-menu> -->

<button mat-flat-button class="border" *ngIf="!user" (click)="login()">Entrar</button>

<!-- <button *ngIf="user && user.isAdmin" mat-icon-button routerLink="s" class="d-sm-inline"> -->
<!-- <button *ngIf="user && user.isAdmin" mat-icon-button routerLink="/admin/clientes/buscar" class="d-sm-inline">
  <mat-icon>settings</mat-icon>
</button> -->

<ng-container *ngIf="!isMobile">
  <button *ngIf="user" mat-icon-button [matMenuTriggerFor]="toolbarUserMenu" class="m-r-5" (mouseenter)="onMouseEnter()"
    (click)="onMouseEnter()">
    <!-- <mat-icon class="mat-icon-size">account_circle</mat-icon> -->
    <img src="../../../assets/images/icons/user2.png" alt="">
  </button>

  <mat-menu #toolbarUserMenu="matMenu">
    <button mat-menu-item disabled style="height: auto">
      <span class="d-flex alignt-items-center accent-text-color" style="line-height: 2.5em">
        <span class="d-inline-block" style="width: 1em; height: 1em; margin-right: 1em">
          <mat-icon fontSet="fas" fontIcon="fa-user"></mat-icon>
        </span>
        <span class="d-inline-block">{{ (user?.name || user?.displayName || 'Anonymous').split(' ')[0] }}</span>
      </span>
      <span class="d-block" style="font-size: 0.85em; line-height: 1.5em; margin-bottom: 1em">{{
        !!user ? user.email : ''
        }}</span>
    </button>
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/minha-conta">
      <mat-icon fontSet="fas" fontIcon="fa-user-cog"></mat-icon>
      <span>Minha Conta</span>
    </a>
    <button mat-menu-item (click)="logout()">
      <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt"></mat-icon>
      <span>Sair</span>
    </button>
  </mat-menu>
</ng-container>


<ng-container *ngIf="isMobile">
  <button *ngIf="user" mat-icon-button [matMenuTriggerFor]="toolbarUserMenu" class="m-r-5">
    <!-- <mat-icon class="mat-icon-size">account_circle</mat-icon> -->
    <img src="../../../assets/images/icons/user2.png" alt="">
  </button>
  <mat-menu #toolbarUserMenu="matMenu">
    <button mat-menu-item disabled style="height: auto">
      <span class="d-flex alignt-items-center accent-text-color" style="line-height: 2.5em">
        <span class="d-inline-block" style="width: 1em; height: 1em; margin-right: 1em">
          <mat-icon fontSet="fas" fontIcon="fa-user"></mat-icon>
        </span>
        <span class="d-inline-block">{{ (user?.name || user?.displayName || 'Anonymous').split(' ')[0] }}</span>
      </span>
      <span class="d-block" style="font-size: 0.85em; line-height: 1.5em; margin-bottom: 1em">{{
        !!user ? user.email : ''
        }}</span>
    </button>
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/minha-conta">
      <mat-icon fontSet="fas" fontIcon="fa-user-cog"></mat-icon>
      <span>Minha Conta</span>
    </a>
    <button mat-menu-item (click)="logout()">
      <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt"></mat-icon>
      <span>Sair</span>
    </button>
  </mat-menu>
</ng-container>