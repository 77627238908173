import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AutomaticMessages, DocumentParameter, GetInstitutionDocuments, InstitutionDocuments } from 'functions/src/models/model-interface';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { IdentityOppsForIF } from '../../../../functions/src/models/opportunity/IdentityOppsForIF';
import { ArquivoInfo, Document } from '../../../../functions/src/models/UserDocument';
import { BasicTypeCnae, InstituicaoFinanceira, NewOtherInstitution, ScreeningInstitution } from './instituicao-financeira';

@Injectable({
  providedIn: 'root',
})
export class InstituicaoFinanceiraService {
  instituicaoFinanceiraCollection: AngularFirestoreCollection<InstituicaoFinanceira>;
  instituicaoFinanceira: Observable<InstituicaoFinanceira[]>;
  instituicaoFinanceiraDoc: AngularFirestoreDocument<InstituicaoFinanceira>;
  messagesCollection: AngularFirestoreCollection<AutomaticMessages>;

  private readonly ibgeAPI = 'https://servicodados.ibge.gov.br/api/v1/localidades';

  constructor(
    private angularFirestore: AngularFirestore,
    private logger: LogService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.logger.controllerName = this.constructor.name;
    this.instituicaoFinanceiraCollection = this.angularFirestore.collection('instituicoes-financeiras', (x) =>
      x.orderBy('nome', 'asc')
    );
  }

  getInstituicoesFinanceiras(): Observable<InstituicaoFinanceira[]> {
    this.instituicaoFinanceira = this.instituicaoFinanceiraCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as InstituicaoFinanceira;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.instituicaoFinanceira;
  }

  getInstituicoesFinanceirasAtivas(): Observable<InstituicaoFinanceira[]> {
    const instFinancRef = this.angularFirestore.collection(`instituicoes-financeiras`, (x) =>
      x.where('ativo.id', '==', 'sim')
    );
    return instFinancRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as InstituicaoFinanceira;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  addInstituicaoFinanceira(instituicaoFinanceira: InstituicaoFinanceira) {
    this.logger.info('Tipo de instituição financeira alterado com sucesso.', instituicaoFinanceira.id);
    return this.instituicaoFinanceiraCollection.add(instituicaoFinanceira);
  }

  updateInstituicaoFinanceira(instituicaoFinanceira: InstituicaoFinanceira): Promise<void> {
    this.instituicaoFinanceiraDoc = this.angularFirestore.doc(`instituicoes-financeiras/${ instituicaoFinanceira.id }`);
    return this.instituicaoFinanceiraDoc
      .set(instituicaoFinanceira, { merge: true })
      .then(() => {
        this.logger.info('Tipo de instituição financeira alterado com sucesso.', instituicaoFinanceira.id);
      })
      .catch((e) => {
        this.logger.error('Problemas na alteração de tipo de instituição financeira.', e);
      });
  }

  addInstituicaoFinanceiraAutoMsg(instituicaoFinanceira: InstituicaoFinanceira, automaticMessages: AutomaticMessages): Promise<void> {
    const institutionRef = this.angularFirestore.collection(`institutions-automatic-messages`);
    return institutionRef
      .add(automaticMessages)
      .then(() => {
        this.logger.info('Template de mensagem automatica criado com sucesso.', instituicaoFinanceira.id);
      })
      .catch((e) => {
        this.logger.error('Problemas ao criar o template de mensagem automatica.', e);
      });
  }

  updateInstituicaoFinanceiraAutoMsg(
    instituicaoFinanceira: InstituicaoFinanceira,
    automaticMessages: AutomaticMessages
  ): Promise<void> {
    const institutionRef = this.angularFirestore.doc(`institutions-automatic-messages/${ automaticMessages.id }`);
    return institutionRef
      .set(automaticMessages, { merge: true })
      .then(() => {
        this.logger.info('Template de mensagem automatica alterado com sucesso.', instituicaoFinanceira.id);
      })
      .catch((e) => {
        this.logger.error('Problemas na alteração no template de mensagem automatica.', e);
      });
  }

  async deleteInstituicaoFinanceiraAutoMsg(
    instituicaoFinanceira: InstituicaoFinanceira,
    automaticMessages: AutomaticMessages
  ): Promise<void> {
    const institutionRef = this.angularFirestore.doc(`institutions-automatic-messages/${ automaticMessages.id }`);
    await institutionRef
      .delete()
      .then(() => {
        this.logger.info('Template de mensagem automatica alterado com sucesso.', instituicaoFinanceira.id);
      })
      .catch((e) => {
        this.logger.error('Problemas na alteração no template de mensagem automatica.', e);
      });
  }

  getOtherFinancialInstitution(): Observable<InstituicaoFinanceira[]> {
    return this.angularFirestore.collection('outras-instituicoes-financeiras', ref =>
      ref
        .where('nome', '!=', '')
        .orderBy('nome'))
      .get().pipe(map(snap => snap.docs.map(d => d.data() as InstituicaoFinanceira)))
  }

  getFinancialInstitutionActive(): Observable<InstituicaoFinanceira[]> {
    return this.angularFirestore.collection('instituicoes-financeiras', ref =>
      ref
        .where('ativo.id', '==', 'sim')
        .where('nome', '!=', '')
        .orderBy('nome'))
      .get().pipe(map(snap => snap.docs.map(d => d.data() as InstituicaoFinanceira)));
  }

  getFinancialInstitution(): Observable<InstituicaoFinanceira[]> {
    return this.angularFirestore.collection('instituicoes-financeiras', ref =>
      ref
        .where('nome', '!=', '')
        .orderBy('nome'))
      .get().pipe(map(snap => snap.docs.map(d => d.data() as InstituicaoFinanceira)));
  }

  getFinancialInstitutionAutocomplete(): Observable<NewOtherInstitution[]> {
    return this.getOtherFinancialInstitution()
      .pipe(
        map(ifs => ifs
          .map(({ nomeNoSistema, nome: name }) => ({ id: nomeNoSistema, nomeNoSistema, name, type: 'other' })))
      );
  }

  getAutomaticMessagesInstituition(instituicaoNomeSistema: string): Observable<AutomaticMessages[]> {
    return (
      // firebase.firestore()
      this.angularFirestore
        .collection('institutions-automatic-messages', (x) => x.where('instSystemName', '==', instituicaoNomeSistema))
        .snapshotChanges()
        .pipe(
          map((changes) => {
            return changes.map((a) => {
              const data = a.payload.doc.data() as AutomaticMessages;
              data.id = a.payload.doc.id;
              return data;
            });
          })
        )
    );
  }

  getTiposDocumentosAtivos() {
    const tiposDocumentsRef = this.angularFirestore.collection('tipo-documento', (x) =>
      x.where('ativo.id', '==', 'sim').orderBy('nome', 'asc')
    );
    return tiposDocumentsRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as Document;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  getInstFinancDocuments(
    instFinancId
  ): Observable<GetInstitutionDocuments> {
    return new Observable((obs: Observer<GetInstitutionDocuments>) => {
      const innerSubscription = this.angularFirestore
        .doc(`inst-financ-documentos/${ instFinancId }`)
        .valueChanges()
        .subscribe((userDocs: DocumentParameter) => {
          if (obs.closed) {
            innerSubscription.unsubscribe();
          }

          if (!userDocs || !Array.isArray(userDocs.documents)) {
            obs.next({
              documentsData: [],
              documents: [],
            });
          } else {
            const documentsParam = userDocs?.documents;
            const docPromises = documentsParam?.map((docParam) =>
              this.angularFirestore.doc<Document>(`tipo-documento/${ docParam.id }`).get().toPromise()
            );

            Promise.all(docPromises).then((documents) => {
              obs.next({
                documentsData: documents.map((doc) => ({
                  ...doc.data(),
                  instituicaoFinanceiraId: instFinancId,
                  id: doc.id,
                  automatic: documentsParam.some((docParam) => docParam.id === doc.id && docParam.automatic),
                })) as Document[],
                ...userDocs,
              });
            });
          }
        });
    });
  }

  updateFinancialInstitutionDocuments(instFinancId: string, data: Partial<InstitutionDocuments>): Promise<void> {
    const FIDocumentsDoc = this.angularFirestore.doc(`inst-financ-documentos/${ instFinancId }`);
    return FIDocumentsDoc.set({ ...data }, { merge: true });
  }

  updateImagemBanco(arquivoInfo: ArquivoInfo, instFinancId: string) {
    const userDocumentDoc = this.angularFirestore.doc(`instituicoes-financeiras/${ instFinancId }`);
    userDocumentDoc
      .update({ arquivoInfo: arquivoInfo })
      .then((s) => {
        this.logger.info('Imagem do logotipo de intituição financeira salva com sucesso.', instFinancId);
      })
      .catch((e) => {
        this.logger.error('Problemas na alteração de logotipo de instituição financeira. ' + instFinancId, e);
      });
  }

  getStates(): Observable<any[]> {
    return this.http.get<any[]>(`${ this.ibgeAPI }/estados?orderBy=nome`, {
      responseType: 'json',
      headers: this.authService.getHeader(),
    });
  }

  getRegions(): Observable<any[]> {
    return this.http.get<any[]>(`${ this.ibgeAPI }/regioes?orderBy=nome`, {
      responseType: 'json',
      headers: this.authService.getHeader(),
    });
  }
  getUfsRegions(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${ this.ibgeAPI }/regioes/${ id }/estados?orderBy=nome`, {
      responseType: 'json',
      headers: this.authService.getHeader(),
    });
  }

  getCities(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${ this.ibgeAPI }/estados/${ id }/municipios?orderBy=nome`, {
      responseType: 'json',
      headers: this.authService.getHeader(),
    });
  }

  getCnaes(type: BasicTypeCnae, id: string): Observable<any[]> {
    const cnaeAPI = 'https://servicodados.ibge.gov.br/api/v2/cnae';
    if (id && id.length > 0) {
      return this.http.get<any[]>(`${ cnaeAPI }/${ type.id }`, {
        responseType: 'json',
        headers: this.authService.getHeader(),
      });
    }
  }

  identifyOppsForIfs(params: IdentityOppsForIF): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/opportunity/identify-opps-for-ifs`,
          { params },
          {
            responseType: 'json',
            headers: this.authService.getHeader(),
          }
        )
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // verifyFinancialInstitutionAPIService(nomeNoSistema: string) {
  //   return this.http.post(
  //     'http://localhost:5001/ce-dev-8dcce/us-central1/credit/verify-financial-institution-api',
  //     { nomeNoSistema: nomeNoSistema },
  //     {
  //       responseType: 'json',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     }
  //   )
  // }

  // switchFinancialInstitutionAutomaticAPIService(nomeNoSistema: string) {
  //   return this.http.patch(
  //     'http://localhost:5001/ce-dev-8dcce/us-central1/credit/switch-financial-institution-api-automatic',
  //     { nomeNoSistema: nomeNoSistema },
  //     {
  //       responseType: 'json',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     }
  //   )
  // }

  async switchFinancialInstitutionAutomaticAPIService(institutionKey: string) {
    const database = this.angularFirestore.firestore
    const batch = database.batch()
    const institutionReferenceQuery = await database.collection('settings/integrations/automatic-integrations').where("institutionKey", '==', institutionKey).get()

    institutionReferenceQuery.forEach((document) => {
      batch.update(document.ref, { active: !document.data().active })
    })

    batch.commit()
  }

  async verifyFinancialInstitutionAPIService(institutionKey: string) {

    const database = this.angularFirestore.firestore
    const institutionReferenceQuery = await database.collection('settings/integrations/automatic-integrations').where("institutionKey", '==', institutionKey).get()

    if (institutionReferenceQuery.empty) {
      return
    }

    for (const document of institutionReferenceQuery.docs) {
      return document.data()
    }

  }

  async getApiActiveConfig(nomeNoSistema: string): Promise<boolean | null> {
    const docSnapshot = await this.angularFirestore.doc('configuracao/automatic-integrations').get().toPromise();
    if (docSnapshot.exists) {
      if (docSnapshot.data()[nomeNoSistema] !== undefined) {
        return docSnapshot.data()[nomeNoSistema];
      }
    }
    return null;
  }

  setApiActive(nomeNoSistema: string, isActive: boolean): Promise<void> {
    const updateData = JSON.parse(`{"${ nomeNoSistema }": ${ isActive }}`);
    return this.angularFirestore.doc('configuracao/automatic-integrations').update(updateData);
  }

  getScreeningInstitutionSelected(institution: string): Observable<ScreeningInstitution[]>{
    const ref = this.angularFirestore.collection(`customer-screening`, (x) => 
      x.where('nomeNoSistema', '==', institution)
    );
    return ref.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as ScreeningInstitution;
          return data;
        })
      })
    )
  }

  getScreeningInstitutions(): Observable<ScreeningInstitution[]>{
    const ref = this.angularFirestore.collection('customer-screening');

    return ref.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as ScreeningInstitution;
          return data;
        })
      })
    )
  }

}
