import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Customer } from '../../../../../functions/src/models/Customer';
import { CarouselCardsEntity } from '../../../../../functions/src/models/general/carousel-cards.entity';

@Component({
  selector: 'app-carrossel',
  templateUrl: './carrossel.component.html',
  styleUrls: ['./carrossel.component.scss']
})
export class CarrosselComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() styleType: string;
  @Input() type: string;
  @Input() customer: Customer;

  items: CarouselCardsEntity[] = [];
  currentIndex = 0;
  itemsPerPage = 2.2;
  isDesktop = true;
  text: string;
  showText = true; // Variável para controlar a exibição do texto

  @ViewChild('carouselWrapper', { static: false }) carouselWrapper: ElementRef;

  private touchStartX = 0;  // Variável para armazenar a posição inicial do toque
  private touchEndX = 0;    // Variável para armazenar a posição final do toque
  isActive: boolean[];


  constructor(private firestore: AngularFirestore) { }

  ngOnInit(): void {
    if (this.type === null || this.type === undefined) {
      this.type = 'faq';
    }


    if (this.type === 'service') {
      this.text = 'Benefícios exclusivos';
    }
    if (this.type === 'faq') {
      this.text = 'Agregador de dados';
    }
    this.updateItemsPerPage();
    this.getItems();
    this.checkIsDesktop();
    window.addEventListener('resize', this.updateItemsPerPage.bind(this));
    window.addEventListener('resize', this.checkIsDesktop.bind(this));

    console.log(this.customer);
  }

  ngAfterViewInit(): void {
    this.updateItemsPerPage();
    this.addTouchListeners();  // Adiciona os eventos de toque quando a visualização é iniciada
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.updateItemsPerPage.bind(this));
    window.removeEventListener('resize', this.checkIsDesktop.bind(this));
    this.removeTouchListeners();  // Remove os eventos de toque ao destruir o componente
  }

  getItems(): void {
    if (this.type) {
      this.firestore.collection('carousel-cards', ref => ref.where('type', '==', this.type))
        .valueChanges()
        .subscribe((res: CarouselCardsEntity[]) => {
          this.items = res;
          this.isLabelActive(res)
          this.checkIfShowText();  // Verifica se o texto deve ser exibido após carregar os itens
        });
    } else {
      this.firestore.collection('carousel-cards')
        .valueChanges()
        .subscribe((res: CarouselCardsEntity[]) => {
          this.items = res;
          this.checkIfShowText();  // Verifica se o texto deve ser exibido após carregar os itens
        });
    }
  }
  

  checkIfShowText(): void {
    // Verifica se existe pelo menos um card que atenda às condições de exibição
    this.showText = this.items.some(item => this.customer.revenue < item.revenueMax && this.customer.revenue > item.revenueMin);
  }

  openExternalLink(url: string): void {
    window.open(url, '_blank', 'noopener noreferrer');
  }


  updateItemsPerPage(): void {
    if (this.carouselWrapper) {
      const width = this.carouselWrapper.nativeElement.offsetWidth;
      if (width <= 480) {
        this.itemsPerPage = 1;
      } else if (width <= 768) {
        this.itemsPerPage = 1.5;
      } else {
        this.itemsPerPage = 2.2;
      }
      this.currentIndex = Math.min(this.currentIndex, this.maxIndex());
    }
  }

  getTransform(): string {
    // Mover a quantidade de items com base na largura de um card
    return `translateX(-${ this.currentIndex * (115 / this.items.length) }%)`;
  }

  next(): void {
    // Se ainda houver itens para avançar, avançar 1 card
    if (this.currentIndex < this.items.length - 1) {
      this.currentIndex++;
    }
  }

  prev(): void {
    // Se ainda houver itens para retroceder, voltar 1 card
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }


  maxIndex(): number {
    return Math.max(0, Math.ceil(this.items.length - this.itemsPerPage));
  }

  // Verifica se está no desktop
  checkIsDesktop(): void {
    const width = window.innerWidth;
    this.isDesktop = width > 768;
  }

  // Método para exibir ou ocultar as setas de navegação
  showNavButtons(): boolean {

    if (this.isDesktop === false) {
      return false
    }
    return this.items.length > 2;
  }

  // Adiciona eventos de toque para permitir deslizar no mobile
  addTouchListeners(): void {
    if (this.carouselWrapper) {
      this.carouselWrapper.nativeElement.addEventListener('touchstart', this.onTouchStart.bind(this));
      this.carouselWrapper.nativeElement.addEventListener('touchmove', this.onTouchMove.bind(this));
      this.carouselWrapper.nativeElement.addEventListener('touchend', this.onTouchEnd.bind(this));
    }
  }

  removeTouchListeners(): void {
    if (this.carouselWrapper) {
      this.carouselWrapper.nativeElement.removeEventListener('touchstart', this.onTouchStart.bind(this));
      this.carouselWrapper.nativeElement.removeEventListener('touchmove', this.onTouchMove.bind(this));
      this.carouselWrapper.nativeElement.removeEventListener('touchend', this.onTouchEnd.bind(this));
    }
  }

  onTouchStart(event: TouchEvent): void {
    this.touchStartX = event.changedTouches[0].screenX; // Registra a posição inicial do toque
  }

  onTouchMove(event: TouchEvent): void {
    this.touchEndX = event.changedTouches[0].screenX; // Atualiza a posição conforme o toque se move
  }

  onTouchEnd(): void {
    const touchDiff = this.touchStartX - this.touchEndX; // Calcula a diferença entre o início e o fim do toque
    if (touchDiff > 50) {
      this.next(); // Desliza para o próximo item
    } else if (touchDiff < -50) {
      this.prev(); // Desliza para o item anterior
    }
  }

  fetchDataAndProcess(): void {
 
    this.getItems();  
  }
  
  
  isLabelActive(data: CarouselCardsEntity[]): boolean {
    if (!data || data.length === 0) {
      return false;
    }
  
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const lastAccessDate = this.customer.lastInteraction ? this.customer.lastInteraction.toDate() : null;
  
    if (!lastAccessDate) {
      return false;
    }
  
    // Substitui itens sem 'carouseldate' por uma data gerada do dia atual
    const launchDates = this.items.map(item => {
      if (item.carouseldate && item.carouseldate.toDate) {
        return item.carouseldate.toDate();
      } else {
        const generatedDate = new Date(); // Data do dia
        console.warn(`Item sem 'carouseldate' detectado. Gerando data do dia: ${generatedDate.toDateString()}`);
        return generatedDate;
      }
    });
  
    this.isActive = launchDates.map(launchDate => {
      if (lastAccessDate.getTime() > launchDate.getTime()) {
        const timeDifference = lastAccessDate.getTime() - launchDate.getTime();
        if (timeDifference <= oneWeekInMilliseconds) {
          console.log(
            `O último acesso (${lastAccessDate.toDateString()}) ocorreu dentro de uma semana após o lançamento (${launchDate.toDateString()}).`
          );
          return true;
        }
        console.log(
          `O último acesso (${lastAccessDate.toDateString()}) foi há mais de uma semana após o lançamento (${launchDate.toDateString()}).`
        );
        return false;
      }
  
      console.log(
        `A data de último acesso (${lastAccessDate.toDateString()}) é menor que a data de lançamento (${launchDate.toDateString()}).`
      );
      return false;
    });
  
    return this.isActive.some(active => active);
  }
  
  


}
