<div class="recommend-item-custom">
  <div class="item-custom">
      <img src="../../../assets/images/icons/612803 1.png" alt="" class="img-left" />
      <div class="text">
          <p *ngIf="!hasCupom" style="padding: 4px 10px;" class="txt-black">Possui um cupom?</p>
          <p *ngIf="hasCupom" style="padding: 4px 10px;" class="txt-black">Cupom ativo:</p>

          <div *ngIf="hasCupom && !isEditingCupom">
              <div class="div-cod">
                  <p class="mb-2 codigo-cupom"><span style="font-weight: 500;"></span> {{ customer.cupomCode }}</p>
                  <p class="mb-2 validade"><span style="font-weight: 500;">Validade</span> {{ customer.dataFim?.toLocaleString().substring(0, 10) }}</p>
              </div>

              <!-- <div class="my-3">
   <button mat-raised-button class="button"
     (click)="onChangeCupomAuthorization({ checked: true }); isEditingCupom = true">
     Alterar dados
   </button>
 </div> -->
          </div>
      </div>
  </div>

  <div *ngIf="!hasCupom || isEditingCupom">
      <!-- <div class="checkBoxDesktop">
  <mat-checkbox color="primary" formControlName="allowCupom" [checked]="checked"
    (click)="onChangeCupomAuthorization()">
    Eu tenho um cupom
  </mat-checkbox>
</div> -->
      <form [formGroup]="cupomFormGroup" (ngSubmit)="saveCupom()">
          <div class="row">
              <div class="col-12 mb-3">
                  <!-- <div class="checkBoxMobile">
      <mat-checkbox color="primary" formControlName="allowCupom" [checked]="checked"
          (click)="onChangeCupomAuthorization()">
          Eu tenho um cupom
      </mat-checkbox>
      </div> -->
              </div>
              <div class="input-group d-flex align-items-center justify-content-between">
                  <div class="input-cupom me-2">
                      <input placeholder="Adicione aqui" formControlName="cupomCode"  maxlength="10" required />
                      <div class="cupom-error" *ngIf="cupomFormGroup.get('cupomCode').hasError('required') && cupomFormGroup.get('cupomCode').touched">
                          Código do cupom invalido.
                      </div>
                  </div>

                  <div class="d-flex align-items-center">
                      <!-- <button class="my-3 mr-1" mat-stroked-button (click)="cancelEditing()" *ngIf="!isSaving && hasCupom"><mat-icon class="mr-2">cancel</mat-icon> Cancelar</button> -->
                      <button class="cupom-btn" color="primary" [disabled]=" cupomFormGroup.invalid" (click)="saveCupom()" *ngIf="!isSaving">
                          USAR
                      </button>
                      <mat-spinner *ngIf="isSaving" [diameter]="30" color="primary" style="margin: 0 auto;"> </mat-spinner>
                  </div>
              </div>
          </div>
      </form>
  </div>
</div>

