
import { BasicStructure } from '../common/BasicStructure';

export enum DocumentAction {
  'UPLOAD' = 'upload',
  'SIGN' = 'sign',
}

// export const DocumentActionLabel = {
//   [DocumentAction.SIGN]: 'Assinar (por Clicksign)',
//   [DocumentAction.UPLOAD]: 'Upload',
// };

export interface UserDocuments {
  documentos: Document[];
  uid: string;
}

export interface DocControleVencimento {
  id?: string;
  uid?: string;
  email?: string;
  typeDocument: string; // tipoDocumento
  qtyDays: number; // qtdDias
  dateRelease: Date; // type === firebase.firestore.Timestamp; //dataEmissao
  expirationDate: Date; // type === firebase.firestore.Timestamp; //dataVencimento
  isExpired: boolean; // estaVencido
  dateCreation: Date; // type === firebase.firestore.Timestamp; //dataCriacao
}

export interface DocumentoPendente {
  email: string;
  nomeUsuario: string;
  documentos: any[];
}

export interface Document {
  active?: boolean;
  automatic?: boolean;
  typeId: string;
  name: string; // nome
  mnemonic: string; // mnemonico
  description: string; // descricao
  guarantees?: BasicStructure[];
  typeOfAnticipation?: BasicStructure[];
  visibleToBanker: BasicStructure;
  instructions: string; // instrucoes
  // activeStr: BasicStructure; //ativo
  // automaticStr: BasicStructure; //automatico
  expirationDate: BasicStructure; // validade
  financialYear: BasicStructure; // anoExercicio
  situation?: string; // situacao
  noChange?: boolean;
  qtyDays?: number; // qtdDias
  qtyExpectedFiles: number; // qtdArquivosEsperados
  qtySubmittedFiles: number; // qtdArquviosSubmetidos
  financialYears: [string]; // anosExercicios
  documentId: string;
  fileInfo?: ArquivoInfo[]; // arquivosInfo
  fileInfOld?: ArquivoInfo[];
  uid?: string;
  approvalOrDisapprovalDate?: Date; // type === firebase.firestore.Timestamp; //dataAprovacao
  disapprovalReason?: string; // motivoReprovacao
  expirationControlId?: string; // controleVencimentoId
  ordenation?: number; // ordenacao
  qtyFinancialYear: number; // anoExercicioQtd
  pendingDocumentId?: string; // documentoPendenteId
  pendingIssueForUser?: boolean; // pendenciaParaUsuario
  pendingIssueForAdmin?: boolean; // pendenciaParaAdm
  // documentTypeId?: string; //tipoDocumentoId
  financialInstitutionId?: string; // instituicaoFinanceiraId
  maximumDocuments: number;
  minimumDocuments: number;
  multipleDocuments: any ; // mongoose.Schema.Types.Mixed; // type === any;

  customerConfirmation?: boolean;
  hasNoAlterationRec?: boolean;
  userAproReprov?: string; // usuarioAproReprov
  documentAction: string;
  // documentAction: {
  //   type: String;
  //   enum: ['UPLOAD', 'SIGN'];
  // };
  clicksignDocumentKey?: string;
  clicksignSignatureRequestKey?: string;
  email: string; // customer.email
  companyName: string; // customer.companyName
  cnpj: string; // customer.cnpj
  customerCreatedAt?: Date;
  createdAt?: Date;
  incomplete?: boolean;
  anoExercicioCorrente?: boolean;
  partnerName?: string;
  partnerCpf?: string;
  requiredDocument: boolean;
}

export interface ArquivoInfo {
  path?: string;
  dateRelease?: Date; // type === firebase.firestore.Timestamp; //dataEmissao
  financialYear?: string; // anoExercicio
  userUploader?: string; // usuarioUploader
  dateUpload?: Date; // type === firebase.firestore.Timestamp; //dataUpload
  fileName?: string;
}
