import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recommended-customer',
  templateUrl: './recommended-customer.component.html',
  styleUrls: ['./recommended-customer.component.scss']
})
export class RecommendedCustomerComponent implements OnInit {
  hasCupom: boolean;
  
  constructor() {}

  ngOnInit(): void {}

}
