import packageJson from '../../package.json';

export const environment = {
  appName: 'Capital Empreendedor',
  envName: 'DEV:REMOTE',
  production: false,
  test: false,
  baseURL: 'https://dev.capitalempreendedor.com.br',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    material: packageJson.dependencies['@angular/material/*'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
  },
  functionsUrl: 'https://us-central1-ce-dev-8dcce.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyAc5ZL2FnFlMilUHl6v3sMv0vQ_EFQU3V0',
    authDomain: 'ce-dev-8dcce.firebaseapp.com',
    databaseURL: 'https://ce-dev-8dcce.firebaseio.com',
    projectId: 'ce-dev-8dcce',
    storageBucket: 'ce-dev-8dcce.appspot.com',
    messagingSenderId: '100053234114',
    appId: '1:100053234114:web:6aef0372d8d5b669',
  },
  clicksign: {
    key: '336192852d6552162a110fd2c8ee6b1c',
  },
  keyPass: 'capitalempreendedordesenvolvimento',
  providerIp: 'https://api.ipify.org?format=json',
  defaultPwd: 'rfd983JJK@',
  serviceAccountId: '3276edfaee6aa7048ef43460faf4541c351a1e9f',
  urlCustomToken: 'autenticationsystemapi',
  keyCustomToken: 'PYwRqIPglD3cQA4Zbt63SBHrq8lW72eMPQe7audCyWeFWZexJaqccyJJwwfsnA64',
  url: 'https://staging.capitalempreendedor.com.br',
  acessKey: 'cEHwn0xb87RgGej1DFZJ0PJpKlxBwcWJ'
};
