<div class="main-container">
  <div class="toolbar">
    <mat-toolbar style="background-color: #0A452B;">
      <button class="toggler" mat-icon-button (click)="this.toggle()" *ngIf="shouldSidenavBeVisible()">
        <mat-icon>menu</mat-icon>
      </button>
      <a routerLink="/" class="branding d-inline d-md-none">
        <img [src]="logo" />
      </a>
      <a routerLink="/" class="branding d-none d-md-inline" style="color: #fff">
        <img [src]="logoFull" />
      </a>
      <mat-chip-list aria-label="Environment" class="d-inline-block spacer" *ngIf="!isProd">
        <mat-chip class="ml-4">
          <span>{{ envLabel }}</span>
        </mat-chip>
      </mat-chip-list>
      <div class="d-inline-block spacer" *ngIf="isProd"></div>
      <app-header></app-header>
    </mat-toolbar>
  </div>
  <div class="wrapper">
    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
      <mat-sidenav
        class="snav"
        #snav
        *ngIf="shouldSidenavBeVisible()"
        [opened]="toggled"
        [disableClose]="true"
        style="min-width: 80px; background-color: #fafafa"
        [mode]="mobileQuery.matches ? 'over' : 'push'"
      >
        <mat-nav-list [class.expanded-sidenav]="toggled && expanded">
          <a class="menu-item" [ngClass]="selectedItem === 'Home' ? 'menu-item selected' : 'menu-item'" mat-list-item routerLink="/home" (click)="selected('pagina-inicial')">
            <i class="icon home">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 22V12H15V22" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </i>
            <span class="menu-label" *ngIf="expanded">Página inicial</span>
          </a>
          <a class="menu-item" [ngClass]="selectedItem === 'Documentação' ? 'menu-item selected' : 'menu-item'" mat-list-item routerLink="/documentacao" (click)="selected('documentacao')" *ngIf="!isCustomerNoTouch && !isBabyCustomer">
            <i class="icon file">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14 2V8H20" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 13H8" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 17H8" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 9H9H8" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>                
            </i>
            <span class="menu-label" *ngIf="expanded">Meus documentos</span>
          </a>
          <a class="menu-item" [ngClass]="selectedItem === 'Crédito' ? 'menu-item selected' : 'menu-item'" mat-list-item routerLink="/credito" (click)="selected('linha-de-credito')" *ngIf="hasOpportunities">
            <i class="icon dollar">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 1V23" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17 5H9.5C8.57174 5 7.6815 5.36875 7.02513 6.02513C6.36875 6.6815 6 7.57174 6 8.5C6 9.42826 6.36875 10.3185 7.02513 10.9749C7.6815 11.6313 8.57174 12 9.5 12H14.5C15.4283 12 16.3185 12.3687 16.9749 13.0251C17.6313 13.6815 18 14.5717 18 15.5C18 16.4283 17.6313 17.3185 16.9749 17.9749C16.3185 18.6313 15.4283 19 14.5 19H6" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </i>
            <span class="menu-label" *ngIf="expanded">Ofertas de crédito</span>
          </a>
          <a class="menu-item" [ngClass]="selectedItem === 'Mensagens' ? 'menu-item selected' : 'menu-item'" mat-list-item routerLink="/mensagens" (click)="selected('mensagens')">
            <i class="circle icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>                
            </i>
            <span class="menu-label" *ngIf="expanded">Chat</span>
            <span class="notification-today" *ngIf="unreadMessagesCount > 0">
              {{unreadMessagesCount}}
            </span>
          </a>
          <a class="menu-item" style="background-color: #008348; border-radius: 8px;" mat-list-item  *ngIf="hasOppsByRecurrence" (click)="openDialog()" >
            <mat-icon style="color: #fff">attach_money</mat-icon>
            <span class="menu-label" style="color: #fff" *ngIf="expanded">Peça crédito novamente!</span>
          </a>

        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content
        class="sidenav-content"
        [class.expanded-sidenav-content]="shouldSidenavBeVisible() && toggled && expanded"
        [class.toggled-only-sidenav-content]="shouldSidenavBeVisible() && toggled && !expanded"
        (click)="toggled = mobileQuery.matches && toggled ? false : toggled"
      >
        <div class="content">
          <router-outlet #o="outlet"></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
    <div class="footer">
      <div class="row">
        <div class="col-12">
          <div class="flex" style="display: flex; flex-direction: row; justify-content: space-between;">
            <ng-container ></ng-container>
            
            <div class="col signature">
              <strong>v.{{ version }}</strong> - &#169; <span class="year">{{ year }}</span> - Capital Empreendedor -
              CNPJ: 21.261.858/0001-80
            </div>

            <ng-container > <img width="160px" src="../../../../assets/images/Parceiro BNDES.svg" alt=""> </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
