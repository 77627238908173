import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { User } from 'functions/src/models/User';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { fromEvent, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};

  userSubscription: Subscription;
  user: User;
  lastY: number;
  isMobile: boolean;
  mediaQueryList: MediaQueryList;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  private isMouseOverButton: boolean = false;
  private isMouseOverMenu: boolean = false;
  private closeMenuTimeout: any;

  constructor(
    public authService: AuthService,
    private router: Router,
    private mediaMatcher: MediaMatcher // Injetar o MediaMatcher
  ) {
    this.subscribeToIsAuthenticated();
    this.mediaQueryList = this.mediaMatcher.matchMedia('(max-width: 600px)');
    this.isMobile = this.mediaQueryList.matches;

    // Inscreva-se nas mudanças de mídia
    this.mediaQueryList.addEventListener('change', this.handleMediaQueryChange);

    const mouseLeave$ = fromEvent(document, 'mouseout');
    mouseLeave$.subscribe((event: MouseEvent) => {
      if (event.clientY > 195 || event.clientY < 0) {
        this.closeMenu();
      }
      this.lastY = event.clientY;
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    
    this.mediaQueryList.removeEventListener('change', this.handleMediaQueryChange);
  }

  private subscribeToIsAuthenticated(): void {
    this.userSubscription = this.authService.user.subscribe((user) => {
      if (user && !user.isAnonymous) {
        this.user = user;
        console.log('user', user);
      } else {
        this.user = null;
      }
    });
  }

  handleMediaQueryChange = (event: MediaQueryListEvent) => {
    this.isMobile = event.matches;
  }

  login(): void {
    this.router.navigate(['/entrar']);
  }

  logout(): void {
    this.authService.logout().then(() => {
      this.router.navigate(['/entrar']);
    });
  }

  onMouseEnter(): void {
    this.isMouseOverButton = true;
    this.isMouseOverMenu = true;
    this.cancelClosingTimer();
    this.openMenu();
  }

  onMouseLeave(): void {
    this.isMouseOverButton = false;
    this.isMouseOverMenu = false;
    this.startClosingTimer();
  }

  openMenu(): void {
    this.trigger.openMenu();
  }

  startClosingTimer(): void {
    this.closeMenuTimeout = setTimeout(() => {
      if (!this.isMouseOverButton && !this.isMouseOverMenu) {
        this.closeMenu();
      }
    }, 500);
  }

  cancelClosingTimer(): void {
    if (this.closeMenuTimeout) {
      clearTimeout(this.closeMenuTimeout);
    }
  }

  closeMenu(): void {
    this.trigger.closeMenu();
  }
}
