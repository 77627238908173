<div class="home-container" *ngIf="!isBabyCustomer">
  <div class="inner-container">
    <div class="main-content">
      <div class="home-content">
        <div>
          <h1 style="flex: 1" class="mb-4">Olá, {{ customer?.name || ''  }}</h1>

        </div>
          <mat-accordion class="example-headers-align" *ngIf="showMissingSignatureCard">
            <mat-expansion-panel class="carta-scr">
              <mat-expansion-panel-header>
                <mat-panel-title>Assinatura pendente</mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content">
                <p><span>Assine a autorização do Banco Central.</span><br /> Para dar andamento em seu pedido de crédito, os bancos precisam de algumas informações relacionadas ao Banco Central.
                  A carta SCR é um instrumento de registro gerido pelo Banco Central e alimentado mensalmente pelas instituições financeiras,
                  que auxilia na análise de crédito em andamento.</p>
                <button [disabled]="isClicksignWidgetOpen" mat-raised-button style="width: fit-content; align-self: start" (click)="openClicksignWidget(signatureCardObject.typeId, signatureCardObject.description)">
                  Assinar
                </button>
                <mat-spinner [diameter]="18" color="accent" class="d-inline-block mx-3"
                  *ngIf="isClicksignWidgetOpen">
                </mat-spinner>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          
        <div class="opt-in" *ngIf="!customer?.agreedMakeDataAvailable && customer?.creditCard?.length > 0">
          <mat-card-title><span style="color: #F93232">⚠</span> Compartilhamento de dados</mat-card-title>
          <mat-card-content>
            <p>Para dar andamento em algumas ofertas, é necessário confirmar
            a disponibilização a sua agenda de recebíveis de Cartão</p>
            <div class="col-12 col-lg-9 mt-3 mb-4 d-flex">
              <mat-checkbox color="primary" class="mr-3 my-1 checkbox" [(ngModel)]="agreedMakeDataAvailable"></mat-checkbox>
              <span class="span-text">Eu concordo.<span class="link" (click)="openDialogDataAvailable()"> Saiba mais</span>.</span>
            </div>
            <button class="green-button" [disabled]="!agreedMakeDataAvailable" (click)="saveAgreedMakeDataAvailable()">Assinar</button>
          </mat-card-content>
        </div>

        <div class="opt-in-accept" *ngIf="okOption">
          <mat-card-title><span style="color: #00C46C">✔</span> Aviso</mat-card-title>
          <mat-card-content>
            <p>Assinado com sucesso! <br>
              Obs.: Recarregue a página para ter acesso às informações atualizadas sobre as ofertas disponíveis.</p>
          </mat-card-content>
        </div>

          <div class="ofertas-container">
            <h3>Oferta de crédito disponível!</h3>
            <p>Parabéns! Acesse agora para aproveitar as melhores taxas e prazos para impulsionar sua empresa.</p>
            <button class="button-oferta" routerLink="/credito">
              Ver ofertas
              <img class="img-arrow" src="../../../assets/images/icons/Arrow Right.png" alt="">
            </button>
          </div>
        <div class="doubts-wrapper" *ngIf="hasOppsByRecurrence">
          <div>
            <br>
            <button class="buttonCredit">
              <a (click)="openDialog()">Quero novo crédito</a>
            </button>
          </div>
        </div>
        <div class="docs-pending-card" *ngIf="pendingDocumentsLength > 0">
          <h3>Você tem {{pendingDocumentsLength}} documentos pendentes</h3>
          <p>Por favor, nos envie estes documentos (disponíveis com o seu contador) para dar seguimento a sua operação de crédito!</p>
          <button routerLink="/documentacao">Enviar documentos</button>
        </div>
      </div>
      <div class="text-center" *ngIf="enableLoading">
        <mat-spinner diameter="60" class="mx-auto"></mat-spinner>
      </div>
      <div class="add-guarantees-card">
        <h3>Receba mais ofertas!</h3>
        <p>Adicione uma garantia real para receber mais ofertas e melhores condições de crédito!</p>
        <h4>Adicionar um:</h4>
        <div class="guarantees-images-row" *ngIf="!isLoadingMessage">
          <button (click)="onClickAddGuarantee('veículo')"><img src="../../../../assets/images/home/garantia-veiculo.png" alt="garantia-veiculo-picture"></button>
          <button (click)="onClickAddGuarantee('imóvel')"><img src="../../../../assets/images/home/garantia-imovel.png" alt="garantia-imovel-picture"></button>
          <button (click)="onClickAddGuarantee('veículo e imóvel')"><img src="../../../../assets/images/home/garantia-vei-imo.png" alt="garantia-veiculo-imovel-picture"></button>
          <button (click)="onClickAddGuarantee('recebíveis')"><img src="../../../../assets/images/home/garantia-recebiveis.png" alt="garantia-recebiveis-picture"></button>
        </div>
        <div *ngIf="isLoadingMessage" style="margin-top: 12px;">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
    <div class="extra-cards-container">
      <div class="recommended-container">
        <app-recommended-customer></app-recommended-customer>
      </div>
      <div class="exclusive-benefits-container">
        <app-carrossel  [type]="'service'" [customer]="this.customer"></app-carrossel>
      </div>
      <div class="blog-container">
        <h4>Educação Financeira</h4>
        <div class="blog-inner-container">
          <app-blog-card></app-blog-card>
          <a href="https://www.capitalempreendedor.com.br/blog" target="_blank">Mais artigos</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="home-container baby-layout" *ngIf="isBabyCustomer">
  <div class="inner-container">
    <div class="main-content">
      <h1>Olá, {{ customer?.name || '' }}</h1>
      <p>Boas vindas à <b>Capital Empreendedor!</b> Estamos empenhados em encontrar todas as oportunidades de crédito disponíveis para você!</p>
      <div class="opt-in" *ngIf="!customer?.agreedMakeDataAvailable && customer?.creditCard?.length > 0">
        <mat-card-title><span style="color: #F93232">⚠</span> Aviso</mat-card-title>
        <mat-card-content>
          <p>Para dar andamento em algumas ofertas, é necessário confirmar
          a disponibilização dos seus dados para a Capital Empreendedor.</p>
          <div class="col-12 col-lg-9 mt-3 mb-4 d-flex">
            <mat-checkbox color="primary" class="mr-3 my-1 checkbox" [(ngModel)]="agreedMakeDataAvailable"></mat-checkbox>
            <span class="span-text">Eu concordo em disponibilizar meus dados para a Capital Empreendedor.<span class="link" (click)="openDialogDataAvailable()"> Saiba mais</span>.</span>
          </div>
          <button class="green-button" [disabled]="!agreedMakeDataAvailable" (click)="saveAgreedMakeDataAvailable()">Assinar</button>
        </mat-card-content>
      </div>
      <div class="opt-in-accept" *ngIf="okOption">
        <mat-card-title><span style="color: #00C46C">✔</span> Aviso</mat-card-title>
        <mat-card-content>
          <p>Assinado com sucesso! <br>
            Obs.: Recarregue a página para ter acesso às informações atualizadas sobre as ofertas disponíveis.</p>
        </mat-card-content>
      </div>
      <div class="add-guarantees-container">
        <h3>Comece a receber ofertas!</h3>
        <p>Sua empresa possui menos de 2 anos, então para conseguir chance de crédito, é necessário que adicione um imóvel e/ou veículo como garantia!</p>
        <h5>Adicionar um:</h5>
        <div class="guarantees-images-row">
          <button (click)="onClickAddGuarantee('veículo')"><img src="../../../../assets/images/home/garantia-veiculo.png" alt="garantia-veiculo-picture"></button>
          <button (click)="onClickAddGuarantee('imóvel')"><img src="../../../../assets/images/home/garantia-imovel.png" alt="garantia-imovel-picture"></button>
          <button (click)="onClickAddGuarantee('veículo e imóvel')"><img src="../../../../assets/images/home/garantia-vei-imo.png" alt="garantia-veiculo-imovel-picture"></button>
        </div>
      </div>
      <h5>Precisa de ajuda?</h5>
      <div class="help-buttons-wrapper">
        <button><a routerLink="/mensagens">Fale conosco</a></button>
        <button><a href="https://www.capitalempreendedor.com.br/faq" target="_blank">Perguntas frequentes</a></button>
      </div>
    </div>
    <div class="extra-cards-container">
      <div class="exclusive-benefits-container">
        <app-carrossel styleType="column-reverse" [type]="'service'" [customer]="this.customer"></app-carrossel>
      </div>
      <p>Quer saber como <b>melhorar seu score de crédito e a situação financeira do seu negócio?</b> Acesse nosso blog para dicas!</p>
      <button class="orange-button"><a href="https://www.capitalempreendedor.com.br/blog" target="_blank">Acesse o blog</a></button>
    </div>
  </div>
</div>











