import firebase from 'firebase';
import { BasicStructure } from '../common/BasicStructure';
import { AddressEntity } from '../general/address.entity';
import { CustomerJourneyStep } from '../general/enums/customer-journey-control';

export class UtmSourceEntity {
  medium: string;
  campaign: string;
  id: string;
  term: string;
  content: string;
  source: string;
  constructor() {
    this.medium = '';
    this.campaign = '';
    this.id = '';
    this.term = '';
    this.content = '';
    this.source = '';
  }
}

export class CustomerJourneyAboutCompanyEntity {
  uid: string | null;
  id: string;
  cnpj: string;
  razaoSocial: string;
  email: string;
  whatsapp: string;
  faturamentoAnual: number;
  address: AddressEntity;
  step: number;
  gclid: string;
  utmSource: UtmSourceEntity;
  stepText: CustomerJourneyStep;
  createdAt: Date | firebase.firestore.Timestamp | null;
  dateAboutCompany: Date | firebase.firestore.Timestamp | null;
  cnae?: string;
  ip?: string;
  cpf?: string;
  debt?: number;
  taxRegime?: BasicStructure;
  banks?: BasicStructure[];
  guarantees?: BasicStructure[];
  guaranteesValue?: number;
  creditValue?: number;
  purpose?: BasicStructure;
  agent?: string;

  constructor() {
    this.uid = null;
    this.id = '';
    this.cnpj = '';
    this.razaoSocial = '';
    this.email = '';
    this.gclid = '';
    this.cnae = '';
    this.whatsapp = '';
    this.faturamentoAnual = 0;
    this.address = new AddressEntity();
    this.step = 1;
    this.stepText = CustomerJourneyStep.AboutCompany;
    this.createdAt = null;
    this.dateAboutCompany = null;
    this.agent = null;
    this.utmSource = new UtmSourceEntity();
  }
}
