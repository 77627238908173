import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Customer } from 'functions/src/models/Customer';
import { Document as DocumentMongoDB } from 'functions/src/models/documents/UserDocument';
import { Subscription } from 'rxjs';
import { AlertDialogComponent } from '../../../components/alert-dialog/alert-dialog.component';
import { LogService } from '../../../components/logger/log.service';
import { AuthService } from '../../../core/auth/auth.service';
import { CustomerService } from '../../services/customer.service';
import { UserDocumentService } from '../../services/user-document.service';



@Component({
  selector: 'app-user-cupom',
  templateUrl: './user-cupom.component.html',
  styleUrls: ['./user-cupom.component.scss']
})
export class UserCupomComponent implements OnInit, OnDestroy {

  userSubscription: Subscription;
  customer: Customer;
  customerPendingDocuments: DocumentMongoDB[];

  hasCupom = false;
  isEditingCupom = false;
  cupomFormGroup: FormGroup;

  enableLoading = true;
  isSaving = false;

  constructor(
    public authService: AuthService,
    private customerService: CustomerService,
    private logger: LogService,
    private dialog: MatDialog,
    private userDocumentService: UserDocumentService,
  ) {
    this.logger.controllerName = this.constructor.name;
  }

  async ngOnInit(): Promise<void> {
    this.cupomFormGroup = new FormGroup({
      // allowCupom: new FormControl('', []),
      cupomCode: new FormControl({ value: '', disabled: false }, [Validators.required]),

    });
    this.userSubscription = this.customerService.customer.subscribe(async (customer) => {
      this.customer = customer;
      if (customer?.uid) {
        const uid = this.customer.uid;
        this.customerPendingDocuments = this.removesFilesToSignFromList(
          await this.userDocumentService.getDocumentsPending(uid)
        );

        this.hasCupom = !!this.customer.cupomCode;
        if (this.hasCupom) {
          // this.cupomFormGroup.get('allowCupom').setValue(true);
          this.cupomFormGroup.get('cupomCode').setValue(this.customer.cupomCode);
         
        }

        this.enableLoading = false;
    ;
      }
      
   
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  saveCupom(): void {
    this.isSaving = true;
    const cupomForm = this.cupomFormGroup.get('cupomCode')?.value
      this.customerService
      .checkCupomExists(cupomForm, this.customer.uid)
      .then(() => {
        this.isSaving = false;
        this.isEditingCupom = false;

      })
      .catch((err) => {
        this.isSaving = false;

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro',
            alertDescription: err.message,
            isOnlyConfirm: true,
          },
        });
        console.error('Error saving accountant', err);
        this.logger.fatal(
          'Problemas ao atualizar o cupom',
          this.customer.cupomCode,
          this.cupomFormGroup.get('cupomCode').value,
          err
        );
      });
  }

  // onChangeCupomAuthorization(): void {
  //    const valueSet = !this.cupomFormGroup.get('allowCupom').value;
  //    this.cupomFormGroup.get('allowCupom').setValue(valueSet);
  //   if (valueSet) {
  //     this.cupomFormGroup.get('cupomCode').enable({ onlySelf: true });
  //     this.cupomFormGroup.get('cupomCode').setErrors({ required: true });
  //     this.cupomFormGroup.get('cupomCode').markAsUntouched();

  //   } else {
  //     this.cupomFormGroup.get('cupomCode').setValue('');
  //     this.cupomFormGroup.get('cupomCode').disable({ onlySelf: true });
  //     this.cupomFormGroup.get('cupomCode').setErrors([]);

  //   }
  // }

  // cancelEditing(): void {
  //   this.isEditingCupom = false;
  //   this.cupomFormGroup.get('allowCupom').setValue(true);
  //   this.cupomFormGroup.get('cupomCode').setValue(this.customer.cupomCode);

  // }

  removesFilesToSignFromList(documentsList: DocumentMongoDB[]): DocumentMongoDB[] {
    const documents = documentsList?.filter((document) => {
      if (document?.documentAction === 'sign') {
        return (
          !!document.fileInfo?.find((info) => !!info?.path) &&
          document.qtySubmittedFiles >= document.qtyExpectedFiles &&
          document.situation !== 'Pendente'
        );
      } else {
        return true;
      }
    });

    return documents;
  }


}

