import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'functions/src/models/User';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { Subscription } from 'rxjs';
import { AgentService } from 'src/app/agents/services/agent.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilHandler } from 'src/app/core/handler/util.handler';
import { AgentsAdminService } from '../../services/agents-admin.service';

@Component({
  selector: 'app-agent-create',
  templateUrl: './agent-create.component.html',
  styleUrls: ['./agent-create.component.scss'],
})
export class AgentCreateComponent implements OnInit, OnDestroy {
  isLoading = true;
  isProcessing = false;
  showPassword = false;
  isSalesforceError = false;

  readonly MASKS = MASKS;

  createAgentFormGroup: FormGroup;

  formatPhone = UtilHandler.formatPhone;

  // Admin control
  loggedAdmin: User;
  loggedAdminSubscription: Subscription;

  // origem admin
  defaultSourceAdmin = 'admin';

  constructor(
    private agentService: AgentService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private admin: AgentsAdminService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.createAgentFormGroup = this.formBuilder.group({
      nameCtrl: new FormControl('', Validators.required),
      emailCtrl: new FormControl('', [Validators.required, Validators.email]),
      phoneCtrl: new FormControl('', [Validators.required, NgBrazilValidators.telefone]),
      CNPJCtrl: new FormControl('', [Validators.required, NgBrazilValidators.cnpj]),
      linkedinCtrl: new FormControl('', Validators.required),
      passwordCtrl: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });

    this.loggedAdminSubscription = this.authService.user.subscribe((user) => {
      this.loggedAdmin = user;
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    if (this.loggedAdminSubscription) {
      this.loggedAdminSubscription.unsubscribe();
    }
  }

  async createAgent(): Promise<void> {
    this.isProcessing = true;

    const cnpj = this.createAgentFormGroup.get('CNPJCtrl').value;
    let isValidCNPJ = true;

    if (cnpj) {
      try {
        await Promise.all([this.agentService.checkIfCNPJExists(cnpj)]);
      } catch (err) {
        console.error(err);
        isValidCNPJ = false;
        if (err === 'existent-cnpj') {
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'CNPJ em uso',
              alertDescription: `O CNPJ ${cnpj} já está em uso.`,
              isOnlyConfirm: true,
            },
          });
        } else {
          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao checar CNPJ',
              alertDescription: 'Ocorreu um erro ao verificar o CNPJ informado. Tente novamente mais tarde.',
              isOnlyConfirm: true,
            },
          });
        }
        this.isProcessing = false;
      }
    }

    if (this.createAgentFormGroup.valid && isValidCNPJ) {
      console.log('Changed data');

      this.admin
        .createAgent(
          {
            email: this.createAgentFormGroup.get('emailCtrl').value.trim().toLowerCase(),
            name: this.createAgentFormGroup.get('nameCtrl').value.trim().toUpperCase(),
            phone: this.createAgentFormGroup.get('phoneCtrl').value.trim(),
            cnpj: (this.createAgentFormGroup.get('CNPJCtrl').value || '').trim(),
            linkedin: (this.createAgentFormGroup.get('linkedinCtrl').value || '').trim(),
            source: this.defaultSourceAdmin,
          },
          this.createAgentFormGroup.get('passwordCtrl').value,
          this.loggedAdmin.email
        )
        .then((res) => {
          console.log('Agent created');
          if (res === 'email-in-use') {
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'E-mail em uso',
                alertDescription: `O e-mail ${this.createAgentFormGroup.get('emailCtrl').value} já está em uso.`,
                isOnlyConfirm: true,
              },
            });
          } else if (res === 'error-sending-email') {
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao enviar e-mail',
                alertDescription: `O assessor ${
                  this.createAgentFormGroup.get('emailCtrl').value
                } foi criado, mas ocorreu um erro ao enviar os dados de acesso por e-mail.`,
                isOnlyConfirm: true,
              },
            });
          } else {
            this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Assessor criado',
                alertDescription:
                  'O assessor de crédito foi criado com sucesso. Um e-mail com os dados de acesso foi enviado.',
                isOnlyConfirm: true,
              },
            });

            this.createAgentFormGroup.get('emailCtrl').reset();
            this.createAgentFormGroup.get('emailCtrl').setErrors(null);
            this.createAgentFormGroup.get('emailCtrl').markAsUntouched();

            this.createAgentFormGroup.get('nameCtrl').reset();
            this.createAgentFormGroup.get('nameCtrl').setErrors(null);
            this.createAgentFormGroup.get('nameCtrl').markAsUntouched();

            this.createAgentFormGroup.get('phoneCtrl').reset();
            this.createAgentFormGroup.get('phoneCtrl').setErrors(null);
            this.createAgentFormGroup.get('phoneCtrl').markAsUntouched();

            this.createAgentFormGroup.get('CNPJCtrl').reset();
            this.createAgentFormGroup.get('CNPJCtrl').setErrors(null);
            this.createAgentFormGroup.get('CNPJCtrl').markAsUntouched();

            this.createAgentFormGroup.get('linkedinCtrl').reset();
            this.createAgentFormGroup.get('linkedinCtrl').setErrors(null);
            this.createAgentFormGroup.get('linkedinCtrl').markAsUntouched();

            this.createAgentFormGroup.get('passwordCtrl').reset();
            this.createAgentFormGroup.get('passwordCtrl').setErrors(null);
            this.createAgentFormGroup.get('passwordCtrl').markAsUntouched();
          }

          this.isProcessing = false;
        })
        .catch((err) => {
          console.error('Error creating agent', err);

          if (err === 'salesforce-error') {
            this.isSalesforceError = true;
          } else {
            this.isSalesforceError = false;
          }

          this.dialog.open(AlertDialogComponent, {
            maxWidth: '600px',
            data: {
              alertTitle: 'Erro ao criar assessor',
              alertDescription: 'Ocorreu um erro ao criar o assessor de crédito. Tente novamente mais tarde.',
              isOnlyConfirm: true,
            },
          });

          this.isProcessing = false;
        });
    }
  }

  generatePassword(): void {
    this.createAgentFormGroup.get('passwordCtrl').setValue(this.admin.generatePassword(8));
    this.showPassword = true;
  }
}
