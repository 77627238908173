import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BlogCard } from './blog-card.interface';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss']
})
export class BlogCardComponent implements OnInit {
  blogCardsContent: BlogCard[];

  constructor(private firestore: AngularFirestore) { }

  ngOnInit(): void {
    this.getBlogCardsContent();
  }

  getBlogCardsContent(): void{
    this.firestore.collection('blog-cards').get().toPromise().then((snapshot) => {
      const response = snapshot.docs.map(doc => doc.data() as BlogCard);
      this.blogCardsContent = response;
      console.log('blogCardsContent', this.blogCardsContent);
    })
  }

}
