import { Component, Input, OnInit } from '@angular/core';
import { ErpsService } from '../../services/erps.service';
import { ReceivablesFormatter } from './customer-erps-result';

@Component({
  selector: 'app-customer-erps-result',
  templateUrl: './customer-erps-result.component.html',
  styleUrls: ['./customer-erps-result.component.scss']
})
export class CustomerErpsResultComponent implements OnInit {
  @Input() customer: string | null = null;
  formattedData: any[] = [];

  constructor(private erpService: ErpsService) {}

  ngOnInit(): void {
    if (this.customer) {
      this.erpService.getErpsResult(this.customer).subscribe(data => {
        // Verifica se a resposta contém o campo "omie"
        let resultData = null;
        if (data?.omie && Array.isArray(data?.omie?.movimentos)) {
          resultData = data.omie.movimentos; // Acessa o array "movimentos" dentro de "omie"
        } else if (data?.contaAzul) {
          resultData = data.contaAzul; // Caso os dados venham do campo "contaAzul"
        }

        // Logar os dados para entender a estrutura
        console.log('Dados recebidos:', resultData);

        // Verifica se resultData é um array
        if (Array.isArray(resultData)) {
          this.formattedData = ReceivablesFormatter.format(resultData);
        }
      });
    }
  }


  // saveTestData(): void {
  //   const uid = 'Mq38CLfUNJPWUgA6SKbJPeePo903';
  //   const newTestData = {
  //     nPagina: 1,
  //     nTotPaginas: 62683,
  //     nRegistros: 5,
  //     nTotRegistros: 313404,
  //     movimentos: [
  //       {
  //         detalhes: {
  //           cCPFCNPJCliente: "41.626.210/0001-77",
  //           cCodCateg: "1.01.02",
  //           cGrupo: "CONTA_A_RECEBER",
  //           cNatureza: "R",
  //           cNumDocFiscal: "1",
  //           cNumOS: "44",
  //           cNumParcela: "001/001",
  //           cNumTitulo: "RPS 6",
  //           cOperacao: "01",
  //           cOrigem: "VENR",
  //           cStatus: "CANCELADO",
  //           cTipo: "99999",
  //           dDtEmissao: "07/08/2014",
  //           dDtPrevisao: "07/08/2014",
  //           dDtRegistro: "07/08/2014",
  //           dDtVenc: "07/08/2014",
  //           nCodCC: 1208238,
  //           nCodCliente: 3189079,
  //           nCodNF: 3210145,
  //           nCodOS: 3210138,
  //           nCodTitRepet: 3213562,
  //           nCodTitulo: 3213562,
  //           nValorTitulo: 1200
  //         },
  //         resumo: {
  //           cLiquidado: "S",
  //           nDesconto: 0,
  //           nJuros: 0,
  //           nMulta: 0,
  //           nValAberto: 0,
  //           nValLiquido: 0,
  //           nValPago: 0
  //         }
  //       },
  //       {
  //         detalhes: {
  //           cCPFCNPJCliente: "44.586.137/0001-45",
  //           cCodCateg: "1.01.02",
  //           cCodProjeto: 7526752,
  //           cCodVendedor: 7526751,
  //           cGrupo: "CONTA_A_RECEBER",
  //           cNatureza: "R",
  //           cNumDocFiscal: "2",
  //           cNumOS: "45",
  //           cNumParcela: "001/001",
  //           cNumTitulo: "RPS 7",
  //           cOrigem: "VENR",
  //           cStatus: "RECEBIDO",
  //           cTipo: "CRC",
  //           dDtEmissao: "07/08/2014",
  //           dDtPagamento: "24/09/2024",
  //           dDtPrevisao: "22/01/2021",
  //           dDtRegistro: "07/08/2014",
  //           dDtVenc: "22/01/2021",
  //           nCodCC: 4243124,
  //           nCodCliente: 4214850,
  //           nCodNF: 3212952,
  //           nCodOS: 3212949,
  //           nCodTitRepet: 3213576,
  //           nCodTitulo: 3213576,
  //           nValorTitulo: 100
  //         },
  //         resumo: {
  //           cLiquidado: "S",
  //           nDesconto: 0,
  //           nJuros: 0,
  //           nMulta: 0,
  //           nValAberto: 0,
  //           nValLiquido: 100,
  //           nValPago: 100
  //         }
  //       },
  //       // Adicione o restante dos itens conforme o novo JSON fornecido
  //     ]
  //   };
  
  //   this.erpService.saveErpsData(uid, newTestData).then(() => {
  //     console.log('Dados de teste salvos com sucesso!');
  //   });
  // }
}